import _ from 'underscore';

import { Log } from '@biteinc/common';
import { TimeHelper } from '@biteinc/core-react';
import { CardEntryMethod, FlashBridgeMessage } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';
import GcnGiftCardHelper from '~/helpers/gcn_gift_card_helper';

import { useStore } from '../../../stores';
import Errors from '../utils/errors';
import { GCNCardWithPinView } from './gcn_card_with_pin_view';
import { GCNView } from './gcn_view';
import {
  getCssClassForCardReader,
  getNativeStoredValueInstructionText,
  getSecondaryCssClassForCardReader,
  terminalBasedIntegrationUsesApiPayments,
} from './stored_value_card_reader_helper';

export const GCNGiftCardTerminalView = GCNView.extend({
  className: 'gift-card-terminal-view',

  template: _.template(
    // prettier-ignore
    '<div class="body">' +
      '<div class="title"></div>' +
      '<div class="images-container">' +
        '<div class="image secondary"></div>' +
        '<div class="image primary"></div>' +
      '</div>' +
      '<div class="subtitle"></div>' +
      '<div class="gift-card-view-bottom">' +
      '<div class="form-group"></div>' +
      '<div class="fallback-button card-number"></div>' +
    '</div>' +
    '</div>',
  ),

  initialize(mode, options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.grandTotal = options.grandTotal;
    this._callback = options.callback;
    this._mode = mode;
    this._integration =
      this._mode === 'stored-value'
        ? gcn.location.getStoredValueIntegration()
        : gcn.location.getCompCardIntegration();
    this._readGiftCard();
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);

    if (!this._obtainedTransaction && gcn.bridge) {
      gcn.bridge.send({ event: 'cancelReadGiftCard' });
    }
  },

  handleScannerData(scannerData) {
    Log.info('GCNGiftCardBarcodeView.handleScannerData', scannerData);

    if (!gcn.orderManager.orderHasBeenValidated()) {
      return;
    }
    this.showGiftCardWithPinPopup(scannerData, CardEntryMethod.Scan);
  },

  showGiftCardWithPinPopup(cardNumber, cardEntryMethod) {
    // TODO: Clean up self-dismissal with a controller.
    gcn.menuView.dismissStablePopup();
    // Remove barcode listener
    gcn.removeScannerHandler(this);
    const giftCardView = new GCNCardWithPinView(this._mode, {
      callback: this._callback,
      cardNumber,
      cardEntryMethod,
    });
    gcn.menuView.showStablePopup(giftCardView, 'gift-card-view');
  },

  async _readGiftCard() {
    if (!gcn.bridge) {
      return;
    }

    const currentOrder = gcn.orderManager.getOrder();

    if (terminalBasedIntegrationUsesApiPayments(this._integration.system)) {
      try {
        const amount = gcn.orderManager.getGrandTotal();
        gcn.menuView.showSpinner(localizeStr(Strings.PROCESSING_PAYMENT));

        let data;
        try {
          data = await gcn.maitred.giftCardCaptureInQueue(null, amount);
        } finally {
          gcn.menuView.dismissSpinner();
        }

        gcn.orderManager.setOrderFromJSON(data.order);
        if (gcn.location.useOrdersApiV2()) {
          gcn.orderManager.setTransactionsFromJson(data.successfulTransactions);
        } else {
          gcn.orderManager.setTransactionsFromJson([data.transaction]);
        }
        useStore.getState().checkout.onStoredValueUpdated();
      } catch (err) {
        this._showError(Errors.stringFromErrorCode(err.code), this._callback);
        return;
      } finally {
        this._obtainedTransaction = true;
      }

      this._callback();

      return;
    }

    // Does this callback get GCd once the view is destroyed?
    const payload = {
      event: FlashBridgeMessage.READ_GIFT_CARD,
      amountInCents: this.grandTotal,
      order: currentOrder.attributes,
    };

    const authData = await gcn.bridge.sendAsync(payload);
    // authData could be pre-transaction or post-transaction depending on the integration

    // If the transaction fails by either getting cancelled or being abandoned, the native side
    // will send an empty authData
    // If we get here, then it means the user has abandoned the transaction. Go home, just
    // like we do with normal CC transactions
    if (_.isEmpty(authData)) {
      gcn.goHome();
      return;
    }

    const cardNumber = authData.cardNumber;

    if (this._integration.requiresPin) {
      const callback = () => {
        gcn.menuView.dismissModalPopup();
        this._callback();
      };
      gcn.menuView.dismissModalPopup();
      GcnGiftCardHelper.showGiftCardViewWithPrefilledCardNumber(cardNumber, callback);
      return;
    }

    if (this._mode === 'comp-card') {
      const { inquireError } = await gcn.loyaltyManager.applyCompCard(
        cardNumber,
        CardEntryMethod.Swipe,
      );
      if (inquireError) {
        this._showError(inquireError);
        return;
      }

      this._callback();
      return;
    }

    gcn.orderManager.addStoredValueCard(cardNumber, authData.cardEntryMethod);

    try {
      await GcnGiftCardHelper.captureMaximumAmount(authData);
    } catch (err) {
      this._showError(Errors.stringFromErrorCode(err.code), this._callback);
      return;
    } finally {
      this._obtainedTransaction = true;
    }

    this._callback();
  },

  _showError(message, callback) {
    // We can't use the built-in timeout feature with GCNAlertView because we need a cancel
    // callback without showing a cancel button
    const errorTimeout = setTimeout(() => {
      gcn.menuView.dismissModalPopup();
      callback();
    }, 15 * 1000);

    gcn.menuView.showSimpleAlert(message, () => {
      clearTimeout(errorTimeout);
      callback();
    });
  },

  render() {
    this.$el.html(this.template());

    const storedValueSystem = gcn.location.get('giftCardI9n');
    this._$title = this.$('.title');
    this._$subtitle = this.$('.subtitle');
    this._$image = this.$('.image.primary');
    this._$imageSecondary = this.$('.image.secondary');

    this._$title.htmlOrText(localizeStr(Strings.GIFT_CARD_TITLE));
    this._$subtitle.htmlOrText(getNativeStoredValueInstructionText(storedValueSystem, this._mode));
    this._$image.addClass(getCssClassForCardReader(storedValueSystem));

    const secondaryCssClass = getSecondaryCssClassForCardReader(storedValueSystem);
    if (secondaryCssClass) {
      this._$imageSecondary.addClass(secondaryCssClass);
      let imageOpacity = 1;
      this.cardAnimationInterval = setInterval(() => {
        imageOpacity = 1 - imageOpacity;
        this._$imageSecondary.css('opacity', imageOpacity);
      }, 2400);
    } else {
      this._$imageSecondary.remove();
    }

    if (this._integration.hasBarcodes) {
      gcn.pushScannerHandler(this);
    }

    if (this._integration.useManualEntryAsFallback) {
      this.$manualEntryButton = this.$('.card-number');
      this.$manualEntryButton.htmlOrText(localizeStr(Strings.ENTER_GIFT_CARD_BARCODE_FALLBACK));
      this.$manualEntryButton.css('opacity', '0');
      setTimeout(() => {
        this.$manualEntryButton.css('opacity', '1');
      }, TimeHelper.SECOND);

      this.$manualEntryButton.onButtonTapOrHold('gcbvManual', () => {
        gcn.menuView.dismissModalPopup();
        this.showGiftCardWithPinPopup();
      });
    }

    return this;
  },
});
