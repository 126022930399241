import $ from 'jquery';
import _ from 'underscore';

import { OrderChannelHelper } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import { PAGE_NAV_VIEW_HIDE_EVENT, PAGE_NAV_VIEW_SHOW_EVENT } from '../../../helpers/custom_events';
import Analytics from '../utils/analytics';
import { GCNPopup } from './gcn_popup';
import { GCNAsterHallHeaderView } from './step_views/gcn_aster_hall_header_view';

export const GCNPageNavigationView = GCNPopup.extend({
  className(...args) {
    const className = GCNPopup.prototype.className.apply(this, args);

    if (gcn.location.hasBottomBarButtons()) {
      return `${className} page-nav-view has-bottom-bar`;
    }
    return `${className} page-nav-view`;
  },

  defaultHeaderTemplate: _.template(
    // prettier-ignore
    '<div class="header-container">' +
      '<div class="logo-container bg-color-spot-1">' +
        '<div class="logo"></div>' +
      '</div>' +
      '<div class="title-container">' +
        '<div class="title"><%= title %></div>' +
      '</div>' +
    '</div>',
  ),

  bodyTemplate: _.template(
    // prettier-ignore
    '<div class="body">' +
      '<div class="column"></div>' +
    '</div>' +
    '<div class="footer bg-color-spot-1">' +
      '<div></div>' +
    '</div>',
  ),

  _setTitleBarImage(image) {
    const self = this;
    gcn.requestImageByUrl(image.url, (err, imgPath) => {
      self.$('.logo').css('background-image', `url(${imgPath})`);
      self.$('.logo').addClass('has-image');
    });
  },

  _setTitle(title) {
    this.$('.logo').text(title);
  },

  show() {
    this.$el.show();
    this.$el.css('transform', 'translateY(0px)');
    document.dispatchEvent(PAGE_NAV_VIEW_SHOW_EVENT);
  },

  hide() {
    this.$el.delay(200).fadeOut(400);
    this.$el.css('transform', `translateY(-${this.$el.height()}px)`);
    document.dispatchEvent(PAGE_NAV_VIEW_HIDE_EVENT);
  },

  render() {
    const self = this;

    this.$el.html('');
    if (gcn.location.isAsterHall()) {
      this.$el.append(
        new GCNAsterHallHeaderView({
          title: localizeStr(Strings.OUR_MENU),
        }).render().$el,
      );
    } else {
      this.$el.append(
        this.defaultHeaderTemplate({
          title: localizeStr(Strings.OUR_MENU),
        }),
      );

      const titleBarImage = gcn.menu.getTitleBarImage();
      if (titleBarImage) {
        this._setTitleBarImage(titleBarImage);
      } else if ((gcn.menu.getMenuTitle() || '').length) {
        this._setTitle(gcn.menu.getMenuTitle());
      } else {
        this._setTitle(gcn.location.get('orgName'));
      }
    }

    if (OrderChannelHelper.isKiosk(gcn.location.get('orderChannel')) && !window.isFlash) {
      const $exitToCoverButton = $(
        // prettier-ignore
        '<div class="exit-to-cover-button">' +
          '<div class="icon"></div>' +
          `<div class="text">${localizeStr(Strings.EXIT_TO_COVER)}</div>` +
        '</div>',
      );
      this.$el.append($exitToCoverButton);
      $exitToCoverButton.onButtonTapOrHold('pnvBack', () => {
        Analytics.track(Analytics.EventName.PageNavExitToCoverTapped);
        gcn.goHome();
      });
    }

    this.$el.append(this.bodyTemplate());

    const $column = this.$('.body .column');
    // TODO: Can definitely share more code here if we abstract out the navigation type through a
    // proper MVC pattern.
    if (gcn.menu.structure.pages.length === 1) {
      // Section navigation.
      _.each(gcn.menu.structure.pages[0].sections, (section) => {
        if (section.get('hideFromMenu')) {
          // Hidden sections may come from maitred, such as sections containing items that can only
          // be added to the cart via scanner
          return;
        }

        const sectionSlug = section.getSlugName();
        const $button = $(
          `<div class="page-button bg-color-spot-1 font-title section-${sectionSlug}"></div>`,
        );
        if (section.hasArr('pageNavigationImages')) {
          gcn.requestImageByUrl(section.get('pageNavigationImages')[0].url, (err, imgPath) => {
            if (!err) {
              $button.css('background-image', `url(${imgPath})`);
            }
          });
        }

        $button.text(section.displayName());
        $column.append($button);
        $button.onButtonTapOrHold('pnvSection', () => {
          Analytics.trackEvent({
            eventName: Analytics.EventName.PageNavSectionSelected,
            eventData: {
              sectionName: section.displayName(),
            },
          });
          this.trigger(BackboneEvents.GCNPageNavigationView.DidSelectSectionId, section.id);
          // Dismiss the view.
          this.hide();
          return false;
        });
      });
    } else {
      // Page navigation.
      _.each(gcn.menu.structure.pages, (page) => {
        const $button = $(
          `<div class="page-button bg-color-spot-1 font-title page-${page.getSlugName()}"></div>`,
        );

        const url = page.getNavigationImageUrlIfExists();
        if (url) {
          gcn.requestImageByUrl(url, (err, imgPath) => {
            if (!err) {
              $button.css('background-image', `url(${imgPath})`);
            }
          });
        }
        $button.text(page.get('name'));
        $button.addClass('border-color-spot-1');
        $button.addClass('color-spot-1');
        $button.addClass('font-title');
        $column.append($button);

        $button.onButtonTapOrHold('pnvSubPage', () => {
          Analytics.trackEvent({
            eventName: Analytics.EventName.PageNavPageSelected,
            eventData: {
              pageName: page.displayName(),
            },
          });
          self.trigger(BackboneEvents.GCNPageNavigationView.DidSelectMenuPageId, page.id);
          // Dismiss the view.
          self.hide();
          return false;
        });
      });
    }

    return this;
  },
});
