import type { Schema } from '@biteinc/core-react';
import { addressSchema, guestInfoSchema, vehicleSchema } from '@biteinc/core-react';
import { CustomerIdentifier } from '@biteinc/enums';

import type GcnLocation from '../app/js/models/gcn_location';

module GcnCustomerIdentifierHelper {
  export function shouldAskAtTheBeginning(
    customerIdentifierOption: GcnLocation.CustomerIdentifierOption,
  ): boolean {
    switch (customerIdentifierOption.customerIdentifier) {
      case CustomerIdentifier.Address:
      case CustomerIdentifier.GuestName:
      case CustomerIdentifier.PhoneNumber:
      case CustomerIdentifier.TableNumber:
      case CustomerIdentifier.VehicleDescription:
        return !!customerIdentifierOption.askAtBeginning;
      case CustomerIdentifier.BiteOrderNumber:
      case CustomerIdentifier.GuestEmail:
      case CustomerIdentifier.PosOrderNumber:
        return false;
    }
  }

  export function identifierIsOptional(
    customerIdentifierOption: GcnLocation.CustomerIdentifierOption,
  ): boolean {
    switch (customerIdentifierOption.customerIdentifier) {
      case CustomerIdentifier.GuestEmail:
      case CustomerIdentifier.PhoneNumber:
        return !!customerIdentifierOption.isOptional;
      case CustomerIdentifier.Address:
      case CustomerIdentifier.BiteOrderNumber:
      case CustomerIdentifier.GuestName:
      case CustomerIdentifier.PosOrderNumber:
      case CustomerIdentifier.TableNumber:
      case CustomerIdentifier.VehicleDescription:
        return false;
    }
  }

  /**
   * `isOptional` flag is only available for phone numbers
   */
  function getCustomerIdentifierSchema(
    customerIdentifier: CustomerIdentifier,
    isOptional?: boolean,
  ): Schema | null {
    switch (customerIdentifier) {
      case CustomerIdentifier.Address:
        return addressSchema;
      case CustomerIdentifier.GuestName:
        return { name: guestInfoSchema.name };
      case CustomerIdentifier.VehicleDescription:
        return vehicleSchema;
      case CustomerIdentifier.PhoneNumber:
        return {
          phone: {
            ...guestInfoSchema.phone,
            required: !isOptional,
          },
        };
      case CustomerIdentifier.GuestEmail:
        return {
          email: {
            ...guestInfoSchema.email,
            required: !isOptional,
          },
        };
      case CustomerIdentifier.BiteOrderNumber:
      case CustomerIdentifier.PosOrderNumber:
      case CustomerIdentifier.TableNumber:
        // TODO: handle this case
        return null;
    }
  }

  export function getCustomerIdentifierSchemasMergedIntoSingleSchemaWithEmail(
    customerIdentifierOptions: GcnLocation.CustomerIdentifierOption[],
    options: { requireEmail: boolean },
  ): Schema {
    const eligibleCustomerIdentifierOptions = customerIdentifierOptions.filter((option) => {
      return !shouldAskAtTheBeginning(option);
    });

    let hasInsertedEmailSchema = false;
    const hasNameOrPhoneNumber = eligibleCustomerIdentifierOptions.some(
      ({ customerIdentifier }) => {
        return [CustomerIdentifier.GuestName, CustomerIdentifier.PhoneNumber].includes(
          customerIdentifier,
        );
      },
    );

    // Insert the email schema either after name/phone-number or as the first field
    const baseSchema: Schema =
      hasNameOrPhoneNumber || !options.requireEmail ? {} : { email: guestInfoSchema.email };

    return eligibleCustomerIdentifierOptions.reduce((schema, option) => {
      const { customerIdentifier } = option;
      const identifierSchema = getCustomerIdentifierSchema(
        customerIdentifier,
        identifierIsOptional(option),
      );

      // Insert email schema after name/phone-number
      const emailSchema: Schema | null =
        !hasInsertedEmailSchema &&
        options.requireEmail &&
        [CustomerIdentifier.GuestName, CustomerIdentifier.PhoneNumber].includes(customerIdentifier)
          ? { email: guestInfoSchema.email }
          : null;
      if (emailSchema) {
        hasInsertedEmailSchema = true;
      }

      return {
        ...schema,
        ...(identifierSchema && identifierSchema),
        ...(emailSchema && emailSchema),
      };
    }, baseSchema);
  }
}

export default GcnCustomerIdentifierHelper;
