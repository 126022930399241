import { StringHelper } from '@biteinc/core-react';
import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNNumberEntryView } from './gcn_number_entry_view';

// General purpose phone number input UI.
// Inheritors of this class need to specify the UI (explanation text, icon, etc) as well as
// functionality when the skip or done button are pressed.
export const GCNPhoneNumberEntryView = GCNNumberEntryView.extend({
  className() {
    return `${GCNNumberEntryView.prototype.className.apply(this)} phone`;
  },
  __expectedInputLength: 10,
  __validateInputAsPhoneNumber: true,

  initialize(options = {}, ...args) {
    options.userNumber = gcn.orderManager.getGuestPhoneNumber() || '';
    this.hasScrim = !!gcn.menu.settings.get('phoneNumberScrimImage')?.length;

    GCNNumberEntryView.prototype.initialize.apply(this, [options, ...args]);
  },

  // Exact same formatting logic as in gcn_sms_promo_view but had to hotfix
  __updateDisplayValue() {
    const displayText = StringHelper.toFormattedPhoneNumber(this.__userNumber);
    this._$numberDisplay.toggleClass('error', false);
    this._$numberDisplay.find('span').text(displayText);
    this._$numberEntryInput.val(displayText);

    if (gcn.screenReaderIsActive) {
      if (!this.__userNumber) {
        this._$numberDisplay.attr('aria-label', 'No phone number entered');
      } else {
        this._$numberDisplay.attr(
          'aria-label',
          `selected phone number: ${this.__userNumber.split('').join(' ')}`,
        );
      }
    }
  },

  render(...args) {
    GCNNumberEntryView.prototype.render.apply(this, args);

    if (this.hasScrim) {
      const scrimImage = gcn.menu.settings.get('phoneNumberScrimImage')[0].url;
      this.$el.prepend(
        `<div class="phone-scrim-container">
          <img alt="phone-scrim-image" class="w-full" src="${scrimImage}"/>
        </div>`,
      );
    }

    this.__$footerLink.htmlOrText(`${localizeStr(Strings.DISCLAIMER_PHONE_LINK)}`);
    this.__$footerDescription.htmlOrText(`${localizeStr(Strings.DISCLAIMER_PHONE_DESCRIPTION)}`);
  },
});
