import $ from 'jquery';

import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNFullScreenFlowStepView } from './gcn_full_screen_flow_step_view';

export const GCNCustomizeFlowStepView = GCNFullScreenFlowStepView.extend({
  className: 'customize-flow-step-view',

  initialize(options, ...args) {
    GCNFullScreenFlowStepView.prototype.initialize.apply(this, [options, ...args]);

    this.orderedItem = options.orderedItem;
  },

  setNextDisabled(disabled) {
    this.$nextButton.toggleClass('disabled', disabled);
  },

  _showScrollIndicator() {
    const $scrollIndicator = $(
      // prettier-ignore
      '<div class="scroll-indicator">' +
        '<div class="more-text"></div>' +
        '<div class="arrow-icon">&#x2304;</div>' +
      '</div>',
    );
    this.$stepPanel.append($scrollIndicator);
    $scrollIndicator.find('.more-text').htmlOrText(localizeStr(Strings.MORE));
    setTimeout(() => {
      // Center it.
      let left = (this.$stepBody.outerWidth() - $scrollIndicator.width()) / 2;
      // TODO: Remove this adjustment when #1000 is resolved.
      left -= 14;
      $scrollIndicator.css('left', `${left}px`);
      $scrollIndicator.css('bottom', this.$buttonBar.outerHeight());
    }, 1);

    this.$stepBody.scroll(() => {
      $scrollIndicator.fadeOut(300);
    });
  },

  render(...args) {
    GCNFullScreenFlowStepView.prototype.render.apply(this, args);

    this.$buttonBar = this.$el.find('.button-bar');
    if (this.prevButtonText) {
      this.setPrevButtonText(this.prevButtonText);
    }

    // Adjust for short screens.
    const bodyHeight = window.innerHeight || $('body')[0].clientHeight;
    const availableHeight = bodyHeight - 48;
    if (availableHeight < 720) {
      this.$stepPanel.css('max-height', `${availableHeight}px`);
    }
    if (availableHeight < 720) {
      this.$stepPanel.css('min-height', `${availableHeight}px`);
    }
    // Undo the superclass value.
    this.$stepPanel.css('height', 'initial');

    setTimeout(() => {
      if (this.$el.hasClass('addon-set')) {
        if (this.$stepBody.canVerticalScroll(16)) {
          this._showScrollIndicator();
        }
      }
    }, 1);

    return this;
  },
});
