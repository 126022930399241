import type {
  InputChangeEventDetail,
  IonInputCustomEvent,
  IonSegmentCustomEvent,
  SegmentChangeEventDetail,
} from '@ionic/core';
import {
  IonButton,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { logoUsd } from 'ionicons/icons';
import * as React from 'react';
import _ from 'underscore';

import { ErrorCode } from '@biteinc/common';
import { LocationPresetTipType } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { localizeStr, useLocalize } from '~/app/js/localization/localization';
import type GcnLocation from '~/app/js/models/gcn_location';
import { GCNAlertView } from '~/app/js/views/gcn_alert_view';

import GcnHelper from '../app/js/gcn_helper';
import { useStore } from '../stores';

interface TipSelectorProps {
  tippableTotal: number;
  presetTips: GcnLocation.PresetTip[];
  selectedTipAmount: number;
}

export function TipSelector(props: TipSelectorProps): JSX.Element {
  const str = useLocalize();
  const onOrderUpdated = useStore((state) => state.checkout.onOrderUpdated);
  // we want to derive percentage tips from the tippable total
  // this is so we can know which tip is selected since we only know the amount
  // and not the type
  // this is usually okay because even if the preset tip is a fixed amount
  // the chances of the percentage amount being the same as the fixed amount is extremely low
  const derivedPresetTips = getDerivedPresetTips(props.presetTips, props.tippableTotal);

  const [showCustomTip, setShowCustomTip] = React.useState<boolean>(false);
  const selectedValue = determineSelectedValue(
    props.selectedTipAmount,
    derivedPresetTips,
    showCustomTip,
  );
  const customTipInputRef = React.useRef<HTMLIonInputElement>(null);

  function setTip(tipTotal: number): void {
    gcn.menuView.showSpinner(localizeStr(tipTotal > 0 ? Strings.TIPPING : Strings.REMOVE_TIP));

    // TODO: should this live in CheckoutPage via a callback?
    // eslint-disable-next-line no-underscore-dangle
    gcn.orderManager._tipTotal = tipTotal;
    gcn.maitred.updateTipRequest((err, data) => {
      gcn.menuView.dismissSpinner();
      if (err) {
        const alertText =
          err.code === ErrorCode.CannotModifyTipAfterPayment
            ? localizeStr(Strings.TIP_CANNOT_BE_MODIFIED)
            : err.message;
        const formattedAlertText = alertText && alertText.split('\n').join('<br />');
        const alertView = new GCNAlertView({
          text: formattedAlertText,
          okCallback: () => {
            gcn.menuView.dismissModalPopup();
          },
        });
        gcn.menuView.showModalPopup(alertView);
        return;
      }

      onOrderUpdated(data!.order);
    });
  }

  function submitCustomTip(valueStr: string | number): void {
    const value = Math.round(Number(valueStr) * 100);
    // Allow for zero value to remove tip
    /**
     * is Number
     * is NOT Nan
     * is greater than 0
     * is Less than order total
     */
    if (
      _.isNumber(value) &&
      !_.isNaN(value) &&
      value >= 0 &&
      value <= props.tippableTotal &&
      value !== props.selectedTipAmount
    ) {
      setTip(value);
    }
  }

  return (
    <IonRow className="tip-selector">
      <IonCol size="12">{str(Strings.ADD_TIP)}</IonCol>
      <IonCol size="12">
        <IonSegment
          value={selectedValue}
          className="gratuity-segment"
          onIonChange={(event: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
            const value = event.detail?.value!;
            if (value === 'other') {
              setShowCustomTip(true);

              return;
            }

            // if the user selects a preset tip, we want to hide the custom tip input
            setShowCustomTip(false);
            let [type, amount] = value.split('-');
            let parsedAmount = Number(amount);

            if (type === LocationPresetTipType.Percentage) {
              const tip = Math.ceil(props.tippableTotal * (parsedAmount / 100));
              setTip(tip);
            } else {
              setTip(parsedAmount * 100);
            }
          }}
        >
          {derivedPresetTips.map((tip) =>
            tip.type === LocationPresetTipType.Percentage ? (
              <IonSegmentButton
                value={`${tip.type}-${tip.amount}`}
                key={`${tip.type}-${tip.amount}`}
              >
                <div>%{tip.amount}</div>
                <div>{`$${GcnHelper.stringFromTotal(tip.derivedAmount)}`}</div>
              </IonSegmentButton>
            ) : (
              <IonSegmentButton
                value={`${tip.type}-${tip.amount}`}
                key={`${tip.type}-${tip.amount}`}
              >
                <div>{`$${GcnHelper.stringFromTotal(tip.amount * 100)}`}</div>
              </IonSegmentButton>
            ),
          )}
          <IonSegmentButton value="other">
            <div>{localizeStr(Strings.CUSTOM_TIP)}</div>
          </IonSegmentButton>
        </IonSegment>
        {showCustomTip || selectedValue === 'other' ? (
          <IonCol>
            <IonItem
              lines="none"
              className="other-tip"
            >
              <IonIcon
                slot="start"
                icon={logoUsd}
              />
              <IonInput
                type="number"
                placeholder="0.00"
                ref={customTipInputRef}
                // Set current tip as default value

                onIonChange={async (event: IonInputCustomEvent<InputChangeEventDetail>) => {
                  const $el = await event.target.getInputElement();
                  const valueStr = event.detail?.value!;
                  const latestChar = valueStr[valueStr.length - 1];
                  // if its not a number or a decimal, remove the last character
                  if (!/^\d+\.?$/.test(latestChar)) {
                    $el.value = valueStr.slice(0, -1);
                    return;
                  }
                  const value = Math.round(Number(valueStr) * 100);

                  // Only allow up to 2 decimal places and valid value
                  if (valueStr && !/^\d+\.?\d{0,2}?$/.test(valueStr)) {
                    $el.value = (value * 0.01).toFixed(2);
                  }
                }}
              />
            </IonItem>
            <IonButton
              className="submit-custom-tip"
              expand="block"
              fill="outline"
              size="default"
              onClick={() => {
                const valueStr = customTipInputRef.current?.value!;
                submitCustomTip(valueStr);
              }}
            >
              {str(Strings.ADD_TIP)}
            </IonButton>
          </IonCol>
        ) : null}
      </IonCol>
    </IonRow>
  );
}

type DerivedPresetTip =
  | {
      type: typeof LocationPresetTipType.Percentage;
      amount: number;
      derivedAmount: number;
    }
  | {
      type: typeof LocationPresetTipType.Fixed;
      amount: number;
    };

function getDerivedPresetTips(
  tips: GcnLocation.PresetTip[],
  tippableTotal: number,
): DerivedPresetTip[] {
  return (
    tips.map((presetTip) => {
      return presetTip.type === LocationPresetTipType.Percentage
        ? {
            ...presetTip,
            derivedAmount: Math.ceil(tippableTotal * (presetTip.amount / 100)),
          }
        : presetTip;
    }) as DerivedPresetTip[]
  ).filter((presetTip) => {
    if (presetTip.type === LocationPresetTipType.Percentage) {
      return true;
    }

    return 100 * presetTip.amount <= tippableTotal;
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function determineSelectedValue(
  selectedTipAmount: number,
  derivedPresetTips: DerivedPresetTip[],
  showCustomTip: boolean,
) {
  // use the derivedAmount when the type is percentage because we only know the tip amount in cents
  // otherwise use the amount since it's already in cents
  const selectedTipFromAmount = derivedPresetTips.find((tip) =>
    tip.type === LocationPresetTipType.Percentage
      ? selectedTipAmount === tip.derivedAmount
      : selectedTipAmount === tip.amount * 100,
  );

  // if we're already showing the custom tip input, we want to keep showing it
  if (showCustomTip) {
    return 'other' as const;
  }

  // if we have found a matching tip from the amount, we want to select it
  if (selectedTipFromAmount) {
    return `${selectedTipFromAmount.type}-${selectedTipFromAmount.amount}` as const;
  }

  // if we have a selected tip amount but we can't find a matching tip from the amount
  // then it must be a custom tip
  if (selectedTipAmount) {
    return 'other' as const;
  }

  // otherwise there is no selected tip value
  return undefined;
}
