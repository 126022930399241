import React from 'react';

import { Log } from '@biteinc/common';
import type {
  LoginData,
  LoginResponse,
  Order,
  SignupData,
  SignupPrefilledState,
  SignupResponse,
} from '@biteinc/core-react';
import { AppearanceHelper, Login, ModalService, PromptSignup, Signup } from '@biteinc/core-react';

import type { ThirdPartyLoyaltyAccountData } from '~/components/loyalty/qr-code-modal';
import { QrCodeModal } from '~/components/loyalty/qr-code-modal';
import { useStore } from '~/stores';
import type { KioskLocation } from '~/types';

import GcnFlashMaitredClient from '../app/js/gcn_flash_maitred_client';
import type { Callback } from '../app/js/gcn_maitred_request_manager';

export interface PromptSignupOptions {
  order: Order;
  favoriteItemIndex: number;
}

module AuthService {
  export function promptSignup(location: KioskLocation, options: PromptSignupOptions): void {
    const hide = ModalService.showCustomModal({
      cssClass: 'prompt-signup-modal',
      children: (
        <PromptSignup
          lang={useStore.getState().config.language}
          customStrings={useStore.getState().bridge.menu.settings.customStrings}
          logoUrl={AppearanceHelper.getOrgImage(gcn.org, 'loyaltyLogo')}
          signupUrl={gcn.org.signupUrl}
          close={() => {
            hide();
          }}
          showSignup={() => {
            hide();
            AuthService.showSignup(location, {
              orderId: options.order?._id,
            });
          }}
        />
      ),
    });
  }

  export function showLogin(
    location: KioskLocation,
    callback?: Callback,
    isExternal: boolean = false,
  ): void {
    // Login
    const hide = ModalService.showCustomModal({
      cssClass: 'login-modal',
      children: (
        <Login
          lang={useStore.getState().config.language}
          customStrings={useStore.getState().bridge.menu.settings.customStrings}
          logoUrl={AppearanceHelper.getOrgImage(gcn.org, 'loyaltyLogo')}
          authProvider={gcn.org.customerAuthProvider}
          signupUrl={gcn.org.signupUrl}
          scope={window.customerScope}
          close={() => {
            hide();
          }}
          showSignup={() => {
            hide();
            AuthService.showSignup(location, undefined, callback);
          }}
          signin={async (payload: LoginData) => {
            return new Promise<LoginResponse>((resolve) => {
              gcn.maitred.postCustomerResource('login', payload, (err, data) => {
                if (err) {
                  Log.error('Err', err);
                  resolve({ error: err.message, success: false });
                } else {
                  GcnFlashMaitredClient.setCustomerToken(data.token);
                  gcn.orderManager.setCustomer(data.customer);
                  gcn.loyaltyManager.setCustomerAuthData(data.customer);
                  hide();
                  if (callback) {
                    callback();
                  }
                  resolve({ success: true });
                }
              });
            });
          }}
          isExternal={isExternal}
        />
      ),
    });
  }

  export function showSignup(
    location: KioskLocation,
    prefilledState?: SignupPrefilledState,
    callback?: Callback,
  ): void {
    // Signup
    const hide = ModalService.showCustomModal({
      cssClass: 'signup-modal',
      children: (
        <Signup
          lang={useStore.getState().config.language}
          customStrings={useStore.getState().bridge.menu.settings.customStrings}
          logoUrl={AppearanceHelper.getOrgImage(gcn.org, 'loyaltyLogo')}
          prefilledState={prefilledState}
          flashPrefix={location.orgDomain ? '' : `/${location.urlSlug}`}
          close={() => {
            hide();
          }}
          signup={async (payload: SignupData) => {
            return new Promise<SignupResponse>((resolve) => {
              gcn.maitred.postCustomerResource('signup', payload, (err) => {
                if (err) {
                  Log.error('Err', err);
                  resolve({ error: err.message, success: false });
                } else {
                  hide();
                  AuthService.showLogin(location, callback);
                  resolve({ success: true });
                }
              });
            });
          }}
        />
      ),
    });
  }

  export function showQrCode(params: {
    addFundsUrl: string;
    signUpUrl: string;
    loyaltyData: ThirdPartyLoyaltyAccountData | null;
  }): void {
    const hide = ModalService.showCustomModal({
      cssClass: 'qr-code-modal',
      children: (
        <QrCodeModal
          lang={useStore.getState().config.language}
          addFundsUrl={params.addFundsUrl}
          signUpUrl={params.signUpUrl}
          loyaltyData={params.loyaltyData}
          customStrings={useStore.getState().bridge.menu.settings.customStrings}
          logoUrl={AppearanceHelper.getOrgImage(gcn.org, 'loyaltyLogo')}
          onClose={() => {
            hide();
          }}
        />
      ),
    });
  }
}

export default AuthService;
