import { ItemHiddenStateOnMenu } from '@biteinc/enums';

import { priceWithDefaultMods } from '~/app/js/utils/price.utils';

import { GCNModel } from './gcn_model';

export const GCNPriceOption = GCNModel.extend({
  initialize(...args) {
    GCNModel.prototype.initialize.apply(this, args);

    this.addonSets = [];
  },

  getPrice() {
    return gcn.menu.shouldDisplayPriceWithDefaultMods()
      ? this.getPriceIncludingDefaultMods()
      : this.get('price');
  },

  getPriceForAddonsSelectedByDefault(addonIdsSelectedByDefault) {
    let total = 0;
    addonIdsSelectedByDefault.forEach((defaultAddon) => {
      const defaultAddonId = defaultAddon._id;
      const modifier = gcn.menu.getMenuItemWithId(defaultAddonId);
      // A modifier with multiple price options cannot be a default modifier.
      if (modifier.priceOptions.length !== 1) {
        return 0;
      }
      const modPriceOption = modifier.priceOptions[0];
      const addonPrice = defaultAddon.price ?? (modPriceOption.get('price') || 0);
      total += addonPrice;

      if (modPriceOption.addonSets?.length) {
        modPriceOption.addonSets.forEach((addonSet) => {
          const nestedAddonSelectedByDefault = addonSet.getAddonIdsAndPricesSelectedByDefault();
          if (nestedAddonSelectedByDefault.length) {
            total += this.getPriceForAddonsSelectedByDefault(nestedAddonSelectedByDefault);
          }
        });
      }
    });
    return total;
  },

  getPriceIncludingDefaultMods() {
    return priceWithDefaultMods(this);
  },

  isHidden() {
    return (
      (this.get('i9nHiddenState') &&
        this.get('i9nHiddenState') !== ItemHiddenStateOnMenu.Shown &&
        this.get('i9nHiddenState') !== ItemHiddenStateOnMenu.ShownButUnavailable) ||
      (this.get('hiddenState') &&
        this.get('hiddenState') !== ItemHiddenStateOnMenu.Shown &&
        this.get('hiddenState') !== ItemHiddenStateOnMenu.ShownButUnavailable)
    );
  },

  isDefault() {
    if (this.get('selectedByDefaultQuantity') === undefined) {
      return !!this.get('i9nSelectedByDefault');
    }

    return !!this.get('selectedByDefaultQuantity');
  },
});
