import _ from 'underscore';

import { Validators } from '@biteinc/common';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '../backbone-events';
import { localizeStr } from '../localization/localization';
import ScreenReaderHelper from '../screen_reader_helper';
import { blockedWordValidation } from '../utils/blocked_word_validation';
import { GCNView } from './gcn_view';

export const GCNRoomNumberEntryView = GCNView.extend({
  className: 'room-number-entry',

  initialize(...args) {
    GCNView.prototype.initialize.apply(this, args);
  },

  template: _.template(`
    <div class="header">
      <div class="label" role="heading" aria-label="1" tabindex="-1"></div>
    </div>
    <input type="text" id="room-number-input" class="user-input" />
    <div id="keypad-wrapper"></div>
    <div class="validation-failed-message"></div>
    <div class="footer">
      <div class="footer-label"></div>
      <div class="button-container">
        <button type="button" class="button cancel-button"></button>
        <button type="button" class="button done-button"></button>
      </div>
    </div>
  `),

  _validateRoomNumber(roomNumberString) {
    const regex = new RegExp(Validators.roomNumberRegexString());
    const simpleRegexValidation = regex.test(roomNumberString);
    this._$roomNumberValidationFailedMessage.hide();
    if (!simpleRegexValidation) {
      this._$roomNumberValidationFailedMessage.htmlOrText(
        localizeStr(Strings.ERROR_INVALID_ROOM_NUMBER),
      );
      return false;
    }
    const { validation, result } = blockedWordValidation(roomNumberString);
    if (!result) {
      switch (validation) {
        case 'profanity':
        case 'custom':
          this._$roomNumberValidationFailedMessage.htmlOrText(
            localizeStr(Strings.BLOCKED_WORD_WARNING),
          );
          break;
        case 'generic':
        default:
          break;
      }
    }
    return result;
  },

  toggleDoneButton(isDisabled) {
    this.__$doneButton.toggleClass('disabled', isDisabled);
    this.__$doneButton.attr('disabled', isDisabled);
  },

  render() {
    this.$el.html(this.template());

    this._$headerLabel = this.$('.header .label');
    this._$headerLabel.htmlOrText(localizeStr(Strings.OUTPOST_ROOM_NUMBER_INSTRUCTION));
    this.__$cancelButton = this.$('.cancel-button');
    this.__$doneButton = this.$('.done-button');
    this.__$footerLabel = this.$('.footer-label');
    this._$keypadWrapper = this.$('#keypad-wrapper');
    this._$roomNumberEntryInput = this.$('#room-number-input');
    this._$roomNumberValidationFailedMessage = this.$('.validation-failed-message');
    this._$roomNumberValidationFailedMessage.hide();

    this._$roomNumberEntryInput.keyup((ev) => {
      const enteredRoomNumber = ev.target.value;
      const isSafe = this._validateRoomNumber(enteredRoomNumber);
      if (!isSafe) {
        this._$roomNumberValidationFailedMessage.show();
      }
      this.toggleDoneButton(!isSafe);
    });

    this.__$cancelButton.htmlOrText(localizeStr(Strings.CANCEL));
    this.__$cancelButton.attr(
      'aria-label',
      ScreenReaderHelper.prepareAriaLabel(localizeStr(Strings.CANCEL)),
    );
    this.__$doneButton.htmlOrText(localizeStr(Strings.DONE));
    this.__$doneButton.attr(
      'aria-label',
      ScreenReaderHelper.prepareAriaLabel(localizeStr(Strings.DONE)),
    );

    this.__$cancelButton.onButtonTapOrHold('cnclRoomNumber', () => {
      this.trigger(BackboneEvents.GCNOutpostPickerView.BackedOut);
    });

    this.__$doneButton.onButtonTapOrHold('enteredRoomNumber', () => {
      this.trigger(
        BackboneEvents.GCNOutpostPickerView.DidEnterRoom,
        this._$roomNumberEntryInput.val(),
      );
    });

    return this;
  },
});
