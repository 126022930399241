import classNames from 'classnames';
import { motion } from 'framer-motion';
import * as React from 'react';
import { useButton } from 'react-aria';
import { MdChevronRight, MdShoppingCart } from 'react-icons/md';

import { Log } from '@biteinc/common';
import { LocalizationHelper, TimeHelper } from '@biteinc/core-react';
import { KioskOrderingMode } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import GcnOpeningSequenceManager from '~/app/js/gcn_opening_sequence_manager';
import { useLocalize } from '~/app/js/localization/localization';
import ScreenReaderHelper from '~/app/js/screen_reader_helper';
import { ReactAriaButton } from '~/components/button';
import { useLocation, useSettings, useStore } from '~/stores';

import Analytics from '../../app/js/utils/analytics';
import type { UnvalidatedOrder } from '../../types';
import { CartUtils } from './cart.utils';

const viewTriggerVariants = {
  open: { rotate: 90 },
  closed: { rotate: -90 },
};

type CartTopBarProps = {
  onToggle: () => void;
  showCartInfo: boolean;
};

export function CartTopBar({ onToggle, showCartInfo }: CartTopBarProps): JSX.Element {
  const location = useLocation();
  const settings = useSettings();
  const config = useStore((state) => state.config);
  const str = useLocalize();
  const order = useStore((state) => state.cart);
  const toggleCartViewRef = React.useRef<HTMLButtonElement>(null);
  const ariaLabel = showCartInfo ? 'Hide Cart Info' : 'Show Cart Info';
  const popupInView = CartUtils.usePopupInView();

  const scannerOnlyMode = settings.kioskOrderingMode === KioskOrderingMode.ScannerOnly;
  const toggleButtonDisabled = order.orderedItems.length === 0 || popupInView;
  const cartSize = order.orderedItems.reduce((count, orderedItem) => {
    return count + orderedItem.priceOption.quantity;
  }, 0);

  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const { buttonProps } = useButton(
    // to avoid the chromium bug https://bugs.chromium.org/p/chromium/issues/detail?id=1150073 (this event extends to native browsers)
    // if we're using onClick, then thats fine, but if we're using onPress, we need to alias it to onClick
    // @ts-expect-error
    { onClick: onToggle, elementType: 'div' },
    containerRef,
  );

  return (
    <div
      ref={containerRef}
      className="cart-view__top-bar"
      aria-hidden={true}
      {...(gcn.screenReaderIsActive ? {} : buttonProps)}
    >
      <div className="cart-view__top-bar__cart">
        <div className="cart-view__top-bar__cart__icon-container">
          <MdShoppingCart className="cart-view__top-bar__cart__icon-container__icon tw-h-8 tw-w-8" />
          <span
            className="cart-view__top-bar__cart__icon-container__name"
            role="heading"
            aria-level={1}
            aria-hidden={true}
          >
            {str(Strings.CART)}
          </span>
        </div>

        <span
          className="cart-view__top-bar__cart__amount"
          aria-hidden={true}
        >
          {cartSize}
        </span>
      </div>
      {scannerOnlyMode && str(Strings.CART_TITLE) && (
        <div className="cart-view__top-bar__cart-title">
          <span
            className="cart-view__top-bar__cart=title__text"
            aria-label={str(Strings.CART_TITLE).toString()}
          >
            {str(Strings.CART_TITLE)}
          </span>
        </div>
      )}
      {!config.screenReaderIsActive && (
        <ReactAriaButton
          ref={toggleCartViewRef}
          tabIndex={2}
          onPress={onToggle}
          className={classNames(
            `tw-absolute tw-flex tw-justify-center tw-items-center tw-z-[-1] tw-top-0 tw-left-0 tw-h-full tw-w-full tw-bg-transparent tw-py-3 tw-px-4`,
            {
              'tw-opacity-50': toggleButtonDisabled,
              'tw-invisible': scannerOnlyMode,
            },
          )}
          aria-label={ScreenReaderHelper.prepareAriaLabel(ariaLabel)}
          disabled={toggleButtonDisabled}
        >
          <motion.span
            variants={viewTriggerVariants}
            animate={showCartInfo ? 'open' : 'closed'}
          >
            <MdChevronRight className="tw-h-10 tw-w-10" />
          </motion.span>
        </ReactAriaButton>
      )}
      {!config.screenReaderIsActive ? (
        CartUtils.hasSimpleDiningOptions(location) ? (
          <FulfillmentMethodToggle order={order} />
        ) : (
          <FulfillmentMethod order={order} />
        )
      ) : null}
    </div>
  );
}

function FulfillmentMethod({ order }: { order: UnvalidatedOrder }): JSX.Element | null {
  const str = useLocalize();
  const location = useLocation();
  if (!order.fulfillmentMethod || !location.canChangeDiningOptions()) {
    return null;
  }

  const diningOption = location.getDiningOptionForFulfillmentMethod(order.fulfillmentMethod);
  const diningOptionName =
    diningOption.name ??
    str(LocalizationHelper.localizeEnum.FulfillmentMethod(order.fulfillmentMethod));
  return (
    <div className="cart-view__top-bar__fulfillment">
      <span className="cart-view__top-bar__fulfillment__method">{diningOptionName}</span>
      {diningOption?.futureOrdersEnabled && (
        <span className="cart-view__top-bar__fulfillment__pickup">
          {TimeHelper.getFriendlyFutureTimeDescription(order.pickupAtIso)}
        </span>
      )}
      <ReactAriaButton
        className="tw-rounded-md tw-border tw-border-solid tw-py-2 tw-px-3 tw-bg-transparent tw-text-lg cart-view__top-bar__fulfillment__button"
        onPress={() => {
          Analytics.track(Analytics.EventName.CartFulfillmentMethodEditTapped);
          const openingSequenceManager = new GcnOpeningSequenceManager();
          openingSequenceManager.start();
        }}
        aria-label={ScreenReaderHelper.prepareAriaLabel('Change Fulfillment Method')}
        tabIndex={0}
      >
        {str(Strings.MODIFY)}
      </ReactAriaButton>
    </div>
  );
}

function FulfillmentMethodToggle({ order }: { order: UnvalidatedOrder }): React.ReactNode {
  const location = useLocation();
  const str = useLocalize();
  if (!order.fulfillmentMethod || !location.canChangeDiningOptions()) {
    return null;
  }

  const diningOptions = location.diningOptions;
  const activeDiningOption = location.getDiningOptionForFulfillmentMethod(order.fulfillmentMethod);

  return (
    <div className="cart-view__top-bar__fulfillment-toggle tw-relative tw-bg-[rgba(0,0,0,0.4)] tw-text-gray-400 tw-flex tw-rounded-md tw-text-lg">
      {diningOptions.map((option) => (
        <div
          key={option.fulfillmentMethod}
          className="tw-relative"
        >
          <ReactAriaButton
            onPress={() => {
              if (activeDiningOption.fulfillmentMethod === option.fulfillmentMethod) {
                return;
              }
              Analytics.trackEvent({
                eventName: Analytics.EventName.CartFulfillmentMethodToggleTapped,
                eventData: {
                  fulfillmentMethod: option.fulfillmentMethod,
                },
              });
              Log.info('did pick fulfillment method', option.fulfillmentMethod);
              gcn.orderManager.setFulfillmentMethod(option.fulfillmentMethod);

              const openingSequenceManager = new GcnOpeningSequenceManager();
              openingSequenceManager.start();
            }}
            aria-label={ScreenReaderHelper.prepareAriaLabel('Change Fulfillment Method')}
            className="tw-rounded-md tw-m-0.5 tw-py-1 tw-px-6 tw-bg-transparent cart-view__top-bar__fulfillment-toggle__button"
          >
            {str(LocalizationHelper.localizeEnum.FulfillmentMethod(option.fulfillmentMethod)) ??
              option.name}
          </ReactAriaButton>
          {activeDiningOption.fulfillmentMethod === option.fulfillmentMethod ? (
            <motion.span
              layoutId="active-fulfillment"
              className="tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-w-full tw-h-full tw-rounded-md tw-z-10 tw-bg-white tw-flex tw-items-center tw-justify-center tw-shadow-md tw-text-black"
              aria-label={ScreenReaderHelper.prepareAriaLabel('Selected Fulfillment Method')}
            >
              {str(LocalizationHelper.localizeEnum.FulfillmentMethod(option.fulfillmentMethod)) ??
                option.name}
            </motion.span>
          ) : null}
        </div>
      ))}
    </div>
  );
}
