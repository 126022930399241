// cspell:ignore twrv

import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import Analytics from '../utils/analytics';
import { GCNMarketingConsentView } from './gcn_marketing_consent_view';
import { GCNPhoneNumberEntryView } from './gcn_phone_number_entry_view';

export const GCNTextWhenReadyView = GCNPhoneNumberEntryView.extend({
  className() {
    return `${GCNPhoneNumberEntryView.prototype.className.apply(this)} text-when-ready`;
  },

  initialize(options = {}, ...args) {
    this.isCustomerIdentifier = options.isCustomerIdentifier;
    this.customerIdentifierIsOptional = options.customerIdentifierIsOptional;
    const customerPhoneNumber = gcn.orderManager.getCustomer()?.phoneNumber;

    GCNPhoneNumberEntryView.prototype.initialize.apply(this, [
      {
        ...options,
        useNativeInput: !!window.isFlash,
        ...(customerPhoneNumber && { userNumber: customerPhoneNumber }),
      },
      ...args,
    ]);

    this.addRefreshOnLanguageChangeSubscription();
  },

  onDoneButtonTap() {
    if (!this.__$doneButton.hasClass('disabled')) {
      const number = this.__userNumber;
      gcn.orderManager.setGuestPhoneNumber(number);

      if (gcn.menu.settings.get('marketingConsentCheckbox')) {
        const guestConsentedToMarketing = this.marketingConsentView.getValue();
        gcn.orderManager.setGuestConsentedToMarketing(guestConsentedToMarketing);
      }

      this.trigger(BackboneEvents.GCNTextWhenReadyView.CompletedFlow, number);
    }
  },

  // We want to store this for the future if the user hits cancel for some reason
  onCancelButtonTap() {
    if (gcn.menu.settings.get('marketingConsentCheckbox')) {
      const guestConsentedToMarketing = this.marketingConsentView.getValue();
      gcn.orderManager.setGuestConsentedToMarketing(guestConsentedToMarketing);
    }
  },

  __shouldDoneButtonBeDisabled(enteredNumber) {
    if (
      GCNPhoneNumberEntryView.prototype.__shouldDoneButtonBeDisabled.apply(this, [enteredNumber])
    ) {
      return true;
    }

    if (gcn.menu.settings.get('marketingConsentCheckbox')?.isRequiredToContinue) {
      if (this.marketingConsentView) {
        return !this.marketingConsentView.getValue();
      }
      // If we are initializing the view, then it's possible the checkbox hasn't been rendered yet.
      // Fallback to the order manager's value.
      return !gcn.orderManager.getGuestConsentedToMarketing();
    }

    return false;
  },

  render(...args) {
    GCNPhoneNumberEntryView.prototype.render.apply(this, args);

    // Setup view
    if (this.isCustomerIdentifier) {
      this.__$label.htmlOrText(
        gcn.loyaltyManager.getAuthedGuestFriendlyName()
          ? localizeStr(Strings.ENTER_PHONE_CUSTOMER_IDENTIFIER_WITH_NAME, [
              gcn.loyaltyManager.getAuthedGuestFriendlyName(),
            ])
          : localizeStr(Strings.ENTER_PHONE_CUSTOMER_IDENTIFIER),
      );
    } else {
      this.__$label.htmlOrText(localizeStr(Strings.ENTER_PHONE_TEXT_WHEN_READY));
    }

    if (gcn.menu.settings.get('marketingConsentCheckbox')) {
      this.marketingConsentView = new GCNMarketingConsentView();
      this.marketingConsentView.render().$el.insertBefore(this.$('.footer'));
      this.listenTo(
        this.marketingConsentView,
        BackboneEvents.GCNMarketingConsentView.DidUpdateValue,
        () => {
          this.__updateDoneButtonState();
        },
      );
    }

    if (this.isCustomerIdentifier) {
      if (!this.customerIdentifierIsOptional) {
        this.__$skipButton.hide();
        this.__$cancelButton.show();
        this.__$cancelButton.onButtonTapOrHold('twrvCancel', () => {
          Analytics.track(Analytics.EventName.PhoneNumberBackOut);
          this.trigger(BackboneEvents.GCNTextWhenReadyView.BackedOut);
          this.onCancelButtonTap();
        });
      } else {
        this.__$skipButton.show();
        this.__$skipButton.onButtonTapOrHold('twrvSkip', () => {
          Analytics.track(Analytics.EventName.PhoneNumberSkipped);
          this.trigger(BackboneEvents.GCNTextWhenReadyView.CompletedFlow);
        });
      }
    } else {
      this.__$skipButton.onButtonTapOrHold('twrvSkip', () => {
        Analytics.track(Analytics.EventName.PhoneNumberSkipped);
        this.trigger(BackboneEvents.GCNTextWhenReadyView.CompletedFlow);
      });
    }

    this.__$doneButton.onButtonTapOrHold('twrvDone', () => {
      Analytics.track(Analytics.EventName.PhoneNumberSubmitted);
      this.onDoneButtonTap();
    });

    return this;
  },
});
