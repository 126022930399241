import $ from 'jquery';
import _ from 'underscore';

import { Log } from '@biteinc/common';
import {
  ComboUpsellType,
  GroupOrderingNameType,
  LocationRecommendationsLevel,
} from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';
import { AnalyticsEventName } from '~/types/analytics_event';

import { RecommendationDisplayLocationDescription } from '../../../types/recommendation';
import GcnAddonHelpers from '../gcn_addon_helper';
import { flashElement } from '../gcn_app_view';
import GcnRecoTracker from '../gcn_reco_tracker';
import { GCNOrderedItem } from '../models/gcn_ordered_item';
import Analytics from '../utils/analytics';
import { asCallback } from '../utils/promises';
import { GCNAddonSetPickerView } from './gcn_addon_set_picker_view';
import GcnComboUpsellView from './gcn_combo_upsell_view';
import { GCNGroupRecipientNameView } from './gcn_group_recipient_guest_name_view';
import { GCNGroupRecipientNumberView } from './gcn_group_recipient_number_view';
import GcnModifierUpsellView from './gcn_modifier_upsell_view';
import { GCNPriceOptionPickerView } from './gcn_price_option_picker_view';
import { GCNRecommendationsView } from './gcn_recommendations_view';
import { GCNSpecialRequestView } from './gcn_special_request_view';
import { GCNView } from './gcn_view';

// View with all customization choices for an item.
export const GCNMenuItemCustomizeView = GCNView.extend({
  className: 'item-customize-view',

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._options = options;

    this._fetchedInitialRecos = false;

    this._fetchAndRenderRecommendations();
  },

  _fetchAndRenderRecommendations() {
    if (gcn.menu.settings.get('recommendationsLevel') === LocationRecommendationsLevel.Off) {
      return;
    }

    const canDisplayRecos = !this._options.editMode && !this._options.isNested;

    if (!canDisplayRecos) {
      return;
    }

    // If we haven't picked a price option yet, we can't fetch mod group recos.
    if (!this.model.orderedPO) {
      asCallback(
        GcnRecoTracker.getItemRecommendations(gcn.maitred, this.model),
        (err, recommendations) => {
          if (err) {
            Log.error('error loading recos');
            return;
          }

          this._fetchedInitialRecos = true;

          const recoItems = gcn.menu.getMenuItemsFromRecommendations(recommendations, {
            displayRecosLimit: 2,
          });
          this._renderRecos(recoItems);
        },
      );

      return;
    }

    const modGroups = this.model
      .getAddonSets()
      .map((addonSet) => addonSet.get('parentModGroupId') || addonSet.id);
    const virtualModGroups = this.model
      .getAddonSets()
      .map((modGroup) => (modGroup.get('parentModGroupId') ? modGroup.id : null));

    // fetch recos for this item specifically
    asCallback(
      GcnRecoTracker.getBatchedRecommendations(
        gcn.maitred,
        this.model,
        modGroups,
        virtualModGroups,
      ),
      (err, response) => {
        if (err) {
          Log.error('error loading recos');
          return;
        }

        const recoItems = gcn.menu.getMenuItemsFromRecommendations(response.itemRecommendations, {
          displayRecosLimit: 2,
        });
        this._renderRecos(recoItems);

        this.addonSetPickers.forEach((addonSetPicker) => {
          const modGroupId = addonSetPicker.getAddonSet().id;
          const recos = response.modGroupRecommendationsById[modGroupId];
          if (recos) {
            addonSetPicker.refreshRenderRecos();
          }
        });
      },
    );
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);

    this._recosView?.destroy();
  },

  getSpecialRequest() {
    if (this._specialRequestView) {
      return this._specialRequestView.getValue();
    }
    return '';
  },

  isSpecialRequestProfane() {
    if (this._specialRequestView) {
      return this._specialRequestView.isProfane();
    }
    return false;
  },

  getGroupAssignee() {
    if (!gcn.orderManager.getGroupOrderingModeActive()) {
      return null;
    }

    if (this.groupRecipientView) {
      return this.groupRecipientView.getValue();
    }

    // If we're here, group ordering is active (condition 1), and this has to be the first
    // ordered item / guest in the group order because otherwise we would have
    // the group recipient view rendered (condition 2).
    // Since the guest name is entered through a modal that directly sets the recipient name,
    // the guest naming mode is implicitly number, and since this is the first guest, return 1.
    return 1;
  },

  getOrderedRecommendations() {
    return {
      orderedItems: this._recosView?.getOrderedItems() || [],
      recommendationDisplayLocationDescription:
        RecommendationDisplayLocationDescription.MENU_ITEM_CUSTOMIZE_VIEW,
    };
  },

  _pickerSelectionDidChange(picker) {
    this.updateDoneButtonState();

    this.model.setSelectionStructForSetId(picker.getSelectionStruct(), picker.getAddonSet().id);

    this.trigger(BackboneEvents.GCNMenuItemCustomizeView.AddonOrPriceOptionChanged, this);
  },

  updateDoneButtonState() {
    let result = !!this.model.orderedPO;
    _.each(this.addonSetPickers, (picker) => {
      // eslint-disable-next-line no-bitwise
      result &= picker.isSelectionStructValid();
    });

    result &= !this.isSpecialRequestProfane();

    if (this._specialRequestView) {
      this._specialRequestView.enableSubmitButton(result);
    }

    if (this._options.setDoneDisabled) {
      this._options.setDoneDisabled(!result);
    }
  },

  getFirstInvalidAddonId() {
    let invalidAddonId = null;
    this.addonSetPickers?.some((picker) => {
      const invalidModGroupId = picker.validateSelectionStruct();
      if (invalidModGroupId) {
        invalidAddonId = `id-${invalidModGroupId}`;
        return true;
      }
      return false;
    });
    return invalidAddonId;
  },

  getPriceOption() {
    return this.model.orderedPO;
  },

  _canDeemphasizeAddonSets(addonSets) {
    // If this is strictly editing in the cart and not used for recommendations, do not
    // de-emphasize mods, since the user is likely trying to change them.
    return (
      !(this._options.editMode && !this._options.recoEditor) &&
      _.any(addonSets, (addonSet) => {
        return addonSet.get('deemphasize');
      })
    );
  },

  _renderAddonSets(slideDownFirstTime) {
    const self = this;

    if (!this.$addonSetPickersEl) {
      return;
    }

    // Clears previous addons and hidden contents to prevent duplication
    this.$addonSetPickersEl.html('');
    this._$comboBuilderAddonSetPickersContainer.html('');
    this.$hiddenContentEl.html('');

    this.addonSetPickers = [];
    // Checks if the addons have been deemphasized, if so it is added to the hidden container
    const canDeemphasizeAddonSets = this._canDeemphasizeAddonSets(this.model.getAddonSets());
    // If addons or special requests is de-emphasized, hide container and show the link/button
    if (
      (canDeemphasizeAddonSets ||
        (gcn.menu.settings.get('deemphasizeSpecialRequests') &&
          gcn.menu.settings.get('allowSpecialRequests'))) &&
      !this._hiddenContentButtonPressed
    ) {
      this.$showHiddenContentButton.show();
      this.$hiddenContentEl.hide();
    } else {
      this.$showHiddenContentButton.hide();
    }

    let wasPreviousAddonAssorted = false;
    const menuItemIsAssorted = this.model.item.getAddonSetIfAssorted();
    let hasAddedShowHiddenContentButton = false;
    _.each(this.model.getAddonSets(), (addonSet) => {
      if (!addonSet.items.length) {
        return;
      }

      const $addonSet = $('<div class="addon-set margin"></div>');
      let isAddonAssorted = false;
      if (menuItemIsAssorted) {
        // we only want one custom assorted addon for a menu item
        // for any subsequent addon that is/can be an assorted - we ignore
        isAddonAssorted = addonSet.isAssorted();

        if (isAddonAssorted && !wasPreviousAddonAssorted) {
          wasPreviousAddonAssorted = true;
        } else {
          isAddonAssorted = false;
        }
      }

      if (canDeemphasizeAddonSets && addonSet.get('deemphasize')) {
        if (!hasAddedShowHiddenContentButton) {
          self.$showHiddenContentButton.onButtonTapOrHold(
            'micvModIngr',
            self._showHideAddonButtonTap.bind(self),
          );
          self.$addonSetPickersEl.append(self.$showHiddenContentButton);
          self.$showHiddenContentButton.show();
          hasAddedShowHiddenContentButton = true;
        }
        $addonSet.addClass('hidden');
        self.$addonSetPickersEl.append($addonSet);
        // self.$hiddenContentEl.append($addonSet);
      } else if (addonSet.get('useAsComboBuilder')) {
        self._$comboBuilderAddonSetPickersContainer.append($addonSet);
      } else {
        self.$addonSetPickersEl.append($addonSet);
      }
      const pickerView = new GCNAddonSetPickerView({
        showHeader: true,
        addonSet,
        addonsChangedCallback: self._pickerSelectionDidChange.bind(self),
        // We'll have a chance to not ignore the min after this once we know if there are any
        // selections in the combo builder mod groups
        ignoreMinSelectableOnComboBuilder: addonSet.get('useAsComboBuilder'),
        // isAssorted used to apply container backgrounds
        isAssorted: isAddonAssorted,
        parentItem: this.model.item,
        selectedPriceOptionByAddonId: addonSet._selectedPriceOptionByAddonId || {},
        isInitial: !this._fetchedInitialRecos,
        isEditingPrevious: this._options.isFromHistoryItem || this._options.editMode,
      });

      self.listenTo(pickerView, BackboneEvents.GCNAddonSetPickerView.NestedPickerViewShown, () => {
        self.trigger(BackboneEvents.GCNMenuItemCustomizeView.ContentsExpanded);
      });

      self.listenTo(
        pickerView,
        BackboneEvents.GCNAddonSetPickerView.AddonErrorMessageShown,
        (errorMessage) => {
          self.trigger(BackboneEvents.GCNAddonSetPickerView.AddonErrorMessageShown, errorMessage);
        },
      );

      self.listenTo(
        pickerView,
        BackboneEvents.GCNAddonSetPickerView.AddonSelectionComplete,
        (view, callback) => {
          const $pickers = this.$('.addon-set-picker-view');
          const $next = GcnAddonHelpers.findNextAddonPickerEl(view.$el, $pickers);
          if ($next && !$next.is(':hidden')) {
            this.trigger(
              BackboneEvents.GCNMenuItemCustomizeView.AddonSetSelectionComplete,
              $next,
              callback,
            );
          }
        },
      );

      self.addonSetPickers.push(pickerView);
      $addonSet.append(pickerView.render().$el);
    });

    _.each(this.addonSetPickers, (pickerView) => {
      const addonSetId = pickerView.getAddonSet().id;
      pickerView.setSelectionStruct(self.model.getSelectionStructForAddonSetId(addonSetId));
    });

    const comboBuilderAddonSetPickers = this.addonSetPickers.filter((addonSetPicker) => {
      return addonSetPicker.getAddonSet().get('useAsComboBuilder');
    });
    if (comboBuilderAddonSetPickers.length) {
      this._$comboBuilderButtonContainer.show();
      const hasSelectedFromComboBuilders = comboBuilderAddonSetPickers.some((addonSetPicker) => {
        return _.size(addonSetPicker.getSelectionStruct().selections);
      });
      this._setComboBuilderButtonsState(hasSelectedFromComboBuilders);
      if (hasSelectedFromComboBuilders) {
        this._$comboBuilderAddonSetPickersContainer.show();
        comboBuilderAddonSetPickers.forEach((addonSetPicker) => {
          addonSetPicker.setIgnoreMinSelectable(false);
        });
      }
    } else {
      this._$comboBuilderButtonContainer.hide();
    }

    // Adds special request back in when removed by compound menu items
    // and prevents double rendering in other scenarios.
    if (
      gcn.menu.settings.get('allowSpecialRequests') &&
      !this._options.isNested &&
      gcn.menu.settings.get('deemphasizeSpecialRequests') &&
      slideDownFirstTime
    ) {
      this._specialRequestView = new GCNSpecialRequestView({});
      self.listenTo(
        this._specialRequestView,
        BackboneEvents.GCNSpecialRequestView.SpecialRequestTextChanged,
        () => {
          self.trigger(BackboneEvents.GCNMenuItemCustomizeView.SpecialRequestTextChanged);
        },
      );
      this.$hiddenContentEl.append(this._specialRequestView.render().$el);
    }

    if (slideDownFirstTime && !this.rendered) {
      this.$addonSetPickersEl.hide();
      this.$addonSetPickersEl.slideDown();
      this.trigger(BackboneEvents.GCNMenuItemCustomizeView.ContentsExpanded);
    }
    this.rendered = true;
  },

  // Returns the height of the content before the addon sets.
  heightAboveAddons() {
    let height = 0;
    if (this._$header) {
      height += this._$header.outerHeight();
    }
    if (this.priceOptionPickerView) {
      height += this.priceOptionPickerView.$el.outerHeight();
    }
    return height;
  },

  _showHideAddonButtonTap() {
    this._hiddenContentButtonPressed = true;
    this.$addonSetPickersEl.children('.hidden').slideDown();
    this.$hiddenContentEl.slideDown();
    this.$showHiddenContentButton.slideUp();
  },

  _comboBuilderButtonWasTapped(makeItACombo) {
    this._setComboBuilderButtonsState(makeItACombo);

    const comboBuilderAddonSetPickers = this.addonSetPickers.filter((addonSetPicker) => {
      return addonSetPicker.getAddonSet().get('useAsComboBuilder');
    });

    if (makeItACombo) {
      this._$comboBuilderAddonSetPickersContainer.slideDown();
      // Scroll to past the combo button to reveal all the combo options
      this.trigger(
        BackboneEvents.GCNMenuItemCustomizeView.ComboButtonTapped,
        this._$comboBuilderAddonSetPickersContainer,
        true,
      );

      const defaultSelectionStructByAddonSetId =
        GCNOrderedItem.defaultSelectionStructFromPriceOption(this.model.orderedPO.po, true);

      comboBuilderAddonSetPickers.forEach((addonSetPicker) => {
        // Activate the minimums on the combo builder mod groups
        addonSetPicker.setIgnoreMinSelectable(false);

        // Now apply the default selections to the combo builder mod groups
        const addonSetId = addonSetPicker.getAddonSet().id;
        const defaultSelectionStruct = defaultSelectionStructByAddonSetId[addonSetId];
        addonSetPicker.setSelectionStruct(defaultSelectionStruct);
      });
    } else {
      this._$comboBuilderAddonSetPickersContainer.slideUp();
      comboBuilderAddonSetPickers.forEach((addonSetPicker) => {
        // Ignore the minimums on the combo builder mod groups
        addonSetPicker.setIgnoreMinSelectable(true);
        // Get rid of all selections from the combo builder mod groups so the item is back to
        // non-combo mode
        addonSetPicker.deselectEverything();
      });
    }
  },

  _setComboBuilderButtonsState(makeItACombo) {
    this._$comboBuilderButton.toggleClass('make-it-a-combo', !makeItACombo);
    this._$comboBuilderButton.toggleClass('no-combo', makeItACombo);
    this._$comboBuilderButton.htmlOrText(
      localizeStr(makeItACombo ? Strings.MAKE_IT_A_COMBO_NO : Strings.MAKE_IT_A_COMBO_YES),
    );
  },

  _shouldShowComboUpsell() {
    return (
      this.model?.item.get('comboUpsell')?.type === ComboUpsellType.ComboBuilderModGroup &&
      !this.hasRecommendedCombo &&
      gcn.orderManager.comboUpsellCountWithinCap()
    );
  },

  _showComboUpsell() {
    this.hasRecommendedCombo = true;
    const comboUpsellView = new GcnComboUpsellView({
      model: this.model.item,
      originalModel: this.model.item,
      sectionId: this.model.item.get('section')?.id,
      onAccept: () => {
        gcn.menuView.dismissPopup();
        this._comboBuilderButtonWasTapped(true);
      },
      onDismiss: () => {
        gcn.menuView.dismissPopup();
      },
    });

    gcn.orderManager.incrementComboUpsellPromptCount();
    gcn.menuView.showPopup(comboUpsellView, undefined, 'centered');
  },

  _shouldShowModUpsell() {
    if (this.hasRecommendedMod || this._options.editMode) {
      return false;
    }

    const upsellModId = this.model.item.get('upsellModifierItemId');
    if (!upsellModId || !gcn.menu.settings.get('upsellModifiers')) {
      return false;
    }

    // can't recommend a mod if we haven't picked a PO yet
    if (!this.model.orderedPO) {
      return false;
    }

    const priceOptionHasMod = this.model.orderedPO.po.addonSets?.some((modGroup) => {
      return modGroup.containsItemWithId(upsellModId);
    });

    if (!priceOptionHasMod) {
      return false;
    }

    if (!gcn.menu.getMenuItemWithId(upsellModId)) {
      return false;
    }

    return true;
  },

  _showModUpsell() {
    const upsellModId = this.model.item.get('upsellModifierItemId');
    const upsellModifierItem = gcn.menu.getMenuItemWithId(upsellModId);
    const addonSet = this.model.orderedPO.po.addonSets.find((addonSet) => {
      if (addonSet.containsItemWithId(upsellModId)) {
        return true;
      }
      return false;
    });

    if (upsellModifierItem && addonSet) {
      Analytics.track(AnalyticsEventName.UpsellModifierShown);

      const upsellModifierView = new GcnModifierUpsellView({
        model: upsellModifierItem,
        onAccept: () => {
          this.hasRecommendedMod = true;

          this.addonSetPickers.forEach((picker) => {
            if (picker.getAddonSet().id === addonSet.id) {
              picker.setSelectionStruct(
                {
                  model: gcn.menu.getAddonSetWithId(addonSet.id),
                  selections: {
                    [upsellModId]: {
                      model: upsellModifierItem,
                      quantity: 1,
                    },
                  },
                },
                undefined,
                'modifier-upsell-view',
              );

              picker.render();
            }
          });

          gcn.menuView.dismissPopup();
        },
        onDismiss: () => {
          this.hasRecommendedMod = true;
          gcn.menuView.dismissPopup();
        },
      });
      gcn.menuView.showPopup(upsellModifierView, undefined, 'centered');
      return;
    }
    // fall through to the normal item tap
    Log.error(`upsell item ${upsellModId} not found`);
  },

  _renderRecos(recoItems) {
    if (gcn.menu.settings.get('recommendationsLevel') !== LocationRecommendationsLevel.Passive) {
      return;
    }

    this._recosView = new GCNRecommendationsView({
      model: this.model.item,
      upsellScreen: 'GCNMenuItemCustomizeView',
      recos: recoItems,
      usePopup: true,
    });

    GcnRecoTracker.trackDisplayRecommendations(
      gcn.maitred,
      RecommendationDisplayLocationDescription.MENU_ITEM_CUSTOMIZE_VIEW,
      recoItems.map((item) => {
        return item.id;
      }),
    );

    this.$recosWrapper.append(this._recosView.render().$el);

    this.listenTo(
      this._recosView,
      BackboneEvents.GCNRecommendationsView.SelectedItemsChanged,
      () => {
        this.trigger(BackboneEvents.GCNMenuItemCustomizeView.SelectedRecosChanged);
      },
    );
  },

  render() {
    const self = this;
    this.$el.html('');

    // Header for edit mode.
    if (this._options.editMode) {
      this._$header = $(
        // prettier-ignore
        '<div class="item-header">' +
          '<div class="item-image"></div>' +
          '<div class="item-name font-title"></div>' +
        '</div>',
      );
      this.$el.append(this._$header);
      if (this.model.item.hasArr('images')) {
        const images = this.model.item.get('images');
        const url = images[images.length - 1].url;
        gcn.requestImageByUrl(url, (err, imgPath) => {
          self._$header.find('.item-image').css('background-image', `url(${imgPath})`);
        });
      } else {
        // Apply a default image.
        this._$header.find('.item-image').addClass(gcn.menu.placeholderImageClass(this.model.item));
      }
      this._$header.find('.item-name').html(this.model.item.displayNameHtml());
    }

    if (
      gcn.orderManager.getGroupOrderingModeActive() &&
      gcn.orderManager.getDistinctGroupOrderGuestLabels().length
    ) {
      if (gcn.menu.settings.get('groupOrderingNameType') === GroupOrderingNameType.Name) {
        this.groupRecipientView = new GCNGroupRecipientNameView({
          currentGuestName: this._options.editMode ? this.model.get('recipientName') : null,
        });

        this.$el.append(this.groupRecipientView.render().$el);
      } else {
        this.groupRecipientView = new GCNGroupRecipientNumberView({
          currentGuestNumber: this._options.editMode ? this.model.get('recipientNumber') : null,
        });
        this.$el.append(this.groupRecipientView.render().$el);
      }
    }

    // Price options.
    const priceOptions = this.model.item.priceOptions;
    if (priceOptions.length > 1) {
      this.priceOptionPickerView = new GCNPriceOptionPickerView({
        showHeader: true,
        priceOptionCategory: this.model.item.get('priceOptionCategory'),
        priceOptions,
        priceOptionChangedCallback(selectedPriceOption) {
          if (this._hiddenContentButtonPressed) {
            this._hiddenContentButtonPressed = false;
          }

          const showComboUpsell = self._shouldShowComboUpsell();
          self.model.setPriceOption(selectedPriceOption);
          self._renderAddonSets(true);
          self.updateDoneButtonState();
          const $firstPicker = self.$('.addon-set-picker-view').first();
          if (showComboUpsell) {
            self._showComboUpsell();
          } else if (self._shouldShowModUpsell()) {
            self._showModUpsell();
          }
          if (
            !self._autoScrolledPOOnce &&
            $firstPicker.length &&
            gcn.menu.settings.get('autoScrollCustomization') &&
            !showComboUpsell
          ) {
            // Scroll down to the first addon set picker.
            self._autoScrolledPOOnce = true;
            // Slight delay so user can see tap feedback.
            setTimeout(() => {
              const eventName = BackboneEvents.GCNMenuItemCustomizeView.AddonSetSelectionComplete;
              self.trigger(eventName, $firstPicker, () => {
                flashElement($firstPicker.find('.header .title'), 'flash');
              });
            }, 350);
          }

          self.trigger(BackboneEvents.GCNMenuItemCustomizeView.AddonOrPriceOptionChanged, self);
        },
      });
      if (self.model.orderedPO) {
        this.priceOptionPickerView.selectedPriceOption = self.model.orderedPO.po;
      }

      const $priceOptions = $('<div class="price-option-picker margin"></div>');
      this.$el.append($priceOptions);
      $priceOptions.append(this.priceOptionPickerView.render().$el);
    } else if (this._shouldShowComboUpsell()) {
      this._showComboUpsell();
    } else if (self._shouldShowModUpsell()) {
      self._showModUpsell();
    }

    // For Addons
    this.$addonSetPickersEl = $('<div class="addon-set-pickers"></div>');
    this.$el.append(this.$addonSetPickersEl);

    // Elements for combo builders
    this._$comboBuilderButtonContainer = $('<div class="combo-builder-button-container"></div>');
    this._$comboBuilderButton = $('<div class="combo-builder-button"></div>');
    this._$comboBuilderButton.onButtonTapOrHold('micvCombo', (event) => {
      this._comboBuilderButtonWasTapped(!$(event.target).hasClass('no-combo'));
    });
    this._$comboBuilderButtonContainer.append(this._$comboBuilderButton);
    this.$el.append(this._$comboBuilderButtonContainer);
    this._$comboBuilderButtonContainer.hide();
    this._$comboBuilderAddonSetPickersContainer = $(
      '<div class="addon-set-pickers combo-builders"></div>',
    );
    this.$el.append(this._$comboBuilderAddonSetPickersContainer);
    this._$comboBuilderAddonSetPickersContainer.hide();

    // Elements for deemphasized ingredient modifiers and special request
    this.$showHiddenContentButton = $(`<div class="link-button"></div>`);

    const deemphasizeButtonImages = this.model.item.get('deemphasizeButtonImage') ?? [];
    if (deemphasizeButtonImages.length) {
      const buttonImage = deemphasizeButtonImages[0].url;
      gcn.requestImageByUrl(buttonImage, (err, imgPath) => {
        this.$showHiddenContentButton.prepend(
          `<img src="${imgPath}" class="deemphasize-button-image" aria-label=${localizeStr(Strings.MODIFY_INGREDIENTS)}/>`,
        );
      });
    } else {
      this.$showHiddenContentButton.prepend(localizeStr(Strings.MODIFY_INGREDIENTS));
    }
    this.$showHiddenContentButton.onButtonTapOrHold(
      'micvModIngr',
      this._showHideAddonButtonTap.bind(this),
    );
    this.$showHiddenContentButton.hide();

    // For Special Requests
    this.$hiddenContentEl = $('<div class="addon-set-pickers hidden"></div>');
    this.$el.append(this.$hiddenContentEl);
    this.$hiddenContentEl.hide();

    if (this.model.orderedPO) {
      this._renderAddonSets();
    }

    // Special requests.
    if (gcn.menu.settings.get('allowSpecialRequests') && !this._options.isNested) {
      // If allowing special requests, add in the box
      this._specialRequestView = new GCNSpecialRequestView({});
      self.listenTo(
        this._specialRequestView,
        BackboneEvents.GCNSpecialRequestView.SpecialRequestTextChanged,
        () => {
          self.trigger(BackboneEvents.GCNMenuItemCustomizeView.SpecialRequestTextChanged);
        },
      );
      if (gcn.menu.settings.get('deemphasizeSpecialRequests')) {
        // Add special requests box to the hidden content container
        this.$hiddenContentEl.append(this._specialRequestView.render().$el);
      } else {
        this.$el.append(this._specialRequestView.render().$el);
      }
      if (this.model.hasStr('specialRequest')) {
        this._specialRequestView.setValue(this.model.get('specialRequest'));
      }
    }

    if (gcn.menu.settings.get('recommendationsLevel') === LocationRecommendationsLevel.Passive) {
      this.$recosWrapper = $('<div class="recommendations-wrapper"></div>');
      this.$el.append(this.$recosWrapper);
    }

    // Initial check of addon set restrictions.
    this.updateDoneButtonState();

    if (gcn.screenReaderIsActive) {
      // If the screen reader is active, focus on the first aria heading we have
      this.$('[role="heading"]').first().requestFocusAfterDelay();
    }

    return this;
  },
});
