import _ from 'underscore';

import { Log } from '@biteinc/common';
import { LocationPaymentType, OrderClientEventName } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import {
  CHECKOUT_VIEW_ENTER_EVENT,
  CHECKOUT_VIEW_EXIT_EVENT,
} from '../../../helpers/custom_events';
import { GCNRouterHelper } from '../gcn_router_helper';
import Analytics from '../utils/analytics';
import { GCNAlertView } from './gcn_alert_view';
import { GCNFullScreenFlowView } from './gcn_full_screen_flow_view';
import { GCNAsterHallCompletedStepView } from './step_views/gcn_aster_hall_completed_step_view';
import { GCNAsterHallPaymentStepView } from './step_views/gcn_aster_hall_payment_step_view';
import { GCNAsterHallSummaryStepView } from './step_views/gcn_aster_hall_summary_step_view';
import { GCNFullScreenFlowStepView } from './step_views/gcn_full_screen_flow_step_view';
import { GCNHandlePaymentStepView } from './step_views/gcn_handle_payment_step_view';
import { GCNOrderSummaryStepView } from './step_views/gcn_order_summary_step_view';
import { GCNPaymentCompletedStepView } from './step_views/gcn_payment_completed_step_view';

export const GCNCheckoutFlowView = GCNFullScreenFlowView.extend({
  className: 'checkout-flow-view',
  criticalPeriodName: 'paymentFlow',

  initialize(options, ...args) {
    document.dispatchEvent(CHECKOUT_VIEW_ENTER_EVENT);
    const orderSize = gcn.orderManager.getOrderSize();
    this.hasGoneHome = false;
    Analytics.trackEvent({
      eventName: Analytics.EventName.CheckoutStart,
      eventData: {
        cartSize: orderSize,
        cartSubTotal: gcn.orderManager.getSubTotal(),
      },
    });
    gcn.orderManager.eventRepo.trackCheckoutStart(orderSize);

    this._fastForwardToSendOrder = (options || {}).fastForwardToSendOrder;

    GCNFullScreenFlowView.prototype.initialize.apply(this, [options, ...args]);

    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.PaymentFlowDidStart, this._startPaymentFlow);
    this.listenTo(
      gcn,
      BackboneEvents.GCNMenuAppView.PaymentProcessingDidStart,
      this._paymentProcessingDidStart,
    );
    this.listenTo(
      gcn,
      BackboneEvents.GCNMenuAppView.SendingOrderDidStart,
      this._showSendingOrderSpinner,
    );
    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.LocalPrintDidFail, this._localPrintDidFail);
    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.OrderDidComplete, this._moveToCompletedOrder);
    this.listenTo(
      gcn,
      BackboneEvents.GCNMenuAppView.TransactionDidCancel,
      this.cancelCreditCardPayment,
    );
    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.OrderFailedToGet, this.failedToGetOrder);
    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.CardWasSwiped, this._handleSwipeCard);
    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.SmartCardWasInserted, this._handleDipCard);
    this.listenTo(
      gcn,
      BackboneEvents.GCNMenuAppView.AmountConfirmationWasStarted,
      this._showAmountConfirmationPrompt,
    );

    this.steps.push(this._getSummaryStepView());
    gcn.checkoutFlowView = this;
  },

  _getSummaryStepView() {
    const options = { parent: this };
    if (gcn.location.isAsterHall()) {
      return new GCNAsterHallSummaryStepView(options);
    }
    return new GCNOrderSummaryStepView(options);
  },

  _getCompletedStepView() {
    const options = {
      parent: this,
      cardMethodWasDip: this._cardMethodWasDip,
      canTimeout: !this._localPrintFailed,
    };
    if (gcn.location.isAsterHall()) {
      return new GCNAsterHallCompletedStepView(options);
    }
    return new GCNPaymentCompletedStepView(options);
  },

  _startPaymentFlow() {
    if (this._currentStepType() === GCNFullScreenFlowStepView.StepType.HandlePayment) {
      _.last(this.steps).resetPaymentFlowVisuals(true);
    }
  },

  _paymentProcessingDidStart() {
    if (this._currentStepType() === GCNFullScreenFlowStepView.StepType.HandlePayment) {
      gcn.menuView.showSpinner(localizeStr(Strings.PROCESSING_PAYMENT));
      _.last(this.steps).lockCancelTransactionButton();
    }
  },

  _showSendingOrderSpinner() {
    if (
      this._currentStepType() === GCNFullScreenFlowStepView.StepType.HandlePayment ||
      this._currentStepType() === GCNFullScreenFlowStepView.StepType.OrderSummary
    ) {
      gcn.orderManager.eventRepo.track(OrderClientEventName.OrderSendStart);
      Analytics.track(Analytics.EventName.OrderSendStart);
      gcn.menuView.showSpinner(localizeStr(Strings.SENDING_ORDER_KITCHEN));
    }
  },

  _localPrintDidFail() {
    this._localPrintFailed = true;
    this._moveToCompletedOrder();
  },

  _moveToCompletedOrder() {
    gcn.orderManager.performOrderCompletedTasks(this._paymentStartedTime);

    const StepType = GCNFullScreenFlowStepView.StepType;
    if (
      StepType.HandlePayment === this._currentStepType() ||
      StepType.OrderSummary === this._currentStepType()
    ) {
      gcn.menuView.dismissSpinner();
      this._proceedToNextStepWithoutSendingOrder();
    }
  },

  cancelCreditCardPayment() {
    if (this._currentStepType() === GCNFullScreenFlowStepView.StepType.HandlePayment) {
      gcn.orderManager.eventRepo.track(OrderClientEventName.PaymentCreditCardCancel);
      Analytics.track(Analytics.EventName.CreditCartPaymentCancel);

      this.exitPaymentFlow();
    }
  },

  failedToGetOrder() {
    if (this._currentStepType() === GCNFullScreenFlowStepView.StepType.HandlePayment) {
      gcn.orderManager.eventRepo.track(OrderClientEventName.OrderFailedToGet);
      Analytics.track(Analytics.EventName.OrderFailedToGet);

      const confirmView = new GCNAlertView({
        text: localizeStr(Strings.ORDER_GET_ERROR),
        okText: localizeStr(Strings.OK),
        okCallback: () => {
          gcn.menuView.dismissModalPopup();
        },
      });
      gcn.menuView.showModalPopup(confirmView);

      this.exitPaymentFlow();
    }
  },

  exitPaymentFlow() {
    if (this._currentStepType() === GCNFullScreenFlowStepView.StepType.HandlePayment) {
      this.exitCheckoutFlow();
      GCNRouterHelper.navToMenu();
    }
  },

  // Single exit point for the checkout flow, which clears all checkout state.
  exitCheckoutFlow() {
    gcn.orderManager.eventRepo.track(OrderClientEventName.CheckoutCancel);
    Analytics.track(Analytics.EventName.CheckoutCancel);
    gcn.orderManager.clearCheckout();
    gcn.orderManager.revertOrderedItems();

    gcn.menuView.hideFullScreen(this.criticalPeriodName);
  },

  /**
   * Exit point for the checkout flow, but specifically for when an order operation fails due to a
   * network timeout error
   */
  exitCheckoutFlowDueToTimeout() {
    gcn.menuView.dismissSpinner();
    gcn.menuView.dismissStablePopup();
    const alertText = localizeStr(Strings.ERROR_ORDER_OPERATION, [], function (string) {
      return string.split('\n').join('<br />');
    });
    const confirmView = new GCNAlertView({
      text: alertText,
      okCallback: () => {
        gcn.menuView.dismissModalPopup();
        gcn.checkoutFlowView.exitCheckoutFlow();
        GCNRouterHelper.navToMenu();
      },
    });
    gcn.menuView.showModalPopup(confirmView);
  },

  _handleSwipeCard() {
    if (this._currentStepType() === GCNFullScreenFlowStepView.StepType.HandlePayment) {
      _.last(this.steps).lockInPaymentImage(true);
    }
  },

  _handleDipCard() {
    if (this._currentStepType() === GCNFullScreenFlowStepView.StepType.HandlePayment) {
      _.last(this.steps).lockInPaymentImage();
    }
    // Relay the card method to the next step.
    this._cardMethodWasDip = true;
  },

  _showAmountConfirmationPrompt() {
    if (this._currentStepType() === GCNFullScreenFlowStepView.StepType.HandlePayment) {
      _.last(this.steps).showAmountConfirmationPrompt();
    }
  },

  // Based on the current state of things, return the next step to advance to,
  // or return undefined if there are no more steps.
  _generateNextStep() {
    const currentStep = _.last(this.steps);
    switch (currentStep.stepType) {
      case GCNFullScreenFlowStepView.StepType.OrderSummary: {
        if (gcn.orderManager.payingAtCashier()) {
          return new GCNPaymentCompletedStepView({
            parent: this,
            payAtCashier: true,
            canTimeout: !this._localPrintFailed,
          });
        }

        if (gcn.menu.settings.get('allowZeroDollarOrders')) {
          return this._getCompletedStepView();
        }
        // proceed to complete for already paid for orders
        if (gcn.location.useOrdersApiV2() && gcn.orderManager.isOrderClosed()) {
          return this._getCompletedStepView();
        }
        if (gcn.orderManager.getGrandTotal() === 0) {
          return this._getCompletedStepView();
        }

        const options = {
          parent: this,
          recoveryFastForward: this._fastForwardToSendOrder,
        };

        switch (gcn.location.get('paymentType')) {
          case LocationPaymentType.CashierOnly:
            return new GCNPaymentCompletedStepView({
              parent: this,
              canTimeout: !this._localPrintFailed,
            });
          case LocationPaymentType.CreditCard:
            if (gcn.location.isAsterHall()) {
              return new GCNAsterHallPaymentStepView(options);
            }
            return new GCNHandlePaymentStepView(options);
        }
        return undefined;
      }
      case GCNFullScreenFlowStepView.StepType.HandlePayment:
        return this._getCompletedStepView();
      case GCNFullScreenFlowStepView.StepType.PaymentCompleted:
        return undefined;
    }

    Log.error('Got current step with unrecognized step type', currentStep.stepType);
    return undefined;
  },

  _currentStepType() {
    return _.last(this.steps).stepType;
  },

  submitSurveyResult(surveyValue) {
    const order = gcn.orderManager.getOrder();
    if (order && surveyValue >= 0 && surveyValue <= 3) {
      gcn.maitred.submitGuestSurvey(order, surveyValue);
    }
  },

  restartTimeout() {
    this.startTimeout(this._timeoutDelay);
  },

  startTimeout(delayInMs) {
    if (gcn.screenReaderIsActive) {
      // We don't want to start the timeout if screen reader is active.
      return;
    }
    this._timeoutDelay = delayInMs;
    this.cancelTimeout();
    this.timeout = setTimeout(() => {
      this.goHome();
    }, this._timeoutDelay);
  },

  cancelTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },

  goHome() {
    if (this.hasGoneHome) {
      return;
    }

    this.hasGoneHome = true;
    gcn.goHome();
  },

  nextStep(fromStep) {
    const currentStep = _.last(this.steps);
    if (fromStep !== currentStep) {
      Log.error(
        'CheckoutFlow not going to advance from some random step',
        currentStep.stepType,
        fromStep.stepType,
      );
      return;
    }

    const StepType = GCNFullScreenFlowStepView.StepType;
    const currentStepType = this._currentStepType();
    const isDemoPay = StepType.HandlePayment === currentStepType && !window.hasPaymentTerminal;
    const skippingPayment =
      !gcn.location.takesPayment() ||
      gcn.orderManager.payingAtCashier() ||
      gcn.orderManager.getGrandTotal() === 0;

    const checkoutSession = gcn.orderManager.getCheckoutSession();
    if ((StepType.OrderSummary === currentStepType && skippingPayment) || isDemoPay) {
      if (isDemoPay) {
        gcn.orderManager.eventRepo.track(OrderClientEventName.PaymentCreditCardEnd);
        Analytics.track(Analytics.EventName.CreditCardPaymentComplete);
      }
      gcn.menuView.showSpinner(localizeStr(Strings.SENDING_ORDER_KITCHEN));
      // This actually sends the order through.
      gcn.sendCheckoutSession(checkoutSession);
    } else {
      this._proceedToNextStepWithoutSendingOrder();
    }
  },

  _proceedToNextStepWithoutSendingOrder(...args) {
    const nextStep = this._generateNextStep();
    if (nextStep === undefined) {
      // TODO: Need to implement the robustness front end logic.
      this.cancelTimeout();
      this.goHome();
      return;
    }
    this.steps.push(nextStep);
    this.$contents.append(nextStep.render().$el);

    if (nextStep.stepType === GCNFullScreenFlowStepView.StepType.HandlePayment) {
      this._paymentStartedTime = Date.now();
      gcn.orderManager.eventRepo.track(OrderClientEventName.PaymentCreditCardStart);
      Analytics.track(Analytics.EventName.CreditCardPaymentStart);
    }

    GCNFullScreenFlowView.prototype.nextStep.apply(this, args);
  },

  destroy(...args) {
    GCNFullScreenFlowView.prototype.destroy.apply(this, args);
    gcn.checkoutFlowView = null;
    document.dispatchEvent(CHECKOUT_VIEW_EXIT_EVENT);
  },

  prevStep(...args) {
    if (this.steps.length === 1) {
      this.exitCheckoutFlow();
      GCNRouterHelper.navToMenu();
      return;
    }

    GCNFullScreenFlowView.prototype.prevStep.apply(this, args);
  },

  summaryStepTransitionEnd() {
    if (this._fastForwardToSendOrder) {
      // Need to do this on a timer because otherwise the next step will not
      // see their transition end event.
      setTimeout(() => {
        if (gcn.orderManager.getGrandTotal() === 0) {
          gcn.notifyOrderRecoveryIsReady();
        } else {
          this._proceedToNextStepWithoutSendingOrder();
        }
      }, 1);
    }
  },

  handlePaymentTransitionEnded() {
    if (this._fastForwardToSendOrder) {
      gcn.notifyOrderRecoveryIsReady();
    }
  },
});
