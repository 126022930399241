import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import { useStore } from '../../../stores';
import Errors from '../utils/errors';
import { GCNView } from './gcn_view';

export const GCNEbtCardTerminalView = GCNView.extend({
  className: 'ebt-card-terminal-view',

  template: _.template(
    // prettier-ignore
    '<div class="body">' +
      '<div class="title"></div>' +
      '<div class="images-container">' +
        '<div class="image primary"></div>' +
      '</div>' +
      '<div class="subtitle"></div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.grandTotal = options.grandTotal;
    this.ebtMode = options.ebtMode;
    this._callback = options.callback;
    this._readEbtCard();
  },

  async _readEbtCard() {
    try {
      gcn.menuView.showSpinner(localizeStr(Strings.PROCESSING_PAYMENT));

      let data;
      try {
        data = await gcn.maitred.createKioskApiPaymentRequest({ ebtMode: this.ebtMode });
      } finally {
        gcn.menuView.dismissSpinner();
      }

      gcn.orderManager.setOrderFromJSON(data.order);
      gcn.orderManager.setTransactionsFromJson(data.successfulTransactions);
      useStore.getState().checkout.onStoredValueUpdated();
    } catch (err) {
      this._showError(Errors.stringFromErrorCode(err.code), this._callback);
      return;
    }

    this._callback();
  },

  _showError(message, callback) {
    // We can't use the built-in timeout feature with GCNAlertView because we need a cancel
    // callback without showing a cancel button
    const errorTimeout = setTimeout(() => {
      gcn.menuView.dismissModalPopup();
      callback();
    }, 15 * 1000);

    gcn.menuView.showSimpleAlert(message, () => {
      clearTimeout(errorTimeout);
      callback();
    });
  },

  render() {
    this.$el.html(this.template());

    this._$title = this.$('.title');
    this._$subtitle = this.$('.subtitle');
    this._$image = this.$('.image.primary');

    this._$title.htmlOrText(localizeStr(Strings.GIFT_CARD_TITLE));
    this._$subtitle.htmlOrText(localizeStr(Strings.EBT_CARD_SUBTITLE));
    this._$image.addClass('ingenico');

    return this;
  },
});
