import _ from 'underscore';

import { StringHelper } from '@biteinc/core-react';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

export const GCNVehicleDescriptionEntryView = GCNView.extend({
  className: 'order-destination-view curbside',

  _template: _.template(
    // prettier-ignore
    '<div class="vehicle-description">' +
      '<div class="back-button"></div>' +
      '<div class="header" role="heading" aria-level="1" tabindex="-1"></div>' +
      '<div class="body">' +
        '<input class="vehicle-guest-name-input" maxlength="16" autocomplete="off" autocorrect="off" placeholder="<%= yourNameStr %>" />' +
        '<input class="vehicle-make-input" maxlength="16" autocomplete="off" autocorrect="off" placeholder="<%= vehicleMakeStr %>" />' +
        '<input class="vehicle-model-input" maxlength="16" autocomplete="off" autocorrect="off" placeholder="<%= vehicleModelStr %>" />' +
        '<input class="vehicle-color-input" maxlength="16" autocomplete="off" autocorrect="off" placeholder="<%= vehicleColorStr %>" />' +
      '</div>' +
      '<div class="button-container">' +
        '<div class="done-button"></div>' +
      '</div>' +
      '<div class="footer">' +
        '<div class="footnote"></div>' +
      '</div>' +
    '</div>',
  ),

  _renderBackButton() {
    this.$('.back-button').htmlOrText(localizeStr(Strings.BACK));
    this.$('.back-button').onButtonTapOrHold('vdevBack', () => {
      Analytics.track(Analytics.EventName.VehicleDescriptionBackOut);
      this.trigger(BackboneEvents.GCNVehicleDescriptionEntryView.BackedOut);
    });
  },

  _enableDoneButton(enable) {
    this.$doneButton.toggleClass('disabled', !enable);
  },

  _submitCurbsideDetails() {
    gcn.orderManager.setGuestName(this._getSanitizedUserInput(this.$vehicleGuestNameField.val()));
    const vehicleDescription = {
      make: this._getSanitizedUserInput(this.$vehicleMakeField.val()),
      model: this._getSanitizedUserInput(this.$vehicleModelField.val()),
      color: this._getSanitizedUserInput(this.$vehicleColorField.val()),
    };
    gcn.orderManager.setGuestVehicleDescription(vehicleDescription);
    Analytics.track(Analytics.EventName.VehicleDescriptionSubmitted);
    this.trigger(BackboneEvents.GCNVehicleDescriptionEntryView.DidEnterVehicleDescription);
  },

  blur() {
    _.each(this._allVehicleInputFields, ($inputField) => {
      $inputField.blur();
    });
    setTimeout(() => {
      // TODO: This causes an occasional janky hiding animation when pressing
      // the back button. Consider finding a way to avoid calling this if the
      // text field is focused but the keyboard is NOT shown, as doing that
      // shows the keyboard.
      gcn.notifyUserDidFocusOutOfTextField();
    }, 1);
  },

  _vehicleDescriptionValid() {
    return _.all(this._allVehicleInputFields, ($inputField) => {
      return !!this._getSanitizedUserInput($inputField.val()).length;
    });
  },

  _getSanitizedUserInput(inputValue) {
    return StringHelper.removeDiacritics(StringHelper.toTitleCase(inputValue)).replace(
      /[^a-zA-Z0-9\s\-\\.]/g,
      '',
    );
  },

  render() {
    this.$el.html(
      this._template({
        yourNameStr: localizeStr(Strings.YOUR_NAME),
        vehicleColorStr: localizeStr(Strings.VEHICLE_COLOR),
        vehicleMakeStr: localizeStr(Strings.VEHICLE_MAKE),
        vehicleModelStr: localizeStr(Strings.VEHICLE_MODEL),
      }),
    );

    // Set up common fields.
    this.$('.header').htmlOrText(localizeStr(Strings.ENTER_VEHICLE_DESCRIPTION));
    if (gcn.screenReaderIsActive) {
      this.$('.header').requestFocusAfterDelay();
    }

    // Set up text fields.
    this.$vehicleGuestNameField = this.$('.vehicle-guest-name-input');
    this.$vehicleMakeField = this.$('.vehicle-make-input');
    this.$vehicleModelField = this.$('.vehicle-model-input');
    this.$vehicleColorField = this.$('.vehicle-color-input');
    this._allVehicleInputFields = [
      this.$vehicleGuestNameField,
      this.$vehicleMakeField,
      this.$vehicleModelField,
      this.$vehicleColorField,
    ];

    _.each(this._allVehicleInputFields, ($inputField) => {
      $inputField.focus(() => {
        gcn.notifyUserDidFocusOnTextField();
      });
      // All of them should support enter.
      $inputField.keyup((e) => {
        const inputIsValid = this._vehicleDescriptionValid();
        this._enableDoneButton(inputIsValid);

        // Submit on enter.
        if (e.keyCode === 13 && inputIsValid) {
          this._submitCurbsideDetails();
        }
        return false;
      });
    });

    // Set up done button.
    this.$doneButton = this.$('.done-button');
    this.$doneButton.htmlOrText(localizeStr(Strings.DONE));
    this.$doneButton.onButtonTapOrHold('vedvDone', () => {
      if (this.$doneButton.hasClass('disabled')) {
        return;
      }
      // Submit on done button press.
      this._submitCurbsideDetails();
      this.blur();
    });

    this._renderBackButton();
    this.$vehicleGuestNameField.focus();
    this._enableDoneButton(false);

    return this;
  },
});
