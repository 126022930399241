import { IonButton, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { Component } from 'react';
import _ from 'underscore';

import { Log } from '@biteinc/common';
import { TimeHelper } from '@biteinc/core-react';
import { CardSchemeId, IntegrationSystem } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { localizeStr, str } from '~/app/js/localization/localization';
import LocalStorage from '~/app/js/utils/local_storage';
import { GCNAlertView } from '~/app/js/views/gcn_alert_view';
import { GcnCustomerAccountHelper, LocationUtils } from '~/helpers';
import GcnGiftCardHelper from '~/helpers/gcn_gift_card_helper';
import { AuthService } from '~/services';

import GcnHtml from '../app/js/gcn_html';
import type { GcnTransaction, KioskLocation } from '../types';
import type { StoredValueCardWithBalance } from '../types/stored_value_card';
import type { FreedomPayEcommUtils } from './ecomm-payment/freedom-pay/freedom-pay-ecomm-form.utils';

let partialTenderTimeout: NodeJS.Timeout | null = null;
interface StoredValueProps {
  transactions: GcnTransaction[];
  submitOrder: () => void;
  location: KioskLocation;
  savedCards?: FreedomPayEcommUtils.SavedCard[];
}
export default class StoredValuePanel extends Component<StoredValueProps> {
  componentWillUnmount(): void {
    this.cancelPartialTenderTimeout();
  }

  private requiresGiftCardPaymentToken(): boolean {
    // Atrium on Flash only works if a payment token is passed in.
    return gcn.location.get('giftCardI9n') === IntegrationSystem.Atrium;
  }

  private startStoredValuePayment(): void {
    if (gcn.location.get('giftCardI9n') === IntegrationSystem.Cbord) {
      const authUrl = gcn.location.getStoredValueIntegration()?.externalSsoAuthUrl;
      if (authUrl) {
        // Use the current URL as the redirect URL
        const url = new URL(window.location.href);
        url.search = ''; // Remove query parameters
        url.hash = ''; // Remove the checkout fragment
        const currentUrl = url.toString();
        // mobileapp=3 is required to indicate that after completing SSO authentication we redirect to a url
        // the location of the redirect is indicated with redirecturl
        const loginUrl = `${authUrl}?mobileapp=3&redirecturl=${currentUrl}`;

        gcn.menuView.showSpinner(localizeStr(Strings.LOADING));
        // Redirect to the SSO login page to get the payment token
        window.location.href = loginUrl;
      } else {
        AuthService.showLogin(gcn.location.attributes, () => {}, true);
      }
    } else {
      const callback = (): void => {
        Log.debug('gift card used');
        // If fully paid and the gift card method allows completion of payment, proceed to next
        // step.
        if (gcn.orderManager.getGrandTotal() === 0) {
          this.props.submitOrder();
        }
        // On first tender, we start a timeout. If they do not finish payment in 2 minutes we warn
        // them. If they don't finish 5 minutes after the warning we refund them.
        this.startPartialTenderTimeout();
      };

      if (this.requiresGiftCardPaymentToken()) {
        // Payment by token requires an email because it's generally shared with eComm.
        GcnGiftCardHelper.ensureGuestEmail(() => {
          gcn.menuView.dismissModalPopup();
          // If we previously took payment and then failed to send the order, then we're
          // already settled up
          if (gcn.orderManager.getGrandTotal() === 0) {
            callback();
            return;
          }
          GcnGiftCardHelper.showGiftCardView('stored-value', callback);
        });
      } else {
        GcnGiftCardHelper.showGiftCardView('stored-value', callback);
      }
    }
  }

  private getStoredValueButton(): React.ReactNode {
    if (!LocationUtils.hasStoredValueIntegration(this.props.location)) {
      return;
    }
    if (gcn.orderManager.getGrandTotal() === 0) {
      return;
    }

    const sessionStoredValueCards = gcn.orderManager.getStoredValueCards().filter((card) => {
      return !!card.authSessionToken;
    });

    if (sessionStoredValueCards.length && !gcn.orderManager.getUnusedStoredValueCards().length) {
      // hide button if stored value session token cards all used
      return;
    }
    // Atrium on Flash only works if a payment token is passed in.
    if (this.requiresGiftCardPaymentToken() && !sessionStoredValueCards.length) {
      return;
    }

    if (gcn.location.get('giftCardI9n') === IntegrationSystem.Cbord) {
      const hasCustomer =
        GcnCustomerAccountHelper.customerAccountsAreEnabled() &&
        Boolean(gcn.orderManager.getCustomer());
      if (hasCustomer) {
        const cbordSavedCard = this.props.savedCards?.find(
          (savedCard) => savedCard.cardSchemeId === CardSchemeId.Cbord,
        );
        if (cbordSavedCard) {
          // If a cbord card is saved, we can use it to pay no need to show authentication button
          return;
        }
      } else if (LocalStorage.getItem('paymentToken')) {
        // If a payment token exists, it means a user that is not logged in has completed the cbord auth flow
        return;
      }
    }

    return (
      <IonItem>
        <IonButton
          className="use-stored-value-button"
          expand="block"
          fill="outline"
          size="default"
          onClick={() => {
            this.startStoredValuePayment();
          }}
        >
          {localizeStr(Strings.USE_GIFT_CARD)}
        </IonButton>
      </IonItem>
    );
  }

  private static refundTransaction(transaction: GcnTransaction): void {
    gcn.menuView.showSpinner(localizeStr(Strings.REFUNDING));
    gcn.orderManager.refundStoredValueTransaction(transaction, () => {
      gcn.menuView.dismissSpinner();
    });
  }

  // If another payment is made, this will be cancelled
  private cancelPartialTenderTimeout = (): void => {
    // Check if existing timer. Starting a new one should clear an old one.
    if (partialTenderTimeout) {
      clearTimeout(partialTenderTimeout);
      partialTenderTimeout = null;
    }
  };

  private startPartialTenderTimeout = (): void => {
    this.cancelPartialTenderTimeout();
    if (gcn.orderManager.getTransactions().length === 0) {
      return;
    }
    // Pop-up will show up after 2 minutes of no additional transactions.
    partialTenderTimeout = setTimeout(() => {
      const alertText = localizeStr(Strings.PARTIAL_TENDER_TIMEOUT, [], function (string: string) {
        return string.split('\n').join('<br />');
      });
      const warningView = new GCNAlertView({
        text: alertText,
        okCallback() {
          gcn.menuView.dismissModalPopup();
        },
      });
      gcn.menuView.showModalPopup(warningView);
    }, 2 * TimeHelper.MINUTE);
  };

  private static getRemainingBalance(card: StoredValueCardWithBalance): React.ReactNode {
    if (_.isUndefined(card.remainingBalance)) {
      return undefined;
    }
    return (
      <p className="card-balance">
        {`${GcnHtml.stringFromPrice(card.remainingBalance)}`}
        {localizeStr(Strings.STORED_VALUE_REMAINING_BALANCE)}
      </p>
    );
  }

  private getTransactions(): React.ReactNode[] {
    const cardsByLastFour = gcn.orderManager
      .getStoredValueCardsAndRemainingBalance()
      .reduce(
        (
          storedValueCardsByLastFour: Record<string, StoredValueCardWithBalance>,
          card: StoredValueCardWithBalance,
        ) => {
          // only 1 card per last four
          // edge case here where we show the wrong card because last 4 is not unique
          storedValueCardsByLastFour[card.lastFour] = card;
          return storedValueCardsByLastFour;
        },
        {},
      );

    const nodes: React.ReactNode[] = [];
    if (this.props.transactions.length) {
      nodes.push(
        <IonItem className="stored-value-title">
          <IonLabel>
            <h3 className="title">{localizeStr(Strings.APPLIED_STORED_VALUE_CARDS)}</h3>
          </IonLabel>
        </IonItem>,
      );
      this.props.transactions.forEach((transaction) => {
        const card = cardsByLastFour[transaction.getLastFour()];
        const cardName = card?.cardName || str(Strings.GIFT_CARD);
        const cardLastFour = card?.lastFour ? ` (*${card.lastFour})` : '';
        nodes.push(
          <IonItem className="stored-value-transaction">
            <IonLabel slot="start">
              <h3 className="card-name">{`${cardName} ${cardLastFour}`}</h3>
              {StoredValuePanel.getRemainingBalance(card)}
            </IonLabel>
            <IonButton
              slot="end"
              fill="clear"
              className="refund-stored-value-transaction"
              onClick={() => {
                StoredValuePanel.refundTransaction(transaction);
                this.startPartialTenderTimeout();
              }}
            >
              {localizeStr(Strings.REMOVE_GIFT_CARD)}
            </IonButton>
          </IonItem>,
        );
      });
    }
    return nodes;
  }

  render(): React.ReactNode {
    return (
      <IonList
        lines="none"
        className={`stored-value ${this.props.transactions.length ? 'has-transactions' : ''}`}
      >
        {this.getTransactions()}
        {this.getStoredValueButton()}
      </IonList>
    );
  }
}
