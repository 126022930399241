import $ from 'jquery';
import _ from 'underscore';

import { TimeHelper } from '@biteinc/core-react';
import { FulfillmentMethodHelper } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

// UI to allow the guest to choose when they want their order.
export const GcnFutureOrderPickerView = GCNView.extend({
  className: 'future-order-picker-view list-picker-view',

  _chooseNowOrFutureTemplate: _.template(`
    <div class="choose-now-or-future">
      <div class="back-button"></div>
      <div class="header" role="heading" aria-level="1" tabindex="-1"></div>
      <div class="list-item-button asap"></div>
      <div class="list-item-button later-today"></div>
      <div class="list-item-button next-business-day"></div>
    </div>
  `),

  _chooseOrderTimeTemplate: _.template(`
    <div class="choose-order-time">
      <div class="back-button"></div>
      <div class="header" role="heading" aria-level="1" tabindex="-1"></div>
      <div class="missing-timeslots-warning"></div>
      <div class="times-list"></div>
    </div>
  `),

  initialize(futureOrderSlots, ignoreAsapOption, fulfillmentMethod, ...args) {
    GCNView.prototype.initialize.apply(this, [
      futureOrderSlots,
      ignoreAsapOption,
      fulfillmentMethod,
      ...args,
    ]);

    this.futureOrderSlots = futureOrderSlots;
    this.ignoreAsapOption = ignoreAsapOption;
    this.fulfillmentMethod = fulfillmentMethod;
    this._hasMissingTimeSlots = {};

    this.addRefreshOnLanguageChangeSubscription();
  },

  _renderChooseNowOrFutureStep() {
    this.$el.html(this._chooseNowOrFutureTemplate());

    this.$('.header').htmlOrText(localizeStr(Strings.WHEN_WOULD_YOU_LIKE_YOUR_ORDER));
    if (gcn.screenReaderIsActive) {
      this.$('.header').requestFocusAfterDelay();
    }

    const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod();
    const diningOption = gcn.location.getDiningOption(fulfillmentMethod);

    if (
      gcn.location.getActiveDiningOptions().length > 1 ||
      diningOption.forceGuestToSelectIfSingleDiningOption ||
      FulfillmentMethodHelper.isAnOutpost(fulfillmentMethod)
    ) {
      this.$('.back-button').show();
      this.$('.back-button').htmlOrText(`← ${localizeStr(Strings.BACK)}`);
      this.$('.back-button').onButtonTapOrHold('fopvBack', () => {
        Analytics.track(Analytics.EventName.FutureOrderTimeBackOut);
        this.trigger(BackboneEvents.GcnFutureOrderPickerView.BackedOut, this);
      });
    } else {
      this.$('.back-button').hide();
    }

    const asapOrdersEnabled =
      !this.ignoreAsapOption &&
      !gcn.location.isClosedFor(this.fulfillmentMethod) &&
      !!diningOption.asapOrdersEnabled;
    const { today: todaySlots, nextDay: nextDaySlots } = this.futureOrderSlots;

    const $asapButton = this.$('.asap');
    if (asapOrdersEnabled) {
      $asapButton.htmlOrText(localizeStr(Strings.ORDER_TIME_ASAP));
      $asapButton.onButtonTapOrHold('fopvAsap', () => {
        Analytics.track(Analytics.EventName.FutureOrderTimeSelectedAsap);
        this.trigger(BackboneEvents.GcnFutureOrderPickerView.DidPickAsapOrder, this);
      });
    } else {
      $asapButton.remove();
    }

    const $laterTodayButton = this.$('.later-today');
    // Hide if it is too late for same-day future orders.
    if (todaySlots) {
      $laterTodayButton.htmlOrText(localizeStr(Strings.ORDER_TIME_LATER));
      $laterTodayButton.onButtonTapOrHold('fopvLater', () => {
        Analytics.track(Analytics.EventName.FutureOrderTimeSelectedToday);
        this._renderChooseOrderTimeStep(todaySlots, 'today');
      });
    } else {
      $laterTodayButton.remove();
    }

    const $nextBusinessDayButton = this.$('.next-business-day');
    if (nextDaySlots?.length) {
      const dayName = TimeHelper.formatIsoString(nextDaySlots[0].timestamp, 'dddd');
      $nextBusinessDayButton.htmlOrText(
        `${localizeStr(Strings.ORDER_TIME_NEXT_BUSINESS_DAY)} (${dayName})`,
      );
      $nextBusinessDayButton.onButtonTapOrHold('fopvNext', () => {
        Analytics.track(Analytics.EventName.FutureOrderTimeSelectedNextBusinessDay);
        this._renderChooseOrderTimeStep(nextDaySlots, 'nextDay');
      });
    } else {
      $nextBusinessDayButton.remove();
    }
  },

  _renderChooseOrderTimeStep(slots, slotDay) {
    this.$el.html(this._chooseOrderTimeTemplate());

    this.$('.header').htmlOrText(localizeStr(Strings.CHOOSE_TIME_SLOT));
    this.$('.back-button').htmlOrText(`← ${localizeStr(Strings.BACK)}`);

    const $timesList = this.$('.times-list');
    _.each(slots, (slot) => {
      const { timestamp } = slot;
      const formattedTimeSlot = TimeHelper.formatIsoString(
        timestamp,
        'LT',
        gcn.location.get('timezone'),
      );
      const $timeSlotButton = $(`<div class="button">${formattedTimeSlot}</div>`);
      $timesList.append($timeSlotButton);
      if (slot.available) {
        $timeSlotButton.onButtonTapOrHold('fopvSlot', () => {
          Analytics.trackEvent({
            eventName: Analytics.EventName.FutureOrderTimeSelectedSlot,
            eventData: {
              value: `${formattedTimeSlot}`,
            },
          });
          this.trigger(BackboneEvents.GcnFutureOrderPickerView.DidPickFutureOrder, timestamp);
        });
      } else {
        this._hasMissingTimeSlots[slotDay] = true;
        $timeSlotButton.toggleClass('disabled', true);
      }
    });

    this.$('.back-button').onButtonTapOrHold('fopvBack', () => {
      Analytics.track(Analytics.EventName.FutureOrderTimeBackOut);
      this._renderChooseNowOrFutureStep();
    });
    if (this._hasMissingTimeSlots[slotDay]) {
      const $timeWarning = this.$('.missing-timeslots-warning');
      $timeWarning.html(
        '<span>Some time slots are unavailable as the restaurant is experiencing higher demand than it can handle.</span>',
      );
    }
  },

  render() {
    this._renderChooseNowOrFutureStep();
    return this;
  },
});
