import $ from 'jquery';
import _ from 'underscore';

import { Validators } from '@biteinc/common';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';
import { GCNForcePlainKeyboard } from '~/helpers/gcn_force_plain_keyboard';

import Analytics from '../utils/analytics';
import { GCNAlertView } from './gcn_alert_view';
import { GCNMarketingConsentView } from './gcn_marketing_consent_view';
import { GCNView } from './gcn_view';

export const GCNEmailReceiptView = GCNView.extend({
  className: 'email-receipt-view',
  template: _.template(
    // prettier-ignore
    '<div class="top">' +
      '<div class="icon"></div>' +
      '<div id="title-wrapper"></div>' +
    '</div>' +
    '<div class="bottom">' +
      '<div class="form-group"></div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._callback = options.callback;
    this._setEmailOnly = options.setEmailOnly;
    this._showRequiredMessage = options.showRequiredMessage;
    this._guestEmail = options.email;
    if (!this._setEmailOnly) {
      this.listenTo(
        gcn.orderManager,
        BackboneEvents.GCNOrderManager.EmailReceiptDidSend,
        this._emailSent,
      );
      this.listenTo(
        gcn.orderManager,
        BackboneEvents.GCNOrderManager.EmailReceiptFailedSend,
        this._emailFailed,
      );
    }
    this.addRefreshOnLanguageChangeSubscription();
  },

  overlayWasClicked() {
    this._callback();
  },

  _displayError(errorText) {
    this._$sendButton.removeClass('disabled');
    this.$('.title').htmlOrText(errorText).addClass('error');
  },

  _emailSent() {
    gcn.menuView.dismissSpinner();
    this._callback();
  },

  _emailFailed() {
    gcn.menuView.dismissSpinner();
    this._displayError(localizeStr(Strings.EMAIL_RECEIPT_SEND_FAILED));
  },

  _submitEmail() {
    const email = this._$emailInput.val();
    this._$sendButton.addClass('disabled');
    if (this._setEmailOnly) {
      gcn.orderManager.setGuestEmail(email, true);
      this._callback();
    } else {
      gcn.orderManager.sendOrderEmailReceipt(email, this.marketingConsentView?.getValue());
      gcn.menuView.showSpinner(localizeStr(Strings.SENDING_RECEIPT));
    }
  },

  render() {
    this.$el.html(this.template());

    // email form
    const $emailInputLabel = $(
      `<label for="email-input">${localizeStr(Strings.ENTER_YOUR_EMAIL)}</label>`,
    );
    this._$emailInput = $(
      '<input class="form-control" id="email-input" autocomplete="off" autocorrect="off" type="email" />',
    );
    new GCNForcePlainKeyboard(this._$emailInput).enableForcePlainKeyboardIfNeeded();
    this.$('.form-group').append($emailInputLabel, this._$emailInput);

    if (!this._setEmailOnly && gcn.menu.settings.get('marketingConsentCheckbox')) {
      this.marketingConsentView = new GCNMarketingConsentView();
      this.$('.form-group').append(this.marketingConsentView.render().$el);
    }

    const buttonText = localizeStr(this._setEmailOnly ? Strings.SUBMIT : Strings.SEND);
    this._$sendButton = $(
      `<div role="button" class="button ok" id="search-btn">${buttonText}</div>`,
    ).addClass('disabled');
    const $disclaimer = $(
      `<div class="email-footnote disclaimer-clickable">${`${localizeStr(
        Strings.EMAIL_REQUIRED_FOR_RECEIPT,
      )}\n`}${localizeStr(Strings.DISCLAIMER_EMAIL)}</div>`,
    );

    $disclaimer.onButtonTapOrHold('tosOnPhoneDisclaimer', async () => {
      gcn.maitred.getTermsOfService(gcn.location.get('orgId'), (err, data) => {
        const formattedData = err
          ? localizeStr(Strings.TERMS_OF_SERVICE_LOAD_FAILED)
          : data?.tos
              .split('\n')
              .filter((content) => content.length)
              .map((content) => {
                return `<p>${content}</p>`;
              });
        const termsOfServiceView = new GCNAlertView({
          text: formattedData,
          okCallback() {
            gcn.menuView.dismissTopDismissibleModalPopup();
          },
        });

        termsOfServiceView.$el.addClass('animate terms-of-service');
        gcn.menuView.showTopDismissibleModalPopup(termsOfServiceView);
      });
    });

    this.$('.form-group').append(this._$sendButton, $disclaimer);
    this.$('#title-wrapper').append(
      `<div class="title">${localizeStr(Strings.EMAIL_RECEIPT)}</div>`,
    );

    this._$sendButton.onButtonTapOrHold('ervSend', () => {
      if (this._$sendButton.hasClass('disabled')) {
        return;
      }
      Analytics.track(Analytics.EventName.EmailReceiptRequested);
      this._submitEmail();
    });

    if (this._guestEmail && Validators.isEmail(this._guestEmail)) {
      this._$emailInput.val(this._guestEmail);
      this._$sendButton.toggleClass('disabled', false);
    }

    // Convenient for testing.
    // this._$emailInput.val('steve@getbite.com');

    this._$emailInput.keyup((e) => {
      const input = $(e.target).val();
      this._$sendButton.toggleClass('disabled', !Validators.isEmail(input));
    });

    this.$('.icon').delayedScaleIn();

    return this;
  },
});
