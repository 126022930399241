import $ from 'jquery';
import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

// UI to allow the guest to choose where and when they want their order.
export const GCNOutpostPickerView = GCNView.extend({
  className: 'future-order-picker-view list-picker-view',

  _chooseOutpostTemplate: _.template(`
    <div class="choose-outpost">
      <div class="back-button"></div>
      <div class="header" role="heading" aria-level="1" tabindex="-1"></div>
    </div>
  `),

  _chooseOrderTimeTemplate: _.template(`
    <div class="choose-order-time">
      <div class="back-button"></div>
      <div class="header" role="heading" aria-level="1" tabindex="-1"></div>
      <div class="times-list"></div>
    </div>
  `),

  initialize(...args) {
    GCNView.prototype.initialize.apply(this, args);

    this.addRefreshOnLanguageChangeSubscription();
  },

  _renderChooseOutpostStep() {
    this.$el.html(this._chooseOutpostTemplate());

    this.$('.header').htmlOrText(localizeStr(Strings.WHICH_OUTPOST_FOR_YOUR_ORDER));
    if (gcn.screenReaderIsActive) {
      this.$('.header').requestFocusAfterDelay();
    }

    const diningOption = gcn.location.getDiningOption(gcn.orderManager.getFulfillmentMethod());

    if (
      gcn.location.getActiveDiningOptions().length > 1 ||
      diningOption.forceGuestToSelectIfSingleDiningOption
    ) {
      this.$('.back-button').show();
      this.$('.back-button').htmlOrText(`${localizeStr(Strings.CANCEL)}`);
      this.$('.back-button').onButtonTapOrHold('opvBack', () => {
        Analytics.track(Analytics.EventName.FulfillmentMethodSelectionRevised);
        this.trigger(BackboneEvents.GCNOutpostPickerView.BackedOut);
      });
    } else {
      this.$('.back-button').hide();
    }

    const $outpostsList = this.$('.choose-outpost');

    const outposts = diningOption.outposts;
    _.each(outposts, (outpost) => {
      const $outpostButton = $(`<div class="list-item-button">${outpost.name}</div>`);
      $outpostButton.onButtonTapOrHold('opvOutpost', () => {
        Analytics.trackEvent({
          eventName: Analytics.EventName.OutpostSelected,
          eventData: {
            outpostName: outpost.name,
          },
        });
        this.trigger(BackboneEvents.GCNOutpostPickerView.DidPickOutpost, outpost);
      });
      $outpostsList.append($outpostButton);
    });
  },

  render() {
    this._renderChooseOutpostStep();
    return this;
  },
});
