import _ from 'underscore';

import { Log } from '@biteinc/common';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import GcnRecoTracker from '../gcn_reco_tracker';
import Analytics from '../utils/analytics';
import { GCNRecommendationsView } from './gcn_recommendations_view';
import { GCNView } from './gcn_view';

// UI for showing upsell items between. Generally used as a GcnPopup.
export const GCNUpsellInterstitialView = GCNView.extend({
  className: 'upsell-interstitial-view',
  template: _.template(
    // prettier-ignore
    '<div class="body"></div>' +
    '<div class="bottom-bar">' +
      '<div role="button" class="button"></div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._options = options;
    if (!this._options.recos) {
      Log.error('Creating GCNUpsellInterstitialView with no items.');
      return;
    }

    const upsellScreen = 'GCNUpsellInterstitialView';
    this._recosView = new GCNRecommendationsView({
      upsellScreen,
      recos: this._options.recos,
      headerText: localizeStr(Strings.COULD_WE_INTEREST),
      usePopup: true,
    });
    this.listenTo(this._recosView, BackboneEvents.GCNRecommendationsView.ProceedToNextStep, () => {
      // If item is added from recommendations, we should close the upsell view and process to next step
      this.trigger(BackboneEvents.GCNUpsellInterstitialView.DonePressed, this);
    });

    GcnRecoTracker.trackDisplayRecommendations(
      gcn.maitred,
      this._options.recommendationDisplayLocationDescription,
      this._options.recos.map((item) => {
        return item.id;
      }),
    );

    this.listenTo(
      this._recosView,
      BackboneEvents.GCNRecommendationsView.SelectedItemsChanged,
      () => {
        this._updateButtonState();
      },
    );

    this.addRefreshOnLanguageChangeSubscription();
  },

  overlayWasClicked() {
    // Do nothing.
  },

  shouldHideCloseLabel() {
    return true;
  },

  getOrderedRecommendations() {
    return {
      orderedItems: this._recosView.getOrderedItems(),
      recommendationDisplayLocationDescription:
        this._options.recommendationDisplayLocationDescription,
    };
  },

  _updateButtonState() {
    const hasItems = this._recosView.getOrderedItems().length !== 0;
    this.$button.htmlOrText(localizeStr(hasItems ? Strings.CONTINUE : Strings.NO_THANKS));
    this.$button.toggleClass('active', hasItems);
  },

  render() {
    const self = this;

    this.$el.html(this.template());

    this.$('.body').append(this._recosView.render().$el);
    this.$button = this.$('.button');
    // BITE-5732 The reason we're not using onButtonTapOrHold is that it may accidentally
    // trigger the payment method casher option
    if ('ontouchstart' in window) {
      this.$button.on('touchend', (e) => {
        e.preventDefault();
        Analytics.track(Analytics.EventName.UpsellInterstitialRecoDismissed);
        self.trigger(BackboneEvents.GCNUpsellInterstitialView.DonePressed, self);
      });
    } else {
      this.$button.on('click', () => {
        Analytics.track(Analytics.EventName.UpsellInterstitialRecoDismissed);
        self.trigger(BackboneEvents.GCNUpsellInterstitialView.DonePressed, self);
      });
    }

    this._updateButtonState();

    return this;
  },
});
