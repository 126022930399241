import { IonButton, IonItem, IonLabel } from '@ionic/react';
import React, { Component } from 'react';

import { Strings } from '@biteinc/localization';

import { str } from '~/app/js/localization/localization';

import type { GcnReward } from '../types/gcn_reward';

interface RewardProps {
  reward: GcnReward;
  hasBeenApplied: boolean;
  isDisabled: boolean;
}

class RewardView extends Component<RewardProps> {
  render(): React.ReactNode {
    const buttonText = this.props.hasBeenApplied
      ? `✓ ${str(Strings.LOYALTY_REWARD_APPLIED)}`
      : str(Strings.LOYALTY_REWARD_APPLY);
    return (
      <IonItem class="reward">
        <IonLabel
          className="reward-name"
          dangerouslySetInnerHTML={{ __html: this.props.reward.displayName() }}
        />
        <IonButton
          slot="end"
          fill="clear"
          className="apply-reward-button"
          disabled={this.props.isDisabled}
          onClick={() => {
            if (this.props.hasBeenApplied) {
              gcn.loyaltyManager.removeReward(this.props.reward);
            } else {
              gcn.loyaltyManager.applyReward(this.props.reward, true);
            }
          }}
        >
          {buttonText}
        </IonButton>
      </IonItem>
    );
  }
}

export default RewardView;
