import {
  IonButton,
  IonButtons,
  IonCol,
  IonDatetime,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { Component } from 'react';
import _ from 'underscore';

import type { FutureOrderTimeSlots } from '@biteinc/core-react';
import { TimeHelper } from '@biteinc/core-react';
import type { FulfillmentMethod } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { str } from '~/app/js/localization/localization';
import { LocationUtils } from '~/helpers';
import type { Store } from '~/stores';
import { withStore } from '~/stores';

interface FutureDateTimePickerProps {
  futureOrderSlots: FutureOrderTimeSlots;
  fulfillmentMethod: FulfillmentMethod;
  goBack: () => void;
  orderAsap: () => void;
  confirm: (timestamp: string) => void;
  ignoreAsapOption?: boolean;
  location: Store['bridge']['location'];
}

interface FutureDateTimePickerState {
  showAsap: boolean;
  maxDate: string;
  minDate: string;
  dayValues: number[];
  selectedDate?: string;
  selectedTime?: string;
}

class FutureDateTimePicker extends Component<FutureDateTimePickerProps, FutureDateTimePickerState> {
  constructor(props: FutureDateTimePickerProps) {
    super(props);
    this.state = {
      ...this.getDatesFromSlotsByDay(),
      showAsap: this.showAsap(),
    };
  }

  private showAsap(): boolean {
    const diningOption = LocationUtils.getDiningOptionForFulfillmentMethodWithoutLocalizedName(
      this.props.location,
      this.props.fulfillmentMethod,
    );
    return (
      !this.props.ignoreAsapOption &&
      !LocationUtils.isClosedForFulfillmentMethod(
        this.props.location,
        this.props.fulfillmentMethod,
      ) &&
      !!diningOption.asapOrdersEnabled
    );
  }

  private static getMaxDate(dates: string[]): string {
    let maxDateString = dates[0];
    dates.forEach((date: string) => {
      if (!maxDateString) {
        maxDateString = date;
        return;
      }
      if (new Date(date) > new Date(maxDateString)) {
        maxDateString = date;
      }
    });
    return maxDateString;
  }

  private static getMinDate(dates: string[]): string {
    let minDateString = dates[0];
    dates.forEach((date: string) => {
      if (!minDateString) {
        minDateString = date;
        return;
      }
      if (new Date(date) < new Date(minDateString)) {
        minDateString = date;
      }
    });
    return minDateString;
  }

  private static getDayValues(dates: string[]): number[] {
    return dates.sort().map((date: string) => {
      const [, , day] = date.split('-');
      return parseInt(day, 10);
    });
  }

  private getDatesFromSlotsByDay(): Omit<FutureDateTimePickerState, 'showAsap'> {
    const dayKeys = _.keys(this.props.futureOrderSlots);
    const validDates: string[] = [];
    dayKeys.forEach((key: string) => {
      if (key === 'today') {
        const todayAsTimeString = TimeHelper.getTodaysDateStringWithFormat(
          TimeHelper.DATE_STRING_FORMAT,
        );
        // We want today's date as a key so we can get it when the date is picked.
        this.props.futureOrderSlots[todayAsTimeString] = this.props.futureOrderSlots.today;
        validDates.push(todayAsTimeString);
      }
      if (!['today', 'nextDay'].includes(key)) {
        validDates.push(key);
      }
    });
    const state: Omit<FutureDateTimePickerState, 'showAsap'> = {
      maxDate: FutureDateTimePicker.getMaxDate(validDates),
      minDate: FutureDateTimePicker.getMinDate(validDates),
      dayValues: FutureDateTimePicker.getDayValues(validDates),
    };
    // Set the first available day as default
    state.selectedDate = state.minDate;
    return state;
  }

  private getCalendarView(): React.ReactNode {
    return (
      <IonCol className="calendar-container">
        <IonDatetime
          className="calendar"
          presentation="date"
          max={this.state.maxDate}
          min={this.state.minDate}
          value={this.state.selectedDate}
          dayValues={this.state.dayValues}
          /**
           *  The "day of the sun" was observed in honor of the Sun-god, Ra, the chief of all
           * astral bodies, making Sunday the first of all days.
           * https://www.calendarr.com/united-states/first-day-of-the-week
           */
          firstDayOfWeek={0}
          onIonChange={(ev) => {
            this.setState({
              selectedDate: TimeHelper.format(
                ev.detail.value as string,
                TimeHelper.DATE_STRING_FORMAT,
              ),
              selectedTime: undefined,
            });
          }}
        />
      </IonCol>
    );
  }

  private getCalendarViewOrButtonView(): React.ReactNode {
    if (this.state.showAsap) {
      return (
        <IonCol className="options-buttons-container">
          <IonButton
            className="asap"
            color="primary"
            fill="solid"
            expand="block"
            onClick={() => {
              this.props.orderAsap();
            }}
          >
            {str(Strings.ORDER_TIME_ASAP)}
          </IonButton>
          <IonButton
            className="future"
            color="primary"
            fill="solid"
            expand="block"
            onClick={() => {
              this.setState({
                showAsap: false,
              });
            }}
          >
            {str(Strings.ORDER_TIME_FUTURE)}
          </IonButton>
        </IonCol>
      );
    }
    return this.getCalendarView();
  }

  private getAvailableTimeItems(): React.ReactNode {
    if (!this.state.selectedDate) {
      return (
        <>
          <IonItem
            lines="none"
            className="time-item"
          >
            <IonLabel className="placeholder">{str(Strings.SELECT_DATE)}</IonLabel>
          </IonItem>
        </>
      );
    }
    const slots = this.props.futureOrderSlots[this.state.selectedDate];
    const timeItems: React.ReactNode[] = [];
    slots.forEach(({ timestamp, available }) => {
      const formattedTimeSlot = TimeHelper.formatIsoString(
        timestamp,
        'LT',
        this.props.location.timezone,
      );
      timeItems.push(
        <IonItem
          key={timestamp}
          lines="none"
          color={timestamp === this.state.selectedTime ? 'primary' : ''}
          className="time-item"
          button={available}
          disabled={!available}
          detail={false}
          {...(available && {
            onClick: () => {
              this.setState({
                selectedTime: timestamp,
              });
              this.props.confirm(timestamp);
            },
          })}
        >
          <IonLabel className="time">{formattedTimeSlot}</IonLabel>
        </IonItem>,
      );
    });
    return timeItems;
  }

  render(): React.ReactNode {
    return (
      <div className="future-date-time-picker-view">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                color="primary"
                onClick={() => {
                  // If we are on the asap view or we don't have asap ordering go back
                  if (this.state.showAsap || !this.showAsap()) {
                    this.props.goBack();
                    return;
                  }
                  // If we are on the calendar view go back to asap
                  this.setState({
                    showAsap: this.showAsap(),
                  });
                }}
              >
                {str(Strings.BACK)}
              </IonButton>
            </IonButtons>
            <IonTitle className="title">{str(Strings.WHEN_WOULD_YOU_LIKE_YOUR_ORDER)}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRow className="date-time-picker-container">
          {this.getCalendarViewOrButtonView()}
          {!this.state.showAsap && (
            <IonCol className="time-picker-container">
              <IonList className="date-picker-list">{this.getAvailableTimeItems()}</IonList>
            </IonCol>
          )}
        </IonRow>
      </div>
    );
  }
}

export default withStore((state) => ({
  location: state.bridge.location,
}))(FutureDateTimePicker);
