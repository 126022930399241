import { IonButton, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { Component } from 'react';

import type { Schema } from '@biteinc/core-react';
import { StringHelper, ValidationHelper } from '@biteinc/core-react';
import { CustomerIdentifier } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { localizeStr, str } from '~/app/js/localization/localization';

import type GcnLocation from '../app/js/models/gcn_location';
import { GcnCustomerAccountHelper, GcnCustomerIdentifierHelper } from '../helpers';
import { CustomerIdentifierService } from '../services';
import type {
  CustomerIdentifierData,
  CustomerIdentifierState,
  KioskLocation,
  KioskMenuSettings,
} from '../types';

interface CustomerIdentifierProps {
  location: KioskLocation;
  settings: KioskMenuSettings;
  guestInfo: CustomerIdentifierState;
  customerIdentifierOptions: GcnLocation.CustomerIdentifierOption[];
  orderIsClosed: boolean;
  createOrder: () => void;
  validateOrder: () => void;
}

export class CustomerIdentifiers extends Component<CustomerIdentifierProps, {}> {
  private readonly schema: Schema;

  constructor(props: CustomerIdentifierProps) {
    super(props);

    this.schema =
      GcnCustomerIdentifierHelper.getCustomerIdentifierSchemasMergedIntoSingleSchemaWithEmail(
        this.props.customerIdentifierOptions,
        {
          requireEmail: !gcn.menu.settings.get('allowZeroDollarOrders'),
        },
      );
  }

  private static createListItem(value?: string, className?: string): React.ReactNode {
    if (!value) {
      return undefined;
    }
    return (
      <IonItem
        lines="none"
        className={`customer-identifiers-item ${className}`}
      >
        <IonLabel className="label">
          <div className="label-value">{value}</div>
        </IonLabel>
      </IonItem>
    );
  }

  private getVehicleDescription(): React.ReactNode[] {
    if (!this.props.guestInfo?.vehicleDescription) {
      return [];
    }
    const vehicleParts = [
      this.props.guestInfo.vehicleDescription.make,
      this.props.guestInfo.vehicleDescription.model,
      this.props.guestInfo.vehicleDescription.color,
    ];
    return [CustomerIdentifiers.createListItem(vehicleParts.join(', '), 'vehicle-details')];
  }

  private getCustomerIdentifierList(): React.ReactNode[] {
    const listItems: React.ReactNode[] = [
      CustomerIdentifiers.createListItem(this.props.guestInfo.email, 'guest-email'),
    ];
    this.props.customerIdentifierOptions.forEach((identifier) => {
      switch (identifier.customerIdentifier) {
        case CustomerIdentifier.GuestName: {
          // Add to the top of the list
          listItems.unshift(
            CustomerIdentifiers.createListItem(this.props.guestInfo.name, 'guest-name'),
          );
          break;
        }
        case CustomerIdentifier.PhoneNumber: {
          if (this.props.guestInfo.phone) {
            listItems.push(
              CustomerIdentifiers.createListItem(
                StringHelper.toFormattedPhoneNumber(this.props.guestInfo.phone),
                'guest-phone',
              ),
            );
          }
          break;
        }
        case CustomerIdentifier.VehicleDescription: {
          const lines = this.getVehicleDescription();
          listItems.push(...lines);
          break;
        }
      }
    });
    return listItems.map((node, index) =>
      React.isValidElement(node)
        ? React.cloneElement(node as React.ReactElement, { key: index })
        : node,
    );
  }

  /**
   * @param data Customer identifier data
   * @param init is this the first time we are showing this view, so we do not have a validated order
   */
  private setCustomerIdentifiers(data: CustomerIdentifierData, init?: boolean): void {
    CustomerIdentifierService.setCustomerIdentifierState(data, init);
    if (init) {
      this.props.createOrder();
    } else {
      gcn.menuView.showSpinner(localizeStr(Strings.SENDING_ORDER_VALIDATION));
      this.props.validateOrder();
    }
  }

  /**
   * @param init is this the first time showing this
   * @param prefilledGuestData this will only be passed if we no not have customer accounts
   */
  private editCustomerIdentifiers(
    init?: boolean,
    prefilledGuestData?: CustomerIdentifierData | null,
  ): void {
    CustomerIdentifierService.collectCustomerIdentifiers(this.props.settings, {
      init,
      guestData: prefilledGuestData || this.props.guestInfo,
      customerIdentifierOptions: this.props.customerIdentifierOptions,
      onSubmit: async (data: CustomerIdentifierData) => {
        this.setCustomerIdentifiers(data, init);
      },
    });
  }

  private getGuestInfo(): React.ReactNode {
    return (
      <IonList className="customer-identifiers-list">
        <IonItem
          lines="none"
          className="customer-identifiers-header"
        >
          <IonLabel className="title">
            <h3>{str(Strings.GUEST_INFO)}</h3>
          </IonLabel>
          {!this.props.orderIsClosed && (
            <IonButton
              className="edit-customer-identifiers-button"
              fill="clear"
              onClick={() => {
                this.editCustomerIdentifiers();
              }}
            >
              Edit
            </IonButton>
          )}
        </IonItem>
        {this.getCustomerIdentifierList()}
      </IonList>
    );
  }

  componentDidMount(): void {
    const flattened = CustomerIdentifierService.flattenDataForValidation(this.props.guestInfo);
    if (ValidationHelper.validateWithSchema(flattened, this.schema).isValid) {
      this.props.createOrder();
      return;
    }

    if (!GcnCustomerAccountHelper.customerAccountsAreEnabled()) {
      const guestData = gcn.orderManager.getPersistedGuestData();
      if (guestData) {
        CustomerIdentifierService.setCustomerIdentifierState(guestData);
      }
      this.editCustomerIdentifiers(true, guestData);
      return;
    }
    /**
     * An authenticated users profile might not be fully complete and so we need to still enforce
     * collection of required info but not show the login wall.
     */
    const guestInfo = CustomerIdentifierService.getCustomerIdentifierState();
    if (guestInfo?.name || guestInfo?.email) {
      this.editCustomerIdentifiers(true);
    }
  }

  render(): React.ReactNode {
    const flattened = CustomerIdentifierService.flattenDataForValidation(this.props.guestInfo);
    if (!ValidationHelper.validateWithSchema(flattened, this.schema).isValid) {
      return null;
    }
    return this.getGuestInfo();
  }
}
