import { IonButton, IonItem, IonLabel } from '@ionic/react';
import * as React from 'react';

import type { DeliveryAddress } from '@biteinc/core-react';
import { LocalizationHelper, TimeHelper } from '@biteinc/core-react';
import type { FulfillmentMethod } from '@biteinc/enums';

import { useLocalize } from '~/app/js/localization/localization';
import { useLocation } from '~/stores';

import { GcnFulfillmentHelper } from '../helpers';

interface FulfillmentMethodInfoProps {
  fulfillmentMethod: FulfillmentMethod;
  pickupAtIso?: string;
  enableItemClick?: boolean;
  deliveryAddress?: DeliveryAddress;
  onEdit: () => void;
}

export function FulfillmentMethodInfo(props: FulfillmentMethodInfoProps): React.ReactElement {
  const { deliveryAddress, fulfillmentMethod } = props;
  const str = useLocalize();
  const location = useLocation();
  const address = GcnFulfillmentHelper.addressAsMultipleLines(deliveryAddress);
  const diningOption = location.getDiningOptionForFulfillmentMethod(fulfillmentMethod);
  const diningOptionName =
    diningOption.name ?? str(LocalizationHelper.localizeEnum.FulfillmentMethod(fulfillmentMethod));

  const pickupTimeLabel =
    diningOption.futureOrdersEnabled && (props.pickupAtIso || diningOption.asapOrdersEnabled)
      ? TimeHelper.getFriendlyFutureTimeDescription(props.pickupAtIso)
      : undefined;

  return (
    <IonItem
      lines="none"
      className="edit-fulfillment-method"
      onClick={() => {
        if (props.enableItemClick) {
          props.onEdit();
        }
      }}
    >
      <IonLabel className="change-fulfillment-text">
        <span className="fulfillment-method-label">
          {diningOptionName}
          {deliveryAddress ? (
            <span className="delivery-address">{`\u00a0to\u00a0${address}`}</span>
          ) : null}
        </span>
        {pickupTimeLabel ? <span className="pickup-time"> {pickupTimeLabel}</span> : null}
      </IonLabel>
      <IonButton
        className="edit-fulfillment-method-button"
        fill="clear"
        onClick={() => {
          props.onEdit();
        }}
      >
        Edit
      </IonButton>
    </IonItem>
  );
}
