import { LoyaltyAuthEntryMethod } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import Analytics from '../utils/analytics';
import { GCNNumberEntryView } from './gcn_number_entry_view';

// Handle 7-digit punchh redemption codes.
export const GCNPunchhRedemptionCodeView = GCNNumberEntryView.extend({
  __validateInputAsPhoneNumber: false,
  __expectedInputLength: 7,

  initialize(...args) {
    GCNNumberEntryView.prototype.initialize.apply(this, args);

    this.listenTo(gcn.loyaltyManager, BackboneEvents.GCNLoyaltyManager.DidUpdateAuthGuest, () => {
      if (this._callback) {
        this._callback();
      }
    });
  },

  _lookupUser() {
    gcn.loyaltyManager.handlePunchhLoyaltyData(
      this.__userNumber,
      LoyaltyAuthEntryMethod.RedemptionCodeManuallyEntered,
    );
  },

  render(...args) {
    const self = this;

    GCNNumberEntryView.prototype.render.apply(this, args);

    this.__$label.htmlOrText(localizeStr(Strings.LOYALTY_APP_REDEMPTION_CODE_FALLBACK_LABEL));

    this.setDoneButtonText(localizeStr(Strings.SUBMIT));
    this.__$doneButton.onButtonTapOrHold('prcvDone', () => {
      if (!self.__$doneButton.hasClass('disabled')) {
        Analytics.track(Analytics.EventName.LoyaltyRedeemStart);
        self._lookupUser();
      }
    });

    this.__$icon.hide();
    this.__$skipButton.hide();

    return this;
  },
});
