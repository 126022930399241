import { IonButton } from '@ionic/react';
import React, { Component } from 'react';

import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

interface SubmitOrderButtonProps {
  enabled: boolean;
  onClick(): void;
}

export default class SubmitOrderButton extends Component<SubmitOrderButtonProps, {}> {
  render(): React.ReactNode {
    return (
      <IonButton
        className="cta-button submit-order-button"
        slot="end"
        color="primary"
        expand="block"
        disabled={!this.props.enabled}
        onClick={() => {
          // This is a good place to do some common checkout logic like analytics
          this.props.onClick();
        }}
      >
        {localizeStr(Strings.PLACE_ORDER)}
      </IonButton>
    );
  }
}
