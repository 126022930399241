import $ from 'jquery';
import _ from 'underscore';

import { LocalizationHelper } from '@biteinc/core-react';
import { CardSchemeId } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import GcnHtml from '../gcn_html';
import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

export const GcnStoredValuePaneView = GCNView.extend({
  className: 'stored-value-pane-view',

  _template: _.template('<ul class="transactions-list"></ul>'),

  render() {
    this.$el.html(this._template());

    const $transactionsList = this.$('.transactions-list');

    const transactions = gcn.orderManager.getTransactions();
    const cardsByLastFour = gcn.orderManager
      .getStoredValueCardsAndRemainingBalance()
      .reduce((storedValueCardsByLastFour, card) => {
        // only 1 card per last four
        // edge case here where we show the wrong card because last 4 is not unique
        storedValueCardsByLastFour[card.lastFour] = card;
        return storedValueCardsByLastFour;
      }, {});

    transactions.forEach((transaction) => {
      const card = cardsByLastFour[transaction.getLastFour()];
      let balanceHtml = '';
      let cardLastFourHtml = '';
      if (!_.isUndefined(card?.remainingBalance)) {
        balanceHtml = `<span class="font-body card-balance">${GcnHtml.htmlFromPrice(
          card.remainingBalance,
        )} ${localizeStr(Strings.STORED_VALUE_REMAINING_BALANCE)}</span>`;
      }
      if (card?.lastFour) {
        cardLastFourHtml = ` (*${card.lastFour})`;
      }
      let cardName = card?.cardName;
      if (
        transaction.get('cardSchemeId') === CardSchemeId.EbtCashBenefit ||
        transaction.get('cardSchemeId') === CardSchemeId.EbtFoodStamp
      ) {
        cardName = localizeStr(
          LocalizationHelper.localizeEnum.CardSchemeId(transaction.get('cardSchemeId')),
        );
      }
      if (!cardName) {
        cardName = localizeStr(Strings.GIFT_CARD);
      }

      const $card = $(`
        <li class="card-transaction font-body">
          <div class="card-transaction-details">
            <span class="font-body card-name">${cardName}${cardLastFourHtml}</span>
            <br />${balanceHtml}
          </div>
        </li>
      `);

      const $removeButton = $(
        `<span role="button" class="refund-card-transaction-button border-color-spot-1">${localizeStr(
          Strings.REMOVE_GIFT_CARD,
        )}</span>`,
      );
      $removeButton.onButtonTapOrHold('svpvRemove', () => {
        if ($removeButton.hasClass('disabled')) {
          return;
        }
        Analytics.track(Analytics.EventName.StoredValueDismissed);
        $removeButton.addClass('disabled');
        gcn.menuView.showSpinner(localizeStr(Strings.REFUNDING));
        gcn.orderManager.refundStoredValueTransaction(transaction, () => {
          // unblock ui regardless
          $removeButton.removeClass('disabled');
          gcn.menuView.dismissSpinner();
        });
      });
      $card.append($removeButton);
      $transactionsList.append($card);
    });

    return this;
  },
});
