// cspell:ignore hpsv
import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import { GcnPaymentHelper } from '../../../../helpers';
import { GCNRouterHelper } from '../../gcn_router_helper';
import OrderSender from '../../order_sender';
import { GCNFullScreenFlowStepView } from './gcn_full_screen_flow_step_view';

export const GCNHandlePaymentStepView = GCNFullScreenFlowStepView.extend({
  className: 'full-screen-flow-step-view handle-payment',
  stepType: GCNFullScreenFlowStepView.StepType.HandlePayment,

  _template: _.template(
    // prettier-ignore
    '<div class="title-text" role="heading" aria-level="1" tabindex="-1"><%= payNowStr %></div>' +
    '<div class="image-container">' +
      // Note: The div on top is the one that is initially hidden.
      '<div class="payment-image <%= paymentClass %> frame1"></div>' +
      '<% if (showSecondPaymentOption) { %>' +
        '<div class="image-container frame2">' +
          '<div class="payment-image <%= paymentClass %> frame2"></div>' +
        '</div>' +
      '<% } %>' +
    '</div>' +
    '<div class="swipe-dip-text"><%= paymentPrompt %></div>' +
    '<div class="press-button-text">' +
      '<span><%= thenPressButton1Str %></span>' +
      '<span class="check-button-image"></span>' +
      '<span><%= thenPressButton2Str %></span>' +
    '</div>',
  ),

  onShowTransitionEnd() {
    if (!gcn.bridge) {
      gcn.handleBridgeMessage(
        {
          updatePaymentFlowState: 'waiting_for_card',
        },
        () => {},
      );
    }
    this.parent.handlePaymentTransitionEnded();
  },

  // Locks down a payment image, as well as the cancel button.
  lockInPaymentImage(swipe) {
    if (this.cardAnimationInterval) {
      clearInterval(this.cardAnimationInterval);
      this.$paymentImageOther.css('opacity', swipe ? 1 : 0);
    }
  },

  lockCancelTransactionButton() {
    this.$cancelButton.toggleClass('disabled', true);
  },

  hideCancelTransactionButton() {
    this.$cancelButton.hide();
  },

  showAmountConfirmationPrompt() {
    this.$checkButtonPrompt.slideDown();
  },

  resetPaymentFlowVisuals() {
    const self = this;

    let imageOpacity = 1;
    this.cardAnimationInterval = setInterval(() => {
      imageOpacity = 1 - imageOpacity;
      self.$paymentImageOther.css('opacity', imageOpacity);
    }, 2400);
    this.$cancelButton.toggleClass('disabled', false);
    const paymentSystem = gcn.location.getPaymentSystem();
    if (paymentSystem && GcnPaymentHelper.systemSupportsTransactionCancellation(paymentSystem)) {
      this.$cancelButton.show();
    } else {
      this.$cancelButton.hide();
    }
    this.$checkButtonPrompt.slideUp();
  },

  async cancelPayment() {
    const cancelSuccess = await OrderSender.cancelPaymentFromMaitred();
    if (cancelSuccess && this.parent) {
      this.parent.exitCheckoutFlow();
      GCNRouterHelper.navToMenu();
    }
  },

  // Append the first (header) item into the step body.
  // Override this method to append custom content.
  __renderHeader() {},

  render(...args) {
    GCNFullScreenFlowStepView.prototype.render.apply(this, args);

    this.__renderHeader();

    const paymentSystem = gcn.location.getPaymentSystem();

    this.$stepBody.append(
      this._template({
        payNowStr: localizeStr(Strings.PAY_NOW),
        thenPressButton1Str: localizeStr(Strings.THEN_PRESS_BUTTON_1),
        thenPressButton2Str: localizeStr(Strings.THEN_PRESS_BUTTON_2),
        paymentClass: paymentSystem
          ? GcnPaymentHelper.terminalTypeForSystem(paymentSystem)
          : 'ingenico',
        paymentPrompt: localizeStr(Strings.PAYMENT_PROMPT_NFC),
        showSecondPaymentOption: true,
      }),
    );

    this.$paymentImageOther = this.$stepBody.find('.payment-image.frame2');
    this.$paymentImageOther.css('transition', 'opacity 0.4s');
    this.$paymentImageOther.css('transition-timing-function', 'ease-in-out');

    this.$checkButtonPrompt = this.$stepBody.find('.press-button-text');
    this.$checkButtonPrompt.hide();

    this.$cancelButton = this.$('.button.prev');

    this.setPrevButtonText(localizeStr(Strings.CANCEL_CHECKOUT));

    if (window.hasPaymentTerminal) {
      this.$('.button.next').remove();
      this.$cancelButton.css('float', 'none');
      // Do not call if we're recovering, as garcon will know to handle the
      // payment process without prompting.
      if (!this.options.recoveryFastForward) {
        gcn.sendCheckoutSession(gcn.orderManager.getCheckoutSession());
      }
      if (!GcnPaymentHelper.systemSupportsTransactionCancellation(paymentSystem)) {
        this.hideCancelTransactionButton();
      }
      this.$cancelButton.onButtonTapOrHold('hpsvCancel', () => {
        if (this.$('.button.prev').hasClass('disabled')) {
          return;
        }
        if (gcn.location.usesApiForTerminalPayments()) {
          this.cancelPayment();
          return;
        }
        gcn.notifyUserDidTapCancelPayment();
      });
    } else {
      this.setNextButtonText(localizeStr(Strings.DEMO_PAY));
      this.$cancelButton.onButtonTapOrHold('hpsvCancel', () => {
        if (this.$('.button.prev').hasClass('disabled')) {
          return;
        }
        if (this.parent) {
          this.parent.exitCheckoutFlow();
          GCNRouterHelper.navToMenu();
        }
      });
    }

    if (gcn.screenReaderIsActive) {
      this.$el.find('.title-text').requestFocusAfterDelay();
    }

    if (gcn.maitred.cardWasPreRead()) {
      gcn.menuView.showSpinner(localizeStr(Strings.PROCESSING_PAYMENT));
    }
    return this;
  },
});
