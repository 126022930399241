import { ModGroupWalletType } from '@biteinc/enums';

import type { GcnPriceOption } from '~/types/gcn_price_option';

export function getAddonPrice(
  type: ModGroupWalletType,
  chargePrice: number,
  currValue: number,
  values: any,
  valueCurrIndex: number,
): {
  chargePrice: number;
  runningAmount: number;
  currIndex?: number;
} {
  // Dollar wallet
  let runningAmount = currValue;
  let currIndex = valueCurrIndex;
  switch (type) {
    case ModGroupWalletType.Dollar: {
      if (!runningAmount) {
        return { chargePrice, runningAmount };
      }
      if (runningAmount - chargePrice >= 0) {
        runningAmount -= chargePrice;
        return {
          chargePrice: 0,
          runningAmount,
        };
      }
      const remainder = chargePrice - runningAmount;
      runningAmount = 0;
      return {
        chargePrice: remainder,
        runningAmount,
      };
    }
    case ModGroupWalletType.Quantity: {
      // charge max price if we've exceeded the final cap quantity
      if (
        runningAmount > values[values.length - 1].inclusiveQuantityCap ||
        currIndex === values.length
      ) {
        runningAmount += 1;
        return {
          // sometimes the price is still 0 (in the case of a simple X $0-priced items),
          // we should charge the initial price
          chargePrice: values[values.length - 1].price || chargePrice,
          currIndex,
          runningAmount,
        };
        // charge current bracket price
      }
      if (runningAmount <= values[currIndex].inclusiveQuantityCap) {
        const price = values[currIndex].price;

        // move on brackets if we've reached the current inclusive cap
        if (runningAmount === values[valueCurrIndex].inclusiveQuantityCap) {
          currIndex++;
        }

        runningAmount += 1;
        return {
          chargePrice: price,
          currIndex,
          runningAmount,
        };
      }
    }
  }

  return { chargePrice, runningAmount, currIndex };
}

export function priceWithDefaultMods(priceOption: GcnPriceOption): number {
  let total = priceOption.get('price') ?? 0;

  const walletByParentModGroupId: Record<
    string,
    {
      remainingWalletAmount: number;
      runningQuantity: number;
      valueCurrIndex: number;
    }
  > = {};

  priceOption.addonSets.forEach((modGroup) => {
    const posWalletSettings = modGroup.get('posWalletSettings');
    const parentModGroupId = modGroup.attributes.parentModGroupId;

    if (!posWalletSettings) {
      const addonsSelectedByDefault = modGroup.getAddonIdsAndPricesSelectedByDefault();
      total += priceOption.getPriceForAddonsSelectedByDefault(addonsSelectedByDefault);

      return;
    }

    const { values, type, amount = 0 } = posWalletSettings;

    // for dollar value wallets
    let remainingWalletAmount: number = amount;
    // for quantity value wallets
    let runningQuantity: number = 1;
    let valueCurrIndex: number = 0;

    let runningAmountOfParentWallet;

    if (parentModGroupId) {
      remainingWalletAmount = walletByParentModGroupId[parentModGroupId].remainingWalletAmount || 0;
      runningQuantity = walletByParentModGroupId[parentModGroupId].runningQuantity || 0;
      valueCurrIndex = walletByParentModGroupId[parentModGroupId].valueCurrIndex || 0;
    }

    modGroup.attributes.items.forEach((addon) => {
      const defaultQty = addon.selectedByDefaultQuantity || 0;

      if (!gcn.menu.shouldDisplayPriceWithDefaultMods() || defaultQty === 0) {
        return;
      }

      for (let i = 0; i < defaultQty; i++) {
        const mod = gcn.menu.getMenuItemWithId(addon._id);
        const { chargePrice, runningAmount, currIndex } = getAddonPrice(
          type,
          modGroup.addonPriceInSet(mod!),
          type === ModGroupWalletType.Dollar ? remainingWalletAmount : runningQuantity,
          values,
          valueCurrIndex,
        );

        remainingWalletAmount = runningAmount;
        runningQuantity = runningAmount;
        runningAmountOfParentWallet = runningAmount;
        valueCurrIndex = currIndex!;

        total += chargePrice;
      }
    });

    if (parentModGroupId && runningAmountOfParentWallet !== undefined) {
      walletByParentModGroupId[parentModGroupId] = {
        remainingWalletAmount: runningAmountOfParentWallet || 0,
        runningQuantity: runningAmountOfParentWallet || 0,
        valueCurrIndex,
      };
    }
  });

  return total;
}
