import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import Analytics from '../utils/analytics';
import { GCNNumberPadView } from './gcn_number_pad_view';
import { GCNView } from './gcn_view';

export const GCNTableNumberEntryView = GCNView.extend({
  className: 'order-destination-view table-number',

  inputTableNumberTemplate: _.template(`
    <div class="table-number">
      <div class="back-button"></div>
      <div class="header" role="heading" aria-level="1" tabindex="-1"></div>
      <div class="body">
        <div class="table-stand">
          <div class="user-input"><%= tableNumber %></div>
        </div>
      </div>
      <div class="button-container">
        <div class="done-button"></div>
      </div>
    </div>
  `),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._isTracker = (options || {}).isTracker;
    this._numDigits = (options || {}).numDigits;
    this._tableNumber = gcn.orderManager.getTableNumber() || '';

    this.numberPadView = new GCNNumberPadView();
    this.listenTo(
      this.numberPadView,
      BackboneEvents.GCNNumberPadView.DigitWasPressed,
      this._inputNumber,
    );
    this.listenTo(
      this.numberPadView,
      BackboneEvents.GCNNumberPadView.DeleteWasPressed,
      this._deleteChar,
    );

    this.addRefreshOnLanguageChangeSubscription();
  },

  _inputNumber(number) {
    const prevVal = this.$tableNumberValue.text() || '';
    if (prevVal.length < this._numDigits) {
      this.$tableNumberValue.text(prevVal + number);
    }
    this._toggleDoneButton();
  },

  _deleteChar() {
    const prevVal = this.$tableNumberValue.text() || '';
    if (prevVal.length > 0) {
      this.$tableNumberValue.text(prevVal.substr(0, prevVal.length - 1));
    }
    this._toggleDoneButton();
  },

  _renderBackButton() {
    this.$('.back-button').htmlOrText(localizeStr(Strings.BACK));
    this.$('.back-button').onButtonTapOrHold('tnevBack', () => {
      Analytics.track(Analytics.EventName.TableNumberBackOut);
      this.trigger(BackboneEvents.GCNTableNumberEntryView.BackedOut);
    });
  },

  _toggleDoneButton() {
    const text = this.$tableNumberValue.text() || '';
    const isValid = this._numDigits ? text.length > 0 && text.length <= this._numDigits : !!text;
    this.$doneButton.toggleClass('disabled', !isValid);
  },

  render() {
    this.$el.html(this.inputTableNumberTemplate({ tableNumber: this._tableNumber }));
    this.$('.table-stand .user-input').toggleClass('three-digit', this._numDigits === 3);
    this.$('.table-stand .user-input').toggleClass('four-digit', this._numDigits === 4);

    this.$('.body').append(this.numberPadView.render().$el);

    this.$tableNumberValue = this.$('.user-input');

    if (this._isTracker) {
      this.$('.table-number').addClass('tracker');
      this.$('.header').htmlOrText(
        gcn.loyaltyManager.getAuthedGuestFriendlyName()
          ? localizeStr(Strings.PICK_UP_ENTER_NUMBER_TRACKER_WITH_NAME, [
              gcn.loyaltyManager.getAuthedGuestFriendlyName(),
            ])
          : localizeStr(Strings.PICK_UP_ENTER_NUMBER_TRACKER),
      );
    } else {
      this.$('.header').htmlOrText(
        gcn.loyaltyManager.getAuthedGuestFriendlyName()
          ? localizeStr(Strings.PICK_UP_ENTER_NUMBER_WITH_NAME, [
              gcn.loyaltyManager.getAuthedGuestFriendlyName(),
            ])
          : localizeStr(Strings.PICK_UP_ENTER_NUMBER),
      );
    }

    if (gcn.screenReaderIsActive) {
      this.$('.header').requestFocusAfterDelay();
    }

    this.$doneButton = this.$('.done-button');
    this.$doneButton.htmlOrText(localizeStr(Strings.DONE));
    this.$doneButton.onButtonTapOrHold('tnevDone', () => {
      if (!this.$doneButton.hasClass('disabled')) {
        Analytics.track(Analytics.EventName.TableNumberSubmitted);
        gcn.orderManager.setTableNumber(this.$tableNumberValue.text());
        this.trigger(BackboneEvents.GCNTableNumberEntryView.DidEnterTableNumber);
      }
    });

    this._renderBackButton();
    this._toggleDoneButton();

    return this;
  },
});
