import { IntegrationSystem } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

export function terminalBasedIntegrationUsesApiPayments(
  storedValueSystem: IntegrationSystem.StoredValue,
): boolean {
  switch (storedValueSystem) {
    case IntegrationSystem.EigenStoredValue:
    case IntegrationSystem.PinataPayStoredValue:
    case IntegrationSystem.WorldpayStoredValue:
      return true;
    case IntegrationSystem.Archer:
    case IntegrationSystem.Atrium:
    case IntegrationSystem.AurusPayStoredValue:
    case IntegrationSystem.Cbord:
    case IntegrationSystem.ChaseStoredValue:
    case IntegrationSystem.FreedomPayKioskStoredValue:
    case IntegrationSystem.Givex:
    case IntegrationSystem.OloStoredValue:
    case IntegrationSystem.ParPayStoredValue:
    case IntegrationSystem.PaytronixGift:
    case IntegrationSystem.PinataPayNativeStoredValue:
    case IntegrationSystem.PinataStoredValue:
    case IntegrationSystem.Tcc:
    case IntegrationSystem.Valutec:
      return false;
  }
}

export function getCssClassForCardReader(
  storedValueSystem: IntegrationSystem.StoredValue,
): 'ingenico' | 'msr' | 'hid' | 'verifone' | null {
  switch (storedValueSystem) {
    case IntegrationSystem.Archer:
    case IntegrationSystem.AurusPayStoredValue:
    case IntegrationSystem.ChaseStoredValue:
    case IntegrationSystem.FreedomPayKioskStoredValue:
    case IntegrationSystem.ParPayStoredValue:
    case IntegrationSystem.PinataPayNativeStoredValue:
    case IntegrationSystem.PinataPayStoredValue:
    case IntegrationSystem.WorldpayStoredValue:
      return 'ingenico';

    case IntegrationSystem.EigenStoredValue:
      return 'verifone';

    case IntegrationSystem.Atrium:
    case IntegrationSystem.Cbord: {
      const hasHid = gcn.kiosk?.get('hasHid');
      const hasMsr = gcn.kiosk?.get('hasMsr');
      switch (true) {
        case hasHid && hasMsr:
          return 'msr';
        case hasHid:
          return 'hid';
        case hasMsr:
          return 'msr';
        default:
          return null;
      }
    }

    case IntegrationSystem.Givex:
    case IntegrationSystem.OloStoredValue:
    case IntegrationSystem.PaytronixGift:
    case IntegrationSystem.PinataStoredValue:
    case IntegrationSystem.Tcc:
    case IntegrationSystem.Valutec:
      if (gcn.kiosk?.get('hasMsr')) {
        return 'msr';
      }
      return null;
  }
}

export function getSecondaryCssClassForCardReader(
  storedValueSystem: IntegrationSystem.StoredValue,
): 'hid' | 'scanner' | null {
  switch (storedValueSystem) {
    case IntegrationSystem.Archer:
    case IntegrationSystem.AurusPayStoredValue:
    case IntegrationSystem.ChaseStoredValue:
    case IntegrationSystem.EigenStoredValue:
    case IntegrationSystem.FreedomPayKioskStoredValue:
    case IntegrationSystem.Givex:
    case IntegrationSystem.OloStoredValue:
    case IntegrationSystem.ParPayStoredValue:
    case IntegrationSystem.PinataPayNativeStoredValue:
    case IntegrationSystem.PinataPayStoredValue:
    case IntegrationSystem.PinataStoredValue:
    case IntegrationSystem.Tcc:
    case IntegrationSystem.Valutec:
    case IntegrationSystem.WorldpayStoredValue:
      return null;
    case IntegrationSystem.PaytronixGift:
      const hasScanner = gcn.kiosk?.get('hasScanner');
      const hasBarcodes = gcn.location.getStoredValueIntegration()?.hasBarcodes;
      return hasScanner && hasBarcodes ? 'scanner' : null;
    case IntegrationSystem.Atrium:
    case IntegrationSystem.Cbord: {
      const hasHid = gcn.kiosk?.get('hasHid');
      const hasMsr = gcn.kiosk?.get('hasMsr');
      switch (true) {
        case hasHid && hasMsr:
          return 'hid';
        default:
          return null;
      }
    }
  }
}

export function getNativeStoredValueInstructionText(
  storedValueSystem: IntegrationSystem.StoredValue,
  mode: 'stored-value' | 'comp-card' = 'stored-value',
): string {
  switch (storedValueSystem) {
    case IntegrationSystem.Archer:
    case IntegrationSystem.AurusPayStoredValue:
    case IntegrationSystem.ChaseStoredValue:
    case IntegrationSystem.EigenStoredValue:
    case IntegrationSystem.FreedomPayKioskStoredValue:
    case IntegrationSystem.ParPayStoredValue:
    case IntegrationSystem.PinataPayNativeStoredValue:
    case IntegrationSystem.PinataPayStoredValue:
    case IntegrationSystem.WorldpayStoredValue:
      return localizeStr(Strings.GIFT_CARD_SUBTITLE);

    case IntegrationSystem.Atrium:
    case IntegrationSystem.Cbord: {
      const hasHid = gcn.kiosk?.get('hasHid');
      const hasMsr = gcn.kiosk?.get('hasMsr');
      switch (true) {
        case hasHid && hasMsr:
          return localizeStr(Strings.STUDENT_CARD_SUBTITLE_HID_MSR);
        case hasHid:
          return localizeStr(Strings.STUDENT_CARD_SUBTITLE_HID);
        case hasMsr:
          return localizeStr(Strings.STUDENT_CARD_SUBTITLE_MSR);
      }
      return '';
    }

    case IntegrationSystem.Givex:
    case IntegrationSystem.OloStoredValue:
    case IntegrationSystem.PinataStoredValue:
    case IntegrationSystem.Tcc:
    case IntegrationSystem.Valutec:
      if (gcn.kiosk?.get('hasMsr')) {
        return localizeStr(Strings.SWIPE_YOUR_GIFT_CARD);
      }
      return '';
    case IntegrationSystem.PaytronixGift:
      const hasBarcodes = gcn.location.getStoredValueIntegration()?.hasBarcodes;
      if (gcn.kiosk?.get('hasMsr') && gcn.kiosk?.get('hasScanner') && hasBarcodes) {
        return localizeStr(Strings.SWIPE_OR_SCAN_YOUR_GIFT_CARD);
      }
      if (gcn.kiosk?.get('hasMsr')) {
        return mode === 'comp-card'
          ? localizeStr(Strings.SWIPE_YOUR_COMP_CARD)
          : localizeStr(Strings.SWIPE_YOUR_GIFT_CARD);
      }
      if (gcn.kiosk?.get('hasScanner') && hasBarcodes) {
        return localizeStr(Strings.SCAN_YOUR_GIFT_CARD);
      }
      return '';
  }
}
