import $ from 'jquery';
import _ from 'underscore';

import { Log } from '@biteinc/common';
import {
  BitePlatform,
  LanguageCode,
  MenuItemCategory,
  MenuItemSaleUnitHelper,
} from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import GcnHelper from '../gcn_helper';
import GcnHtml from '../gcn_html';
import Analytics from '../utils/analytics';
import { GCNBuildYourOwnView } from './gcn_build_your_own_view';
import { GCNDictionaryCardView } from './gcn_dictionary_card_view';
import { GCNView } from './gcn_view';

// View with nice presentation of an item, as well as a summary of top level
// information such as descriptions and badges.
export const GCNMenuItemDetailsView = GCNView.extend({
  className: 'item-details-view',
  itemWeighingTemplate: _.template(
    // prettier-ignore
    '<div class="weighable-container margin">' +
      '<div class="weighable-view">' +
        '<div class="instructions"><%= instructions %></div>' +
        '<div class="weight-display">' +
          '<div class="weight-value">0</div>' +
        '</div>' +
        '<div class="unit"><%= unit %></div>' +
      '</div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._options = _.extend(
      {
        showDictionary: true,
      },
      options || {},
    );
    this._menuItem = options.menuItem;
    this._priceOptionId = options.priceOptionId;
    this._orderedItem = options.orderedItem;

    const addonOrPriceOptionChangedEventName =
      BackboneEvents.GCNMenuItemCustomizeView.AddonOrPriceOptionChanged;

    if (this._menuItem.shouldShowNutritionInfo()) {
      this.listenTo(options.customizeView, addonOrPriceOptionChangedEventName, () => {
        this._renderCalories();
      });
    }

    if (this._menuItem.isBuildYourOwnItem()) {
      const eventName = BackboneEvents.GCNMenuItemCustomizeView.AddonOrPriceOptionChanged;
      this.listenTo(options.customizeView, eventName, () => {
        const selectedAddonRefs = this._orderedItem.getSelectedAddonRefsForPriceOption();
        this._byoView.updateForSelectAddonRefs(selectedAddonRefs);
      });
    }
  },

  _renderCalories() {
    if (this._menuItem.shouldShowNutritionInfo()) {
      const calorieRange = this._orderedItem.calorieCounter();
      this.$el.find('.calories').toggleClass('hidden', isNaN(calorieRange.min));
      this.$el.find('.calories-total').html(GcnHtml.stringFromCalorieRange(calorieRange));
    }
  },

  _showGlossaryCard(glossaryWord, left, top, width, height) {
    if (!this._glossaryView) {
      this._glossaryView = new GCNDictionaryCardView({
        model: glossaryWord,
      });
    } else {
      this._glossaryView.updateModel(glossaryWord);
    }

    const css = {
      left,
      top: top + height,
    };
    gcn.menuView.showPopup(this._glossaryView, css);
  },

  setMaxImageSize(maxImageWidth, maxImageHeight) {
    this._maxImageWidth = maxImageWidth;
    this._maxImageHeight = maxImageHeight;

    if (this._menuItem.hasImages()) {
      this._layoutImage();
    }
  },

  _layoutImage() {
    const images = this._menuItem.get('images');
    const image = images[images.length - 1];

    const widthRatio = this._maxImageWidth / image.width;
    let heightToUse = Math.min(Math.ceil(image.height * widthRatio), this._maxImageHeight);

    /**
     * If we have a square image, then let's just crop it so that it fills a 3/2 space;
     * We don't do this for other categories (e.g. drinks) because the square images there are more
     * likely to contain a vertical bottle or some other details on top/bottom of the image which we
     * don't want to get cropped.
     * TODO: THIS IS ALL NOT AN IDEAL SOLUTION
     */
    if (this._menuItem.get('category') === MenuItemCategory.Food && image.width === image.height) {
      heightToUse = this._maxImageHeight;
      this.$('.item-image').css('background-size', 'cover');
    }

    if (this._menuItem.getAddonSetIfAssorted()) {
      if (window.platform === BitePlatform.KioskAndroid) {
        // https://github.com/biteinc/maitred/pull/11783#issuecomment-2136292008
        // we increase the height by 300px to ensure that the `assorted-item` fits in the container
        heightToUse += 300;
      }
      // for assorted items, we need to set the height of the parent container
      // also added an extra 55px to the height to compensate for the extra pixels added to the top
      this.$('.item-image')
        .parent()
        .css('height', `${heightToUse + 55}px`);
    }
    this.$('.item-image').css('height', `${heightToUse}px`);
  },

  handleScaleData(scaleData) {
    Log.info('MIDV:handleScaleData', scaleData);
    const saleUnit = this._menuItem.getSaleUnit();
    if (!scaleData && !saleUnit) {
      return;
    }
    let weight = 0;
    if (scaleData.unit === saleUnit) {
      weight = scaleData.weight;
      Log.info('OK.', weight);
    } else {
      this._internalWeight = null;
      Log.error('Wrong scale unit. Expected', saleUnit, 'got', scaleData.unit);
    }
    this.$('.weighable-view .weight-value').text(weight.toString());
    this._internalWeight = weight;
    this._orderedItem.setWeightAndQuantityOnSelectedPriceOption(weight, 1);
  },

  getWeight() {
    return this._internalWeight || 1;
  },

  render() {
    const self = this;

    this.$el.html('<div class="item-image"></div>');
    const $container = this.$el;

    const isBYO = this._menuItem.isBuildYourOwnItem();
    const assortedBaseImages = this._menuItem.get('assortedBaseImage');
    const addonSetIfMenuItemAssorted = this._menuItem.getAddonSetIfAssorted();
    const $image = this.$('.item-image');
    if (this._menuItem.hasImages()) {
      this._layoutImage();
      const images = this._menuItem.get('images');
      const itemUrl = images[images.length - 1].url;
      if (!isBYO) {
        gcn.requestImageByUrl(itemUrl, (err, imgPath) => {
          $image.css('background-image', `url(${imgPath})`);
        });
      }
    } else {
      $image.addClass(gcn.menu.placeholderImageClass(this._menuItem));
    }

    if (isBYO && !addonSetIfMenuItemAssorted) {
      this.$el.addClass('byo');
      this._byoView = new GCNBuildYourOwnView({
        model: this._menuItem,
      });
      $image.append(this._byoView.render().$el);

      setTimeout(() => {
        $image.css('width', `${self.$el.width()}px`);
      }, 100);
    }

    const caloriesHtml = this._menuItem.getCaloriesHtml();
    $container.append(
      `<div class="item-title-line margin">` +
        `<span class="name font-title">${this._menuItem.displayNameHtml()}${
          caloriesHtml ? `<br />${caloriesHtml}` : ''
        }</span>` +
        `</div>`,
    );
    self._renderCalories();

    const $nameSpan = $container.find('.name');

    const saleUnit = this._menuItem.getSaleUnit();
    if (!gcn.location.isDeliKioskDemo() && saleUnit) {
      // Scale UI
      const $itemWeighingView = this.itemWeighingTemplate({
        instructions: localizeStr(Strings.WEIGHING_INSTRUCTIONS),
        unit: MenuItemSaleUnitHelper.notation(saleUnit),
      });
      $container.append($itemWeighingView);
    }

    this._processedDictionaryWordIdSet = {};

    const itemDesc = this._menuItem.displayDescriptionHtml();
    if (itemDesc.length) {
      $container.append(
        `<div class="item-description font-body margin" role="text">${GcnHtml.glossaryTermsFromString(
          itemDesc,
          this._processedDictionaryWordIdSet,
        )}</div>`,
      );
    }

    const badges = this._menuItem.badges();
    if (badges.length) {
      const $badges = $('<div class="badges font-body margin"></div>');
      $container.append($badges);
      _.each(badges, (badge) => {
        const $span = $(`<span>${badge.displayNameHtml()}</span>`);
        $badges.append($span);
        const badgeUrl = badge.get('icons')[0].url;
        gcn.requestImageByUrl(badgeUrl, (err, imgPath) => {
          $span.css('background-image', `url(${imgPath})`);
        });
      });
    }

    if (gcn.location.get('showNutritionDemo')) {
      const $nutriButton = $(
        `<div class="nutri-section">` +
          `<span role="button" class="button font-body nutri-button">${localizeStr(
            Strings.SHOW_NUTRITION,
          )}</span>` +
          `</div>`,
      );
      $nutriButton.onButtonTapOrHold('midvNutri', () => {
        const $nutri = $('<div class="nutri font-body margin"></div>');
        if (LanguageCode.EN_US !== gcn.getLanguage()) {
          $nutri.addClass(gcn.getLanguage());
        }
        $nutriButton.after($nutri);
        $nutri.hide();
        $nutriButton.slideUp();
        setTimeout(() => {
          $nutri.slideDown();
        }, 1);
      });
      $container.append($nutriButton);
    }

    // Add all price options, if more than one.
    const priceOptions = this._menuItem.priceOptions;
    const samePoPrice = _.all(priceOptions, (po) => {
      return po.getPrice() === priceOptions[0].getPrice();
    });
    if (samePoPrice && priceOptions[0].getPrice() > 0) {
      const $price = $(GcnHtml.htmlFromPriceOption(priceOptions[0], { className: 'font-title' }));
      $price.insertAfter($nameSpan);
    }

    const story = this._menuItem.displayStoryHtml();
    if (story.length) {
      $container.append(
        `<div class="item-story font-body margin">${GcnHtml.glossaryTermsFromString(
          story,
          this._processedDictionaryWordIdSet,
        )}</div>`,
      );

      // Optionally hide the story behind a button.
      if (gcn.menu.shouldDeemphasizeStories()) {
        const $story = $container.find('.item-story');
        const $showStoryButton = $(
          `<div class="link-button">${localizeStr(Strings.SHOW_STORY)}</div>`,
        );
        $showStoryButton.onButtonTapOrHold('midvStory', () => {
          Analytics.track(Analytics.EventName.MenuItemStoryShow);
          $story.slideDown();
          $showStoryButton.slideUp();
        });
        $container.append($showStoryButton);
        $showStoryButton.insertBefore($story);
        $story.hide();
      }
    }

    // assorted custom menu item
    if (addonSetIfMenuItemAssorted) {
      this.$el.addClass('assorted');
      const itemUrl = assortedBaseImages[assortedBaseImages.length - 1]?.url;
      if (itemUrl) {
        gcn.requestImageByUrl(itemUrl, (err, imgPath) => {
          $image.css('background-image', `url(${imgPath})`);
        });
      } else {
        // fallback on if itemUrl is not set
        $image.addClass(gcn.menu.placeholderImageClass(this._menuItem));
      }
      setTimeout(() => {
        // don't allow image to overflow in width
        $image.css('width', `${self.$el.width()}px`);
        this.$el.css(
          'height',
          `${window.innerHeight > 1400 ? 700 : Math.max($image.height(), 500)}px`,
        );
      }, 100);

      const assortedSize = addonSetIfMenuItemAssorted.get('maxAggregateQuantity');
      // only render the UI element if `assortedSize` is set
      if (assortedSize !== 0) {
        this.$('.item-image').addClass('assorted-container');
        const $itemImageContainer = this.$('.item-image');
        const $assortedItemsList = Array.from(Array(assortedSize)).map(() => {
          return `<div class="assorted-item assorted-item-${assortedSize}"></div>`;
        });

        $itemImageContainer.append(...$assortedItemsList);

        // the counter
        $itemImageContainer.append(`<div class="assorted-counter-container">
            <span class="current-count">0</span>
            <span> of ${assortedSize}</span>
          </div>`);
      }
    }

    // Set dictionary handler for any and all processed words.
    const $glossaryItems = $container.find('.dict-word');
    GcnHelper.addGlossaryTermClickers($glossaryItems, (glossaryWord, left, top, width, height) => {
      this._showGlossaryCard(glossaryWord, left, top, width, height);
    });

    return this;
  },
});
