import classNames from 'classnames';
import * as React from 'react';

import { TimeHelper } from '@biteinc/core-react';
import { CardSchemeId } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';
import { Button } from '@biteinc/ui';

import { localizeStr, useLocalize } from '~/app/js/localization/localization';
import { GCNAlertView } from '~/app/js/views/gcn_alert_view';
import GcnGiftCardHelper from '~/helpers/gcn_gift_card_helper';

import { Skeleton } from '../../skeleton';
import type { EcommPaymentFormProps } from '../ecomm-payment-form';
import { cardElement, paymentRequestButtonContainer } from '../ecomm-payment-form';
import { FreedomPayEcommUtils } from './freedom-pay-ecomm-form.utils';
import { SavedCard } from './saved-card';

let partialTenderTimeout: NodeJS.Timeout | null = null;

export function FreedomPayEcommForm(props: EcommPaymentFormProps): JSX.Element {
  const str = useLocalize();

  const {
    iFrames,
    onSelectCard,
    selectedCard,
    onToggleSaveOnSale,
    saveOnSale,
    savedCards,
    hasCustomer,
  } = FreedomPayEcommUtils.useFreedomPayState(props);

  const cancelPartialTenderTimeout = (): void => {
    // Check if existing timer. Starting a new one should clear an old one.
    if (partialTenderTimeout) {
      clearTimeout(partialTenderTimeout);
      partialTenderTimeout = null;
    }
  };

  const startPartialTenderTimeout = (): void => {
    cancelPartialTenderTimeout();
    if (gcn.orderManager.getTransactions().length === 0) {
      return;
    }
    // Pop-up will show up after 2 minutes of no additional transactions.
    partialTenderTimeout = setTimeout(() => {
      const alertText = localizeStr(Strings.PARTIAL_TENDER_TIMEOUT, [], (string: string) => {
        return string.split('\n').join('<br />');
      });
      const warningView = new GCNAlertView({
        text: alertText,
        okCallback() {
          gcn.menuView.dismissModalPopup();
        },
      });
      gcn.menuView.showModalPopup(warningView);
    }, 2 * TimeHelper.MINUTE);
  };

  return (
    // we want this screen to look similar to the FreedomPay iframe design, which means using arial
    <div className="tw-flex tw-flex-col tw-gap-2 tw-font-[Arial,sans-serif] tw-text-base">
      {props.requiresEcommPayment && (
        <>
          {savedCards.status === 'success' && savedCards.data.length > 0 && (
            <>
              <div className="tw-px-2">{str(Strings.CUSTOMER_CARDS)}</div>
              <div className="tw-mx-[10px] tw-flex tw-flex-col tw-divide-y tw-divide-gray-600 tw-rounded-md tw-bg-gray-200 tw-p-1">
                {savedCards.data.map((savedCard) => (
                  <div
                    className="tw-flex tw-flex-col tw-gap-1"
                    key={savedCard._id}
                  >
                    <SavedCard
                      savedCard={savedCard}
                      onSelectCard={onSelectCard}
                      selectedCard={selectedCard}
                    />
                    {selectedCard?._id === savedCard._id &&
                      (selectedCard.cardSchemeId === CardSchemeId.Cbord ? (
                        <div className="tw-flex tw-justify-center tw-p-2">
                          <Button
                            className="tw-w-full tw-p-2 tw-text-[#666666] hover:tw-bg-gray-200"
                            style={{
                              border: '1px solid #666666',
                            }}
                            onClick={() => {
                              gcn.menuView.showSpinner(localizeStr(Strings.LOOKUP_CARD));
                              GcnGiftCardHelper.showGiftCardViewWithPrefilledCardNumber(
                                savedCard.gatewayToken,
                                () => {
                                  // If fully paid and the gift card method allows completion of payment, proceed to next
                                  // step.
                                  if (gcn.orderManager.getGrandTotal() === 0) {
                                    props.onSubmit();
                                  }
                                  // On first tender, we start a timeout. If they do not finish payment in 2 minutes we warn
                                  // them. If they don't finish 5 minutes after the warning we refund them.
                                  startPartialTenderTimeout();
                                },
                              );
                            }}
                            uncolored={true}
                          >
                            {str(Strings.USE_GIFT_CARD)}
                          </Button>
                        </div>
                      ) : (
                        <>
                          {iFrames.savedCard.status === 'fetching' && (
                            <Skeleton className="tw-h-36" />
                          )}
                          {iFrames.savedCard.status === 'success' && (
                            <>
                              <div
                                className="[&>iframe]:tw-h-full [&>iframe]:tw-w-full"
                                style={{ height: iFrames.savedCard.state.height }}
                                dangerouslySetInnerHTML={{ __html: iFrames.savedCard.data.iframe }}
                              />
                              {iFrames.savedCard.state.errors.length > 0 && (
                                <div className="checkout-form-error">
                                  {iFrames.savedCard.state.errors.map((error) => (
                                    <p
                                      className="error-message"
                                      key={error}
                                    >
                                      {error}
                                    </p>
                                  ))}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ))}
                  </div>
                ))}
              </div>
            </>
          )}

          {iFrames.googleWallet.status === 'fetching' && <Skeleton className="tw-h-36" />}
          {iFrames.googleWallet.status === 'success' && (
            <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center">
              <div
                className={`${paymentRequestButtonContainer} google-pay`}
                style={{
                  height: iFrames.googleWallet.state.height,
                }}
                dangerouslySetInnerHTML={{ __html: iFrames.googleWallet.data.iframe }}
              />
              {iFrames.googleWallet.state.errors.length > 0 && (
                <div className="checkout-form-error">
                  {iFrames.googleWallet.state.errors.map((error) => (
                    <p
                      className="error-message"
                      key={error}
                    >
                      {error}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}
          {iFrames.appleWallet.status === 'fetching' && <Skeleton className="h-36" />}

          {iFrames.appleWallet.status === 'success' && (
            <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center">
              <div
                className={`${paymentRequestButtonContainer} apple-pay`}
                style={{
                  height: iFrames.appleWallet.state.height,
                }}
                dangerouslySetInnerHTML={{ __html: iFrames.appleWallet.data.iframe }}
              />
              {iFrames.appleWallet.state.errors.length > 0 && (
                <div className="checkout-form-error">
                  {iFrames.appleWallet.state.errors.map((error) => (
                    <p
                      className="error-message"
                      key={error}
                    >
                      {error}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}

          <div className="tw-flex tw-flex-col tw-gap-1">
            {!selectedCard ? (
              <>
                {iFrames.paymentForm.status === 'fetching' && <Skeleton className="tw-h-[400px]" />}
                {iFrames.paymentForm.status === 'success' && (
                  <>
                    {hasCustomer && (
                      <div className="tw-flex tw-items-center tw-gap-2 tw-px-[10px]">
                        <input
                          id="save-card"
                          type="checkbox"
                          name="save-card"
                          checked={saveOnSale}
                          onChange={() => onToggleSaveOnSale()}
                        />
                        <label
                          className="tw-text-sm"
                          htmlFor="save-card"
                        >
                          {str(Strings.SAVE_CARD)}
                        </label>
                      </div>
                    )}
                    <div className="tw-flex tw-flex-col tw-gap-1 tw-px-[10px]">
                      <label className="text-base leading-6">{str(Strings.NAME_ON_CARD)}</label>
                      <input
                        type="text"
                        value={iFrames.paymentForm.state.cardEntry.name}
                        placeholder="John Doe"
                        className={classNames(
                          'tw-w-full tw-rounded-[0.25rem] tw-border tw-px-3 tw-py-1.5 tw-text-gray-800 tw-duration-150 placeholder:tw-text-[#495057]',
                          {
                            'tw-border-green-500':
                              iFrames.paymentForm.state.cardEntry.status === 'valid',
                            'tw-border-red-500':
                              iFrames.paymentForm.state.cardEntry.status === 'invalid',
                          },
                        )}
                        autoComplete="cc-name"
                        onChange={(event) => {
                          iFrames.paymentForm.state.updateCard({
                            status: event.target.value ? 'valid' : 'invalid',
                            name: event.target.value || '',
                          });
                        }}
                      />
                    </div>
                    {iFrames.paymentForm.state.cardEntry.status === 'invalid' && (
                      <label className="tw-pl-[10px] tw-text-sm tw-text-red-500">
                        Card Name is incomplete.
                      </label>
                    )}
                    <div
                      id={cardElement}
                      dangerouslySetInnerHTML={{ __html: iFrames.paymentForm.data?.iframe }}
                      className="[&>iframe]:tw-h-full [&>iframe]:tw-w-full"
                      style={{
                        height: iFrames.paymentForm.state.height,
                      }}
                    />
                    {iFrames.paymentForm.state.errors.length > 0 && (
                      <div className="checkout-form-error">
                        {iFrames.paymentForm.state.errors.map((error) => (
                          <p
                            className="error-message"
                            key={error}
                          >
                            {error}
                          </p>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="tw-flex tw-justify-center tw-p-2">
                <Button
                  className="tw-w-full tw-p-2 tw-text-[#666666] hover:tw-bg-gray-200"
                  style={{
                    border: '1px solid #666666',
                  }}
                  onClick={() => {
                    onSelectCard(null);
                  }}
                  uncolored={true}
                >
                  Input New Card
                </Button>
              </div>
            )}
          </div>
        </>
      )}
      {props.orderTotalsComponent}
      {!props.requiresEcommPayment ? (
        <Button
          className="tw-w-full tw-bg-[color:var(--color-primary)] tw-p-2 tw-text-white"
          onClick={() => {
            props.onSubmit();
          }}
          uncolored={true}
        >
          {str(Strings.PLACE_ORDER)}
        </Button>
      ) : null}
    </div>
  );
}
