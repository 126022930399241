import $ from 'jquery';
import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import ScreenReaderHelper from '../screen_reader_helper';
import { GCNAlertView } from './gcn_alert_view';
import { GCNNumberPadView } from './gcn_number_pad_view';
import { GCNView } from './gcn_view';

// General purpose number input UI.
// Inheritors of this class need to specify the UI (explanation text, icon, etc) as well as
// functionality when the skip or done button are pressed.
export const GCNNumberEntryView = GCNView.extend({
  className() {
    return 'number-entry-view';
  },
  // Arbitrary for base class.
  __expectedInputLength: 12,
  __validateInputAsPhoneNumber: false,

  template: _.template(
    // prettier-ignore
    '<div class="header">' +
      '<div class="icon"></div>' +
      '<div class="label" role="heading" aria-level="1" tabindex="-1"></div>' +
    '</div>' +
    '<input id="number-entry-input" class="user-input" type="tel" pattern="[0-9]*"/>' +
    '<div id="number-display" class="user-input" aria-level="1" role="heading" tabindex="0" aria-label="No phone number entered"><span aria-hidden="true"></span></div>' +
    '<div id="keypad-wrapper"></div>' +
    '<div class="validation-failed-message"></div>' +
    '<div class="footer">' +
      '<div class="footer-label"><span class="disclaimer-clickable"/>&nbsp;<span class="disclaimer-description"/></div>' +
      '<div class="button-container">' +
        '<div class="button cancel-button" aria-level="1" role="heading"></div>' +
        '<div class="button skip-button"></div>' +
        '<div class="button done-button disabled" aria-level="1" role="heading"></div>' +
      '</div>' +
    '</div>',
  ),

  initialize(options = {}, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._useNativeInput = options.useNativeInput;
    if (!this._useNativeInput) {
      this._numberPadView = new GCNNumberPadView();
      this.listenTo(
        this._numberPadView,
        BackboneEvents.GCNNumberPadView.DigitWasPressed,
        this._inputNumber,
      );
      this.listenTo(
        this._numberPadView,
        BackboneEvents.GCNNumberPadView.DeleteWasPressed,
        this._deleteChar,
      );
    }
    this.__userNumber = options.userNumber || '';
    this._callback = options.callback;
  },

  __updateDisplayValue() {
    this._$numberDisplay.toggleClass('error', false);
    this._$numberDisplay.find('span').text(this.__userNumber);
    this._$numberEntryInput.val(this.__userNumber);
  },

  __isValidPhoneNumber(enteredNumber) {
    // explanation to this regex: https://stackoverflow.com/a/26933567
    // checks for both increasing & decreasing sequences
    const consecutiveNumbersRegex =
      /^((?:0(?=1|$))?(?:1(?=2|$))?(?:2(?=3|$))?(?:3(?=4|$))?(?:4(?=5|$))?(?:5(?=6|$))?(?:6(?=7|$))?(?:7(?=8|$))?(?:8(?=9|$))?9?|(?:9(?=8|$))?(?:8(?=7|$))?(?:7(?=6|$))?(?:6(?=5|$))?(?:5(?=4|$))?(?:4(?=3|$))?(?:3(?=2|$))?(?:2(?=1|$))?(?:1(?=0|$))?0?)$/;
    const repeatedNumbersRegex = /\b(\d)\1+\b/;
    const validAreaCodeRegex = /^([2-9][0-8][0-9])$/;

    return (
      enteredNumber.length === this.__expectedInputLength &&
      !consecutiveNumbersRegex.test(enteredNumber) &&
      !repeatedNumbersRegex.test(enteredNumber) &&
      validAreaCodeRegex.test(enteredNumber.substring(0, 3))
    );
  },

  __updateDoneButtonState() {
    this.__$doneButton.toggleClass(
      'disabled',
      this.__shouldDoneButtonBeDisabled(this.__userNumber),
    );
  },

  __shouldDoneButtonBeDisabled(enteredNumber) {
    return !this.__isValidPhoneNumber(enteredNumber);
  },

  _validatePhoneNumber() {
    if (!this.__validateInputAsPhoneNumber) {
      this.__$doneButton.toggleClass('disabled', false);
      this._$numberValidationFailedMessage.hide();
      return;
    }

    const isValidPhoneNumber = this.__isValidPhoneNumber(this.__userNumber);
    if (!isValidPhoneNumber) {
      this._$numberValidationFailedMessage.show();
    }
    this.__updateDoneButtonState();
  },

  _inputNumber(number) {
    if (this.__userNumber.length < this.__expectedInputLength && /[0-9]/.test(number)) {
      this.__$label.toggleClass('error', false);
      this.__userNumber += number;
      if (gcn.screenReaderIsActive) {
        gcn.showNativeToast(`${number} selected.`);
      }
      this.__updateDisplayValue();
      this.trigger(BackboneEvents.GCNNumberEntryView.ValueUpdated, this);
    }
    this._$numberValidationFailedMessage.hide();
    if (this.__userNumber.length === this.__expectedInputLength) {
      this._validatePhoneNumber();
    }
  },

  _deleteChar() {
    if (this.__userNumber.length > 0) {
      this.__userNumber = this.__userNumber.substring(0, this.__userNumber.length - 1);
      if (gcn.screenReaderIsActive) {
        gcn.showNativeToast('Last digit deleted');
      }
      this.__updateDisplayValue();
      this.trigger(BackboneEvents.GCNNumberEntryView.ValueUpdated, this);
    }
    this._$numberValidationFailedMessage.hide();
    this.__updateDoneButtonState();
  },

  setCancelButtonText(text) {
    this.__$cancelButton.htmlOrText(text);
    this.__$cancelButton.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(text));
  },

  setDoneButtonText(text) {
    this.__$doneButton.htmlOrText(text);
    this.__$doneButton.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(text));
  },

  setSkipButtonText(text) {
    this.__$skipButton.htmlOrText(text);
    this.__$skipButton.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(text));
  },

  render() {
    this.$el.html(this.template());

    // Bind variables
    this.__$label = this.$('.label');
    this.__$icon = this.$('.icon');
    this._$numberDisplay = this.$('#number-display');
    this.__$cancelButton = this.$('.cancel-button');
    this.__$doneButton = this.$('.done-button');
    this.__$skipButton = this.$('.skip-button');
    this.setSkipButtonText(localizeStr(Strings.SKIP));
    this.setCancelButtonText(localizeStr(Strings.CANCEL));
    this.setDoneButtonText(localizeStr(Strings.CONTINUE));
    this.__$footerLabel = this.$('.footer-label');
    this.__$footerLink = this.$('.disclaimer-clickable');
    this.__$footerDescription = this.$('.disclaimer-description');
    this.__$footer = this.$('.footer');
    this._$keypadWrapper = this.$('#keypad-wrapper');
    this._$numberEntryInput = this.$('#number-entry-input');
    this._$numberValidationFailedMessage = this.$('.validation-failed-message');
    this._$numberValidationFailedMessage.hide();
    this._$numberValidationFailedMessage.htmlOrText(
      localizeStr(Strings.ERROR_INVALID_PHONE_NUMBER),
    );

    this.__$footerLink.onButtonTapOrHold('tosOnPhoneDisclaimer', async () => {
      gcn.maitred.getTermsOfService(gcn.location.get('orgId'), (err, data) => {
        const formattedData = err
          ? localizeStr(Strings.TERMS_OF_SERVICE_LOAD_FAILED)
          : data?.tos
              .split('\n')
              .filter((content) => content.length)
              .map((content) => {
                return `<p>${content}</p>`;
              });
        const termsOfServiceView = new GCNAlertView({
          text: formattedData,
          okCallback() {
            gcn.menuView.dismissTopDismissibleModalPopup();
          },
        });

        termsOfServiceView.$el.addClass('animate terms-of-service');
        gcn.menuView.showTopDismissibleModalPopup(termsOfServiceView);
      });
    });

    if (this._useNativeInput) {
      // hide manual input elements
      this._$keypadWrapper.hide();
      this._$numberDisplay.hide();

      this._$numberEntryInput.on('input', (event) => {
        // capture the input value in chunks based on phone number format
        const inputVal = $(event.target)
          .val()
          .replace(/\D/g, '')
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        // rewrite the value of the input html
        $(event.target).val(
          !inputVal[2]
            ? inputVal[1]
            : `(${inputVal[1]}) ${inputVal[2]}${inputVal[3] ? `-${inputVal[3]}` : ''}`,
        );

        this.__userNumber = inputVal[0];
        this.__updateDoneButtonState();
        this._$numberValidationFailedMessage.show();
      });
    } else {
      this._$numberEntryInput.hide();

      // Setup view
      this._$keypadWrapper.html(this._numberPadView.render().$el);
    }

    // Used in new customer identifier view
    this.__$cancelButton.hide();
    if (gcn.screenReaderIsActive) {
      this.__$cancelButton.show();
    }

    this.__updateDisplayValue();
    if (this.__userNumber) {
      this.__updateDoneButtonState();
    }

    // Setup view
    this.__$icon.delayedScaleIn();

    if (gcn.screenReaderIsActive) {
      this.$('.header .label').requestFocusAfterDelay();
    }

    return this;
  },
});
