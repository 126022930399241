import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';

import { localizeStr } from '../localization/localization';
import Analytics from '../utils/analytics';
import { GCNPhoneNumberEntryView } from './gcn_phone_number_entry_view';

export const GCNSmsPromoView = GCNPhoneNumberEntryView.extend({
  className() {
    return `${GCNPhoneNumberEntryView.prototype.className.apply(this)} sms-promo`;
  },

  render(...args) {
    GCNPhoneNumberEntryView.prototype.render.apply(this, args);

    this.__$label.text(
      `Enter your mobile number below and we'll send you an app link to claim your 770 points:`,
    );

    this.setDoneButtonText(localizeStr(Strings.SEND));
    this.__$doneButton.onButtonTapOrHold('smsDone', () => {
      if (!this.__$doneButton.hasClass('disabled')) {
        Analytics.track(Analytics.EventName.PhoneNumberSmsPromoSubmitted);
        gcn.menuView.showSpinner('Sending Text (Demo)');
        setTimeout(() => {
          gcn.menuView.dismissSpinner();
          this.trigger(BackboneEvents.GCNSmsPromoView.DidCompleteLookup, this);
        }, 1500);
      }
    });

    this.__$skipButton.hide();

    return this;
  },
});
