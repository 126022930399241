import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNAsterHallHeaderView } from './gcn_aster_hall_header_view';
import { GCNOrderSummaryStepView } from './gcn_order_summary_step_view';

export const GCNAsterHallSummaryStepView = GCNOrderSummaryStepView.extend({
  // Append custom Aster Hall header.
  __renderHeader() {
    this.$stepBody.append(
      new GCNAsterHallHeaderView({
        title: localizeStr(Strings.YOUR_ORDER),
      }).render().$el,
    );
  },
});
