import _ from 'underscore';

import { Validators } from '@biteinc/common';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr, str } from '~/app/js/localization/localization';
import { GCNForcePlainKeyboard } from '~/helpers/gcn_force_plain_keyboard';

import Analytics from '../utils/analytics';
import { blockedWordValidation } from '../utils/blocked_word_validation';
import { GCNView } from './gcn_view';

export const GCNSpecialRequestView = GCNView.extend({
  className: 'special-request-view',
  template: _.template(
    // prettier-ignore
    '<label for="special-request">Special Request:</label>' +
    '<textarea id="special-request" class="special-request-text" type="text" placeholder="" />' +
    '<div class=blocked-word-warning-container hidden">' +
      '<div class="blocked-word-warning"></div>' +
    '</div>' +
    '<div class="max-length"></div>' +
    '<div class="fine-print"></div>' +
    '<div class="submit-button"></div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.showSubmitButton = options?.showSubmitButton;
    this._options = options;
    this.stringRegex = new RegExp(
      Validators.simpleStringRegexString({
        allowCommas: true,
      }),
    );
  },

  focus() {
    if (this.showSubmitButton) {
      this.$submitButton.slideDown();
    }
  },

  blur() {
    this._$specialRequestTextBox[0].blur();
    gcn.notifyUserDidFocusOutOfTextField();
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);

    this.blur();
    this._$specialRequestTextBox.remove();
    this.$el.html('');
  },

  getValue() {
    // This is for edge cases, where a user may paste
    // special requests, or by quickly typing
    // evade the user input regex
    this._$specialRequestTextBox.val(
      this._$specialRequestTextBox.val().replace(/[^a-zA-Z0-9\s\-.,]/g, ''),
    );
    return this._$specialRequestTextBox.val();
  },

  isProfane() {
    const safeText = this._validateSpecialRequestInput(this.getValue());

    this._$blockWordWarningContainer.toggleClass('hidden', safeText);

    return !safeText;
  },

  setValue(value) {
    if (this._$specialRequestTextBox) {
      this._$specialRequestTextBox.val(value);
    } else {
      this._presetValue = value;
    }
  },

  enableSubmitButton(enabled) {
    this.$submitButton.toggleClass('disabled', !enabled);
  },

  _validateSpecialRequestInput(inputValue) {
    const { validation, result } = blockedWordValidation(inputValue.toLowerCase());
    this._$specialRequestTextBox.removeClass('validation-failed');
    if (!result) {
      this._$specialRequestTextBox.addClass('validation-failed');
      switch (validation) {
        case 'profanity':
        case 'custom':
        case 'generic':
        default:
          break;
      }
    }
    return result;
  },

  render() {
    const self = this;

    this.$el.html(this.template());

    // Note this transform the <textArea> into a <input> tag on qualified devices.
    new GCNForcePlainKeyboard(this.$('#special-request')).enableForcePlainKeyboardIfNeeded();

    this._$blockedWordWarning = this.$('.blocked-word-warning');
    this._$blockedWordWarning.htmlOrText(localizeStr(Strings.BLOCKED_WORD_WARNING));
    this._$blockWordWarningContainer = this.$('.blocked-word-warning-container');
    this._$blockWordWarningContainer.toggleClass('hidden', true);

    this._$specialRequestTextBox = this.$('#special-request');
    this._$specialRequestTextBox.attr('maxlength', gcn.location.getMaxSpecialRequestLength());

    this._$maxLength = this.$('.max-length');

    this._$finePrint = this.$('.fine-print');
    this._$finePrint.hide();

    this.$submitButton = this.$('.submit-button');
    if (this.showSubmitButton) {
      this.$submitButton.htmlOrText(localizeStr(Strings.ADD_TO_ORDER));
      this.$submitButton.onButtonTapOrHold('srvSubmit', () => {
        if (self.$submitButton.hasClass('disabled')) {
          return;
        }
        Analytics.track(Analytics.EventName.SpecialRequestSubmitted);
        self.trigger(BackboneEvents.GCNSpecialRequestView.SubmitButtonPressed);
      });
    }
    this.$submitButton.hide();

    this._$specialRequestTextBox.focus(() => {
      gcn.notifyUserDidFocusOnTextField();
    });
    this._$specialRequestTextBox.keyup(() => {
      const length = self.getValue().length;
      if (length) {
        self._$finePrint.slideDown();
      } else {
        self._$finePrint.slideUp();
      }
      const maxLength = gcn.location.getMaxSpecialRequestLength();
      self._$maxLength.htmlOrText(
        localizeStr(Strings.CHARS_LEFT, [Math.max(0, maxLength - length)]),
      );

      self.trigger(
        BackboneEvents.GCNSpecialRequestView.SpecialRequestTextChanged,
        self.isProfane(),
      );

      return false;
    });
    this._$specialRequestTextBox.focus(() => {
      self.focus();
    });
    this._$specialRequestTextBox.blur(() => {
      if (self.showSubmitButton) {
        self.$submitButton.slideUp();
      }
    });
    // Required for Firefox, works on all other browsers
    // as well including iOS Mobile. Does not work for android
    if (window.isFlash && navigator.userAgent.indexOf('Firefox') !== -1) {
      this._$specialRequestTextBox.keydown((e) => {
        const k = e.keyCode;
        if (13 === k) {
          self.blur();
          return false;
        }
        const ok =
          (k >= 65 && k <= 90) || // A-Z
          (k >= 96 && k <= 105) || // a-z
          (k >= 35 && k <= 40) || // arrows
          k === 8 || // Backspaces
          k === 32 || // Space
          (!e.shiftKey && k >= 48 && k <= 57); // 0-9

        if (!ok) {
          return false;
        }
        return true;
      });
    } else {
      // Works for everyone but Mozilla Firefox. When/if they
      // support, we can remove the above block.
      this._$specialRequestTextBox.on('textInput', (e) => {
        return this.stringRegex.test(e.originalEvent?.data);
      });
    }
    // Localization.
    this.$('label').htmlOrText(`${localizeStr(Strings.SPECIAL_REQUESTS)}:`);
    this._$specialRequestTextBox.attr('placeholder', str(Strings.SPECIAL_REQUESTS_EXAMPLE));
    this._$finePrint.htmlOrText(localizeStr(Strings.SPECIAL_REQUESTS_FINEPRINT));

    if (this._presetValue) {
      this._$specialRequestTextBox.val(this._presetValue);
    }

    return this;
  },
});
