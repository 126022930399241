import { ErrorCode, PaymentCode } from '@biteinc/common';
import type { LocalizableString } from '@biteinc/localization';
import { Strings } from '@biteinc/localization';

import { localizeStr, str } from '../localization/localization';

export default class Errors {
  static isPOSValidation86dError(code?: number): boolean {
    if (!code) {
      return false;
    }
    return [
      ErrorCode.POSValidationItem86d,
      ErrorCode.POSValidationPriceOption86d,
      ErrorCode.POSValidationMod86d,
      ErrorCode.OrderValidationOrderedItemsUnavailableInMenu,
    ].includes(code);
  }

  static isApplyRewardError(code?: number): boolean {
    if (!code) {
      return false;
    }
    return [
      ErrorCode.LoyaltyRewardCannotBeApplied,
      ErrorCode.LoyaltyBadResponseError,
      ErrorCode.CannotModifyLoyaltyAfterPayment,
      ErrorCode.POSDiscountNotAccepted,
      ErrorCode.LoyaltyRewardItemMissing,
      ErrorCode.LoyaltyMinimumOrderAmountNotMet,
    ].includes(code);
  }

  static isPosValidationCode(code?: number): boolean {
    if (!code) {
      return false;
    }
    return (
      this.isPOSValidation86dError(code) ||
      [
        ErrorCode.POSValidationItemMaxQuantityExceeded,
        ErrorCode.POSValidationItemMinQuantityUnmet,
        ErrorCode.POSValidationModMaxQuantityExceeded,
        ErrorCode.POSValidationRuleRequirementUnmet,
        ErrorCode.POSValidationModMinQuantityUnmet,
        ErrorCode.POSValidationAlcoholSaleRequiresFoodInCart,
      ].includes(code)
    );
  }

  static isBadTimeSlotOrThrottlingError(code?: number): boolean {
    if (!code) {
      return false;
    }
    return [
      ErrorCode.FutureOrderValidationWhenSlotExpired,
      ErrorCode.FutureOrderValidationWhenSlotFarOut,
      ErrorCode.FutureOrderValidationWhenSlotInvalid,
      ErrorCode.FutureOrderValidationWhenSlotThrottled,
      ErrorCode.FutureOrderValidationWhenLeadTimeExceedsSlot,
      ErrorCode.OrderThrottleDollarValueExceeded,
      ErrorCode.OrderThrottleCountExceeded,
    ].includes(code);
  }

  static isNoFutureOrderSlotsError(code?: number): boolean {
    return code === ErrorCode.FutureOrderNoSlotsAvailable;
  }

  static stringFromErrorCode(
    code: number | undefined,
    fallback: LocalizableString = Strings.GENERIC_ERROR,
  ): string {
    switch (code) {
      case ErrorCode.AuthInvalidLoginCredentials:
        return localizeStr(Strings.ERROR_INVALID_LOGIN_CREDS);
      case ErrorCode.StoredValueCardNotFound:
        return localizeStr(Strings.ERROR_CARD_NOT_FOUND);
      case ErrorCode.StoredValueCardInsufficientFunds:
        return localizeStr(Strings.ERROR_AMOUNT_EXCEEDS_BALANCE);
      case ErrorCode.StoredValueCardDeclined:
        return localizeStr(Strings.ERROR_CARD_DECLINED);
      case ErrorCode.StoredValueCardDeactivated:
        return localizeStr(Strings.ERROR_CARD_INACTIVE);
      case ErrorCode.StoredValueCardRestrictedTime:
        return localizeStr(Strings.ERROR_CARD_RESTRICTED_TIME);
      case ErrorCode.POSValidationItem86d:
        return localizeStr(Strings.ITEM_86D);
      case ErrorCode.POSValidationMod86d:
        return localizeStr(Strings.MOD_86D);
      case ErrorCode.SendOrderUITimeoutWithPayment:
        return `${localizeStr(Strings.SEND_ORDER_UI_TIMEOUT)} ${localizeStr(
          Strings.PAYMENT_WAS_TAKEN,
        )}`;
      case ErrorCode.SendOrderUITimeoutWithoutPayment:
        return localizeStr(Strings.SEND_ORDER_UI_TIMEOUT);
      case ErrorCode.PaymentUserTimeout:
        return localizeStr(Strings.PAYMENT_KIOSK_TIMEOUT);
      case ErrorCode.PaymentError:
        return localizeStr(Strings.PAYMENT_KIOSK_ERROR);
      case ErrorCode.LoyaltyAuthFailed:
      case ErrorCode.LoyaltyNoMatchingUser:
      case ErrorCode.LoyaltyNonUniqueIdentifier:
        return localizeStr(Strings.LOYALTY_AUTH_GENERIC_ERROR);
      case ErrorCode.OrderTotalAboveMax:
        return localizeStr(Strings.ORDER_TOTAL_MAX_EXCEEDED);
      case ErrorCode.OrderThrottleDollarValueExceeded:
      case ErrorCode.OrderThrottleCountExceeded:
      case ErrorCode.FutureOrderValidationWhenSlotThrottled:
        return localizeStr(Strings.ORDER_THROTTLE_MAX_EXCEEDED);
      case ErrorCode.FutureOrderValidationWhenSlotExpired:
        return localizeStr(Strings.FUTURE_ORDER_VALIDATION_WHEN_SLOT_EXPIRED_ERROR);
      case ErrorCode.FutureOrderValidationWhenLeadTimeExceedsSlot:
        return localizeStr(Strings.LEAD_TIME_LONG);
      case ErrorCode.OrderValidationOrderedItemsUnavailableInMenu:
        return localizeStr(Strings.ITEMS_NO_LONGER_IN_MENU);
      case ErrorCode.OrderValidationWhenClosed:
        return localizeStr(Strings.KITCHEN_CLOSED);
      case ErrorCode.POSCouponNotFound:
        return localizeStr(Strings.COUPON_NOT_FOUND);
      // These error codes should display the message from the server as it will usually specify the
      // reason (e.g. min spend)
      case ErrorCode.POSCouponUnmetParameters:
      case ErrorCode.POSCouponNotAccepted:
      case ErrorCode.POSCouponMinSpendUnmet:
        return localizeStr(Strings.COUPON_NOT_ACCEPTED);
      case ErrorCode.LoyaltySignInAttemptsExceeded:
        return localizeStr(Strings.LOYALTY_SIGN_IN_ATTEMPTS_EXCEEDED);
    }

    return localizeStr(fallback);
  }

  static stringFromPaymentCode(
    code: number | undefined,
    fallback: LocalizableString = Strings.GENERIC_ERROR,
  ): string {
    switch (code) {
      case PaymentCode.PaymentDeclinedGeneric:
        return str(Strings.ERROR_CARD_DECLINED);
      case PaymentCode.PaymentLiveCardUsedInTest:
        return str(Strings.PLEASE_USE_TEST_CARD);
    }

    return str(fallback);
  }
}
