import { IonCol, IonImg, IonItem, IonLabel, IonList, IonRow, IonSkeletonText } from '@ionic/react';
import React, { Component } from 'react';
import _ from 'underscore';

import type { ServiceCharge } from '@biteinc/core-react';
import { LocalizationHelper } from '@biteinc/core-react';
import { CardSchemeIdHelper } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { localizeStr, str } from '~/app/js/localization/localization';
import { GCNTransaction } from '~/app/js/models/gcn_transaction';
import type { Store } from '~/stores';

import { GcnPaymentHelper } from '../helpers';
import type { GcnTransaction, OrderPayload } from '../types';
import type { StoredValueCard } from '../types/stored_value_card';

type Line = Readonly<{
  label: string | React.ReactNode;
  value: number;
  isBold?: true;
  type?:
    | 'total'
    | 'subtotal'
    | 'grand-total'
    | 'tax'
    | 'discount'
    | 'tip'
    | 'service-charge'
    | 'stored-value';
}>;

export class OrderTotals extends Component<Pick<Store['checkout'], 'order' | 'transactions'>, {}> {
  public static totalFromPrice(priceInCents: number, currencySymbol = ''): string {
    const absTotal = (Math.abs(priceInCents) / 100.0).toFixed(2);
    return `${priceInCents < 0 ? '-' : ''}${currencySymbol}${absTotal}`;
  }

  private static renderLine(line: Line, useSkeletonText: boolean): React.ReactNode {
    return (
      <IonItem
        lines="none"
        className={`order-totals-item ${line.isBold ? 'bold' : ''}`}
      >
        <IonLabel>
          <IonRow>
            <IonCol>
              <p>{line.label}</p>
            </IonCol>
            <IonCol className="text-right">
              {useSkeletonText ? (
                <IonSkeletonText
                  animated={true}
                  style={{ width: '50px' }}
                />
              ) : (
                <p
                  className={line.type ? `${line.type}-amount` : undefined}
                >{`${OrderTotals.totalFromPrice(line.value, '$')}`}</p>
              )}
            </IonCol>
          </IonRow>
        </IonLabel>
      </IonItem>
    );
  }

  private static getAppliedStoredValueCards(saleTransactions: GcnTransaction[]): Line[] {
    const cardNameByLastFour = gcn.orderManager
      .getStoredValueCards()
      .reduce((nameByLastFour: Record<string, string>, card: StoredValueCard) => {
        return {
          ...nameByLastFour,
          [card.lastFour]: card.cardName,
        };
      }, {});

    const lines: Line[] = [];
    saleTransactions.forEach((transaction) => {
      if (!CardSchemeIdHelper.isStoredValue(transaction.get('cardSchemeId'))) {
        return;
      }
      const defaultCardName = str(Strings.GIFT_CARD);
      const lastFour = transaction.getLastFour();
      const cardName = cardNameByLastFour[lastFour];
      lines.push({
        label: `${cardName || defaultCardName} (*${lastFour})`,
        // -1 to avoid confusion where it may seem the amount is being added to the total
        value: -1 * transaction.get('amount'),
        type: 'stored-value',
      });
    });
    return lines;
  }

  private static getOtherTransactions(saleTransactions: GcnTransaction[]): Line[] {
    const lines: Line[] = [];
    saleTransactions.forEach((transaction) => {
      const cardSchemeId = transaction.get('cardSchemeId');
      if (CardSchemeIdHelper.isStoredValue(cardSchemeId)) {
        return;
      }
      const lastFour = transaction.getLastFour();
      lines.push({
        label: (
          <div className="paid-by-line">
            <span>Paid via</span>
            <IonImg
              className="card-scheme-image"
              src={GcnPaymentHelper.getCardSchemeImage(cardSchemeId)}
              alt={str(LocalizationHelper.localizeEnum.CardSchemeId(cardSchemeId))}
            />
            <span>{`****${lastFour}`}</span>
          </div>
        ),
        value: transaction.get('amount'),
      });
    });
    return lines;
  }

  private getLinesForOrder(order: OrderPayload): Line[] {
    if (!order.wasValidated) {
      return [
        { label: `${str(Strings.SUBTOTAL)}:`, value: 0, type: 'subtotal' },
        { label: `${str(Strings.TAXES)}:`, value: 0, type: 'tax' },
        { label: `${str(Strings.TOTAL)}:`, value: 0, isBold: true, type: 'total' },
      ];
    }

    const lines: Line[] = [
      { label: `${str(Strings.SUBTOTAL)}:`, value: order.subTotal, type: 'subtotal' },
    ];
    // We check if discountTotal exists
    if (!_.isUndefined(order.discountTotal) && order.discountNames?.length) {
      const discountLabel = (
        <div className="discount-label">
          <div className="discount">{localizeStr(Strings.DISCOUNTS)}:</div>
          {order.discountNames?.length ? (
            <p className="discount-names">{order.discountNames}</p>
          ) : (
            ''
          )}
        </div>
      );
      lines.push({ label: discountLabel, value: -order.discountTotal, type: 'discount' });
    }
    if (order.serviceChargeTotal && !order.serviceCharges?.length) {
      lines.push({
        label: `${str(Strings.SERVICE_CHARGE)}:`,
        value: order.serviceChargeTotal,
        type: 'service-charge',
      });
    }
    if (order.serviceChargeTotal && order.serviceCharges?.length) {
      order.serviceCharges.forEach((serviceCharge: ServiceCharge) => {
        lines.push({
          label: `${serviceCharge.name}:`,
          value: serviceCharge.amount,
          type: 'service-charge',
        });
      });
    }
    lines.push({ label: `${str(Strings.TAXES)}:`, value: order.taxTotal, type: 'tax' });
    if (order.tipTotal) {
      lines.push({ label: `${str(Strings.TIP)}:`, value: order.tipTotal, type: 'tip' });
    }

    const saleTransactions = GCNTransaction.getSuccessfulSaleTransactionsWithoutRefunds(
      this.props.transactions,
    );

    // Applied Stored Value Cards
    if (order.isClosed) {
      // After the order is closed we want to show the total followed by all the transactions
      lines.push({
        label: `${str(Strings.PAYMENT_TOTAL)}:`,
        value: order.total,
        isBold: true,
        type: 'grand-total',
      });
      lines.push(...OrderTotals.getAppliedStoredValueCards(saleTransactions));
      lines.push(...OrderTotals.getOtherTransactions(saleTransactions));
    } else {
      // Before the order is closed we want to show the gift cards before the remaining total
      lines.push(...OrderTotals.getAppliedStoredValueCards(saleTransactions));
      lines.push({
        label: `${str(Strings.TOTAL)}:`,
        value: gcn.orderManager.getGrandTotal(),
        isBold: true,
        type: 'total',
      });
    }
    return lines;
  }

  render(): React.ReactNode {
    const lines: Line[] = this.getLinesForOrder(this.props.order);
    const useSkeletonText = !this.props.order.wasValidated;

    return (
      <IonList
        lines="none"
        className="order-totals-list"
      >
        {lines
          .map((line) => {
            return OrderTotals.renderLine(line, useSkeletonText);
          })
          .map((node, index) =>
            React.isValidElement(node)
              ? React.cloneElement(node as React.ReactElement, { key: index })
              : node,
          )}
      </IonList>
    );
  }
}
