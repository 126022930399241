import _ from 'underscore';

import { LoyaltyAuthEntryMethod } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { localizeStr, str } from '~/app/js/localization/localization';
import { GCNForcePlainKeyboard } from '~/helpers/gcn_force_plain_keyboard';

import { GCNLoyaltyProgramHeaderView } from './gcn_loyalty_program_header_view';
import { GCNView } from './gcn_view';

export const GCNLoyaltyCardNumberView = GCNView.extend({
  className: 'loyalty-card-number-view',

  template: _.template(
    // prettier-ignore
    '<div class="body">' +
      '<div class="instructions font-body"></div>' +
      '<input class="form-control" id="loyalty-identifier-input" autocomplete="off" autocorrect="off" autocapitalize="off"/>' +
    '</div>' +
    '<div class="footer">' +
      '<button class="button done"></button>' +
    '</div>',
  ),

  _validateInputAndUpdateUIState(identifier) {
    const identifierValidated = (identifier || '').length > 0 && /^[A-Za-z0-9]+$/.test(identifier);
    this._$identifierInput.toggleClass('error', !identifierValidated);

    return identifierValidated;
  },

  _updateTitleWithError(errorMsg) {
    this._$instructions.htmlOrText(errorMsg);
    this._$instructions.toggleClass('error', true);
  },

  _attemptLookupUser() {
    const identifier = this._$identifierInput.val().trim();
    if (this._validateInputAndUpdateUIState(identifier)) {
      gcn.menuView.dismissStablePopup();
      gcn.loyaltyManager.submitLoyaltyTokenData(
        identifier,
        LoyaltyAuthEntryMethod.CardNumberManuallyEntered,
      );
    }
  },

  render() {
    const self = this;
    this.$el.html(this.template());

    this.$el.prepend(new GCNLoyaltyProgramHeaderView().render().$el);

    this._$instructions = this.$('.instructions');
    this._$doneButton = this.$('.button.done');
    this._$identifierInput = this.$('#loyalty-identifier-input');

    this._$doneButton.htmlOrText(localizeStr(Strings.FIND_ACCOUNT));

    this._$identifierInput.attr('placeholder', str(Strings.CREDIT_CARD_NUMBER).toLowerCase());
    // For testing paytronix
    // this._$identifierInput.val('6005943979');

    this._$instructions.htmlOrText(localizeStr(Strings.LOYALTY_CARD_NUMBER_INSTRUCTIONS));
    this._$doneButton.htmlOrText(localizeStr(Strings.FIND_ACCOUNT));

    this._$doneButton.onButtonTapOrHold('lcnvDone', () => {
      self._attemptLookupUser();
    });
    this._$identifierInput.keyup((e) => {
      if (e.keyCode === 13) {
        self._attemptLookupUser();
      }
      return false;
    });

    new GCNForcePlainKeyboard(this._$identifierInput).enableForcePlainKeyboardIfNeeded();

    return this;
  },
});
