import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNPopup } from './gcn_popup';

// A screen that can not be dismissed.
export const GCNClosedScreenView = GCNPopup.extend({
  className(...args) {
    const className = GCNPopup.prototype.className.apply(this, args);
    return `${className} closed-screen-view`;
  },

  template: _.template(
    // prettier-ignore
    '<div class="container">' +
      '<div class="logo"></div>' +
      '<div class="closed-message font-body"></div>' +
    '</div>',
  ),

  show() {
    this.$el.show();
  },

  render() {
    this.$el.html(this.template());

    const titleBarImage = gcn.menu.getTitleBarImage();
    if (titleBarImage && titleBarImage.url) {
      gcn.requestImageByUrl(titleBarImage.url, (_err, imgPath) => {
        this.$('.logo').css('background-image', `url(${imgPath})`);
      });
    }

    this.$('.closed-message').htmlOrText(localizeStr(Strings.CLOSED_SCREEN_MESSAGE));

    return this;
  },
});
