import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import * as Sentry from '@sentry/browser';
import { close } from 'ionicons/icons';
import QRCode from 'qrcode';
import React from 'react';

import { Log } from '@biteinc/common';
import { BaseFormComponent, Logo, SignupSchema } from '@biteinc/core-react';
import type { FormProps, FormState, Schema } from '@biteinc/core-react';
import { ApiResource, ApiVersion, RewardType } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import GcnHtml from '~/app/js/gcn_html';
import type { GcnReward } from '~/types/gcn_reward';

type FormData = {};

export interface ThirdPartyLoyaltyAccountData {
  qrCodeString: string;
  status: string;
  rewards: GcnReward[];
}

interface QrCodeModalProps extends FormProps<FormData> {
  logoUrl?: string;
  loyaltyData: ThirdPartyLoyaltyAccountData | null;
  addFundsUrl: string;
  signUpUrl: string;
  onClose: () => void;
}

interface QrCodeModalState extends FormState<Partial<FormData>> {}

export class QrCodeModal extends BaseFormComponent<FormData, QrCodeModalProps, QrCodeModalState> {
  resource: ApiResource = ApiResource.Customer;

  version: ApiVersion = ApiVersion.V2;

  schema: Schema = SignupSchema;

  canvas: React.RefObject<HTMLCanvasElement>;

  constructor(props: QrCodeModalProps) {
    super(props);
    this.state = {
      data: {},
      formValid: false,
    };
    this.canvas = React.createRef();
  }

  componentDidMount(): void {
    if (this.props.loyaltyData) {
      QRCode.toCanvas(
        this.canvas.current,
        this.props.loyaltyData.qrCodeString,
        {
          width: window.innerWidth,
          margin: 2,
        },
        (err) => {
          // For now just track the error in sentry.
          // We can show some error UI once we know how often this happens and why.
          Log.error('Error generating qr code', err);
          Sentry.captureException(err);
        },
      );
    }
  }

  private getLogo(): React.ReactNode {
    if (this.props.logoUrl) {
      return React.createElement(Logo, { url: this.props.logoUrl });
    }
    return undefined;
  }

  private getLoggedInContent(loyaltyData: ThirdPartyLoyaltyAccountData): React.ReactNode {
    const walletReward = loyaltyData.rewards.find((reward) => {
      return reward.get('type') === RewardType.Wallet;
    });
    const balanceStr = walletReward
      ? `$${GcnHtml.stringFromPrice(walletReward.get('amount'))}`
      : loyaltyData.status;
    const rewards = loyaltyData.rewards.filter((reward) => {
      return reward !== walletReward;
    });

    return React.createElement(
      IonRow,
      null,
      React.createElement(
        IonCol,
        { className: 'logged-in-content ion-text-center' },
        React.createElement('canvas', {
          ref: this.canvas,
          className: 'qr-code',
        }),
        React.createElement(
          'p',
          { className: 'status tw-font-bold' },
          `${this.localize(Strings.LOYALTY_BALANCE_LABEL)} ${balanceStr}`,
        ),
        rewards.length
          ? React.createElement(
              'div',
              {
                className: 'tw-p-3 tw-flex tw-flex-col rewards',
              },
              ...rewards.map((reward) => {
                return React.createElement(
                  'span',
                  {
                    className:
                      'reward tw-px-2 tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap',
                  },
                  reward.displayName(true),
                );
              }),
            )
          : null,
      ),
    );
  }

  render(): React.ReactNode {
    return React.createElement(
      IonPage,
      null,
      React.createElement(
        IonHeader,
        null,
        React.createElement(
          IonToolbar,
          null,
          React.createElement(
            IonButtons,
            { slot: 'start' },
            React.createElement(
              IonButton,
              { color: 'light', onClick: this.props.onClose.bind(this) },
              React.createElement(IonIcon, { slot: 'icon-only', icon: close }),
            ),
          ),
        ),
      ),
      React.createElement(
        IonContent,
        { className: 'signup-content qr-code-content' },
        React.createElement(
          IonRow,
          { className: 'container-row ion-align-items-center' },
          React.createElement(
            IonCol,
            null,
            React.createElement(
              IonRow,
              { className: 'ion-text-center' },
              React.createElement(IonCol, null, this.getLogo()),
            ),
            this.props.loyaltyData
              ? this.getLoggedInContent(this.props.loyaltyData)
              : React.createElement(
                  IonRow,
                  { className: 'ion-text-center tw-px-16' },
                  React.createElement(
                    IonCol,
                    null,
                    this.localize(Strings.LOYALTY_AUTH_GENERIC_ERROR),
                  ),
                ),
            React.createElement(
              'p',
              { className: 'ion-text-center ion-margin-top' },
              React.createElement(
                IonButton,
                {
                  className: 'qr-code-cta-button ion-margin-top',
                  color: 'dark',
                  href: this.props.loyaltyData ? this.props.addFundsUrl : this.props.signUpUrl,
                  target: '_blank',
                },
                this.localize(this.props.loyaltyData ? Strings.ADD_FUNDS : Strings.SIGN_UP),
              ),
            ),
          ),
        ),
      ),
    );
  }
}
