import $ from 'jquery';
import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import GcnHtml from '../gcn_html';
import { GCNView } from './gcn_view';

export const GCNPriceOptionPickerView = GCNView.extend({
  className: 'price-option-picker-view',

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._options = options || {};
    this.priceOptions = options.priceOptions;
  },

  selectPriceOption(priceOptionId) {
    const self = this;
    let changesMade = false;
    _.each(this.priceOptions, (priceOption) => {
      if (priceOption.id === priceOptionId) {
        self.selectedPriceOption = priceOption;
        self.$el.find(`#${priceOptionId}`).addClass('selected');
        changesMade = true;
      }
    });
    if (changesMade && this._options.priceOptionChangedCallback) {
      this._options.priceOptionChangedCallback(this.selectedPriceOption);
    }
  },

  _priceOptionOnTap(priceOption, $priceOptionButton) {
    if (this.selectedPriceOption) {
      this.$el.find('.price-option-button.selected').removeClass('selected');
    }
    this.selectedPriceOption = priceOption;
    $priceOptionButton.addClass('selected');

    if (this._options.priceOptionChangedCallback) {
      this._options.priceOptionChangedCallback(this.selectedPriceOption);
    }
  },

  _getPriceOptionCategoryText() {
    let priceOptionCategory = this._options.priceOptionCategory;
    if (!priceOptionCategory) {
      priceOptionCategory = localizeStr(Strings.CHOOSE_OPTION);
    }
    return priceOptionCategory;
  },

  render() {
    const self = this;
    this.$el.html('');

    if (!this.priceOptions.length) {
      return this;
    }

    if (this._options.showHeader) {
      const $header = $(
        // prettier-ignore
        '<div class="header font-body">' +
          '<span class="title"></span>' +
          '<span class="max-warning"></span>' +
          '<div class="wallet-message"></div>' +
        '</div>',
      );
      this.$el.append($header);

      $header.find('.title').htmlOrText(this._getPriceOptionCategoryText());
    }

    const $addonList = $('<div class="price-options"></div>');
    this.$el.append($addonList);

    const samePrice = _.all(this.priceOptions, (po) => {
      return po.get('price') === self.priceOptions[0].get('price');
    });

    _.each(this.priceOptions, (priceOption) => {
      // TODO: Reuse GCNAddonButtonView here.
      const $priceOptionButton = $(`
        <div id=${priceOption.id} class="price-option-button font-body">
        ${
          !priceOption.isHidden()
            ? `<div>
                <div class="image no-image"></div>
              </div>`
            : ''
        }
          <div class="price-option-header">
            <div class="left-col">
              <span class="name">${priceOption.displayNameHtml()}</span>
            </div>
            ${
              samePrice || priceOption.isHidden()
                ? ''
                : GcnHtml.htmlFromPriceOption(priceOption, { className: 'braces' })
            }
          </div>
        </div>
      `);
      $addonList.append($priceOptionButton);
      const isDisabled = priceOption.isHidden();

      if (isDisabled) {
        $priceOptionButton.toggleClass('disabled', true);
        $priceOptionButton.append(
          `<div class="out-of-stock-message">${localizeStr(Strings.OUT_OF_STOCK)}</div>`,
        );
      } else {
        // Highlight the parent's selected PO, if any.
        if (
          (self.selectedPriceOption && priceOption.id === self.selectedPriceOption.id) ||
          !!priceOption.attributes.selectedByDefault
        ) {
          $priceOptionButton.addClass('selected');

          // If this price option is selected by default, then we should update the selectedPriceOption
          if (!self.selectedPriceOption) {
            self.selectedPriceOption = priceOption;
          }
          self._options.priceOptionChangedCallback(self.selectedPriceOption);
        }

        $priceOptionButton.onTapInScrollableAreaWithCalibration('popv', () => {
          self._priceOptionOnTap(priceOption, $priceOptionButton);
        });
      }
    });

    return this;
  },
});
