import { IntegrationSystem, OrderChannel } from '@biteinc/enums';

module StoredValueHelper {
  // Returns true if the gift card is actually some sort of secondary payment method, like a
  // student card or some other stored payment method.
  export function isStoredValueSecondaryPayment(): boolean {
    return !!gcn.location.getStoredValueIntegration()?.useAsSecondaryPayment;
  }

  export function requiresNativeForStoredValue(
    storedValueSystem: IntegrationSystem.StoredValue,
  ): boolean {
    switch (storedValueSystem) {
      case IntegrationSystem.Archer:
      case IntegrationSystem.AurusPayStoredValue:
      case IntegrationSystem.ChaseStoredValue:
      case IntegrationSystem.EigenStoredValue:
      case IntegrationSystem.FreedomPayKioskStoredValue:
      case IntegrationSystem.ParPayStoredValue:
      case IntegrationSystem.PinataPayNativeStoredValue:
      case IntegrationSystem.WorldpayStoredValue:
        return true;
      case IntegrationSystem.Atrium:
        // On the kiosk a physical card needs to be swiped because students might know each other's
        // student IDs (and hence Atrium card numbers)
        if (
          [OrderChannel.DriveThru, OrderChannel.Kiosk].includes(gcn.location.get('orderChannel'))
        ) {
          return true;
        }
        // On a linebuster, a staff member is entering the number so we will only use a native
        // reader if we have one connected to the kiosk.
        return !!gcn.kiosk?.get('hasMsr');
      case IntegrationSystem.Givex:
      case IntegrationSystem.OloStoredValue:
      case IntegrationSystem.PaytronixGift:
      case IntegrationSystem.PinataPayStoredValue:
      case IntegrationSystem.PinataStoredValue:
      case IntegrationSystem.Tcc:
      case IntegrationSystem.Valutec:
      case IntegrationSystem.Cbord:
        if (gcn.kiosk?.get('hasMsr')) {
          return true;
        }
        return false;
    }
  }
}

export default StoredValueHelper;
