import { IonButton, IonCol, IonIcon, IonItem, IonLabel, IonRow } from '@ionic/react';
import { createOutline, heart, trashBinOutline } from 'ionicons/icons';
import * as React from 'react';

import { Log } from '@biteinc/common';
import type { Order, OrderedMod, OrderedPriceOption, UIOrderedItem } from '@biteinc/core-react';
import { Strings } from '@biteinc/localization';

import { useLocalize } from '~/app/js/localization/localization';
import type { KioskMenuAddonSet } from '~/types';

import GcnFlashMaitredClient from '../app/js/gcn_flash_maitred_client';
import GcnHtml from '../app/js/gcn_html';
import { AuthService } from '../services';
import { useLocation, useStore } from '../stores';

interface OrderedItemProps {
  index: number;
  orderedItem: UIOrderedItem;
  showEditDeleteButtons?: true;
  showFavoriteButton?: true;
  order?: Order;
}

export function OrderedItemComponent(props: OrderedItemProps): JSX.Element {
  const str = useLocalize();

  const displayDefaultModsInCart = gcn.menu.settings.get('displayDefaultModsInCart');
  const displayZeroDollarModsInCart = gcn.menu.settings.get('displayZeroDollarModsInCart');
  const displayDeselectedModsInCart = gcn.menu.settings.get('displayDeselectedModsInCart');
  const displayModCodesInCart = gcn.menu.settings.get('displayModCodesInCart');

  // This entire logic is made to mimic gcn_ordered_item_view but may not do so 1 to 1
  // because we just don't have quite the same data here.
  // https://github.com/biteinc/maitred/blob/6f879ff219b8e080b89dc2ba79c30bc7872aeb54/packages/gcn/src/app/js/views/gcn_ordered_item_view.js#L114
  const modNames = getModsFromPriceOption(props.orderedItem.priceOption)
    .filter(({ mod, modRef, isSelected }) => {
      if (
        isSelected &&
        !displayDefaultModsInCart &&
        modRef.selectedByDefaultQuantity &&
        modRef.selectedByDefaultQuantity <= mod.priceOption.quantity
      ) {
        return false;
      }
      if (!displayZeroDollarModsInCart && modRef.price === 0) {
        return false;
      }
      if (!displayDeselectedModsInCart && !isSelected) {
        return false;
      }
      return true;
    })
    .map(({ mod, modRef, isSelected }) => {
      let subModPrefix = '';
      if (displayModCodesInCart && modRef.autoSelectedSubMods?.length) {
        subModPrefix = modRef.autoSelectedSubMods
          .map((subMod) => {
            return subMod.name;
          })
          .join(', ');
      }
      // Add the space
      if (subModPrefix) {
        subModPrefix += ' ';
      }

      const displayName = isSelected ? mod.name : str(Strings.DESELECTED_MOD_PREFIX, [mod.name]);
      return { displayName: `${subModPrefix}${displayName}`, isSelected };
    });

  const selectedModNames = modNames
    .filter(({ isSelected }) => !!isSelected)
    .map(({ displayName }) => displayName);

  const deselectedModNames = displayDeselectedModsInCart
    ? modNames.filter(({ isSelected }) => !isSelected).map(({ displayName }) => displayName)
    : [];

  return (
    <IonItem className="ordered-item">
      <IonLabel>
        <IonRow className="item-row">
          <IonCol size="12">
            <IonRow className="item-row">
              <IonCol>
                <p className="name">
                  <span className="quantity">{props.orderedItem.priceOption.quantity}× </span>
                  {props.orderedItem.name}
                </p>
              </IonCol>
              <IonCol size="auto">
                <p className="price">{`$${GcnHtml.stringFromPrice(props.orderedItem.total)}`}</p>
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol size={props.showEditDeleteButtons ? '12' : '10'}>
            {props.orderedItem.priceOption.name ? (
              <p className="modifier">{props.orderedItem.priceOption.name}</p>
            ) : null}
            {selectedModNames.length ? (
              <p className="modifier">{selectedModNames.join(', ')}</p>
            ) : null}
            {deselectedModNames.length ? (
              <p className="modifier deselected">{deselectedModNames.join(', ')}</p>
            ) : null}
            {props.orderedItem.specialRequest ? (
              <p className="special-request">{props.orderedItem.specialRequest}</p>
            ) : null}
          </IonCol>
          <FavoriteButton {...props} />
          <EditDeleteButtons {...props} />
        </IonRow>
      </IonLabel>
    </IonItem>
  );
}

function EditDeleteButtons(props: {
  showEditDeleteButtons?: boolean;

  orderedItem: UIOrderedItem;
}): JSX.Element | null {
  const editItem = useStore((state) => state.cart.editOrderedItem);
  const removeItem = useStore((state) => state.cart.removeOrderedItem);

  if (!props.showEditDeleteButtons) {
    return null;
  }
  return (
    <IonCol size="12">
      <IonRow className="item-row">
        <IonCol>
          <IonButton
            className="edit-item-button simple-icon-button"
            fill="clear"
            size="small"
            onClick={() => {
              // eslint-disable-next-line no-underscore-dangle
              editItem(props.orderedItem._uid);
            }}
          >
            <IonIcon icon={createOutline} />
          </IonButton>
          <IonButton
            className="remove-item-button simple-icon-button"
            fill="clear"
            size="small"
            onClick={() => {
              // eslint-disable-next-line no-underscore-dangle
              removeItem(props.orderedItem._uid);
            }}
          >
            <IonIcon icon={trashBinOutline} />
          </IonButton>
        </IonCol>
      </IonRow>
    </IonCol>
  );
}

function FavoriteButton(props: {
  showFavoriteButton?: true;
  order?: Order;
  index: number;
  orderedItem: UIOrderedItem;
}): JSX.Element | null {
  const str = useLocalize();
  const [isFavorite, setIsFavorite] = React.useState(false);
  const location = useLocation();

  if (!props.showFavoriteButton) {
    return null;
  }

  function saveToFavorites(): void {
    if (!GcnFlashMaitredClient.getCustomerToken()) {
      AuthService.promptSignup(location, {
        order: props.order!,
        favoriteItemIndex: props.index,
      });
      return;
    }
    const payload = {
      locationId: location._id,
      orderedItem: props.orderedItem,
    };
    gcn.maitred.postCustomerResource('favorites', payload, (err /* data */) => {
      if (err) {
        Log.error('Err', err);
      } else {
        setIsFavorite(true);
      }
    });
  }

  const btnText = isFavorite
    ? str(Strings.ADDED_TO_FAVORITES_WEB)
    : str(Strings.ADD_TO_FAVORITES_WEB);
  const iconSlot = btnText ? 'end' : 'icon-only';
  return (
    <IonCol
      size="2"
      className="favorite-button-container"
    >
      <IonButton
        className={`favorite-button simple-icon-button ${isFavorite ? 'selected' : ''}`}
        fill="clear"
        size="small"
        disabled={isFavorite}
        {...(btnText && { expand: 'block' })}
        onClick={() => {
          saveToFavorites();
        }}
      >
        {btnText || undefined}
        <IonIcon
          slot={iconSlot}
          icon={heart}
        />
      </IonButton>
    </IonCol>
  );
}

type ModWithRef = {
  mod: OrderedMod;
  modRef: KioskMenuAddonSet['items'][0];
  isSelected: boolean;
};

function getModsFromPriceOption(priceOption: OrderedPriceOption): ModWithRef[] {
  const mods: ModWithRef[] = [];
  priceOption.addonSets?.forEach((addonSet) => {
    const modRefById = gcn.menu
      .getAddonSetWithId(addonSet._id)
      .get('items')
      .reduce(
        (acc, item) => {
          acc[item._id] = item;
          return acc;
        },
        {} as Record<string, KioskMenuAddonSet['items'][0]>,
      );

    addonSet.items?.forEach((item) => {
      mods.push({
        mod: item,
        modRef: modRefById[item._id],
        isSelected: true,
      });
      mods.push(...getModsFromPriceOption(item.priceOption));
    });

    addonSet.deselectedItems?.forEach((item) => {
      mods.push({
        mod: item,
        modRef: modRefById[item._id],
        isSelected: false,
      });
    });
  });
  return mods;
}
