import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { str } from '~/app/js/localization/localization';

import { GCNView } from './gcn_view';

type Options = {
  currentGuestNumber: number | undefined;
};

export const GCNGroupRecipientNumberView = GCNView.extend({
  className: 'group-recipient-view',
  attributes: {
    id: 'group-recipient-view',
  },
  template: _.template('<div class="title"></div><div  class="group-guests-container"></div>'),

  initialize(options: Options) {
    this.value = options.currentGuestNumber ?? undefined;
  },

  getValue() {
    return this.value;
  },

  clearInvalidState() {
    this.$el.removeClass('invalid');
  },

  validate() {
    if (!this.value) {
      this.$el.addClass('invalid');
      return false;
    }

    this.$el.removeClass('invalid');
    return true;
  },

  makeGuestButton(guestNumber: number, defaultToSelected: boolean) {
    const guestLabel = str(Strings.GROUP_ORDERING_GUEST_NUMBER_PREFIX, [guestNumber.toString()]);

    const $button = $(
      `<button class="button group-guest ${guestNumber === this.value || defaultToSelected ? 'selected' : ''}">${guestLabel}</button>`,
    );
    $button.onButtonTapOrHold('grnv', () => {
      this.value = guestNumber;
      this.trigger('change', this.value);

      this.clearInvalidState();

      // set the selected class and remove it from the other buttons
      this.$groupGuestNumbersContainer.find('.group-guest').removeClass('selected');
      $button.addClass('selected');
    });

    return $button;
  },

  render() {
    this.$el.html(this.template({}));
    this.$groupGuestNumbersContainer = this.$('.group-guests-container');
    this.$title = this.$('.title');

    this.$title.htmlOrText(str(Strings.GROUP_ORDERING_LABEL));

    const guestNumberLabels = gcn.orderManager.getDistinctGroupOrderGuestLabels();
    const guestNumbers = guestNumberLabels.map((label) => parseInt(label.match(/\d+/g)![0], 10));

    guestNumbers.forEach((guestLabel) => {
      const $button = this.makeGuestButton(guestLabel, false);
      this.$groupGuestNumbersContainer.append($button);
    });

    if (
      gcn.orderManager.getOrderedItems().length >= 1 &&
      gcn.orderManager.getOrderedItems().length >= guestNumberLabels.length
    ) {
      this.$groupGuestNumbersContainer.append(
        `<button class="button new-group-guest">${str(Strings.ADD_NEW)}</button>`,
      );

      const $addNewButton = this.$groupGuestNumbersContainer.find('.new-group-guest');
      $addNewButton.onButtonTapOrHold('grnvAddNew', () => {
        // next guest number should be the first available number not taken already
        let nextGuestNumber = 1;
        while (guestNumbers.includes(nextGuestNumber)) {
          nextGuestNumber += 1;
        }

        // remove the add new guest button
        this.$groupGuestNumbersContainer.find('.new-group-guest').remove();

        // default to this new guest number
        this.value = nextGuestNumber;
        this.$groupGuestNumbersContainer.find('.group-guest').removeClass('selected');

        const $button = this.makeGuestButton(nextGuestNumber, true);
        this.$groupGuestNumbersContainer.append($button);
      });
    }
    return this;
  },
});
