import _ from 'underscore';

import { Validators } from '@biteinc/common';
import { BitePlatform } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import ScreenReaderHelper from '../screen_reader_helper';
import Analytics from '../utils/analytics';
import LocalStorage from '../utils/local_storage';
import { GCNView } from './gcn_view';

export const GCNGuestEmailEntryView = GCNView.extend({
  className: 'order-destination-view',

  template: _.template(`
    <div class="guest-name">
      <div class="back-button"></div>
      <div class="header" role="heading" aria-level="1" tabindex="-1"></div>
      <div class="body">
        <input class="guest-email-input" type="email" autocomplete="off" autocorrect="off" <%= androidAttributes %> />
      </div>
      <div class="button-container">
        <div class="skip-button"></div>
        <div class="done-button"></div>
      </div>
      <div class="blocked-word-warning-container">
        <p class="guest-name-input-validation-failed hidden"></p>
      </div>
      <div class="footer">
        <div class="footnote"></div>
      </div>
    </div>
  `),

  initialize(options = {}, ...args) {
    GCNView.prototype.initialize.apply(this, [...args]);

    this.customerIdentifierIsOptional = options.customerIdentifierIsOptional;

    this.addRefreshOnLanguageChangeSubscription();
  },

  _renderBackButton() {
    this.$('.back-button').htmlOrText(localizeStr(Strings.BACK));
    this.$('.back-button').onButtonTapOrHold('gnevBack', () => {
      this.trigger(BackboneEvents.GCNTextWhenReadyView.BackedOut);
    });
  },

  _enableDoneButton(enable) {
    this.$doneButton.toggleClass('disabled', !enable);
  },

  _submitGuestEmail(email) {
    Analytics.track(Analytics.EventName.EmailSubmitted);
    gcn.orderManager.setGuestEmail(email);
    this.trigger(BackboneEvents.GCNTextWhenReadyView.CompletedFlow);
  },

  blur() {
    if (this.$guestEmailInput) {
      this.$guestEmailInput.blur();
    }
    setTimeout(() => {
      // TODO: This causes an occasional janky hiding animation when pressing
      // the back button. Consider finding a way to avoid calling this if the
      // text field is focused but the keyboard is NOT shown, as doing that
      // shows the keyboard.
      gcn.notifyUserDidFocusOutOfTextField();
    }, 1);
  },

  _validateEmail(inputValue) {
    const isEmailValidated = Validators.isEmail(inputValue.toLowerCase());
    if (!isEmailValidated) {
      this.$inputValidationFailed.toggleClass('hidden', false);
    }
    return isEmailValidated;
  },

  _setDoneButtonText(text) {
    this.$doneButton.htmlOrText(text);
    this.$doneButton.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(text));
  },

  _setSkipButtonText(text) {
    this.$skipButton.htmlOrText(text);
    this.$skipButton.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(text));
  },

  _handleDoneButtonClick() {
    if (!this.$doneButton.hasClass('disabled') && this._validateGuestEmail()) {
      const guestEmail = this.$guestEmailInput.val();
      this._submitGuestEmail(guestEmail);
    }
    this.blur();
  },

  _handleSkipButtonClick() {
    Analytics.track(Analytics.EventName.EmailSkipped);
    this.trigger(BackboneEvents.GCNTextWhenReadyView.CompletedFlow);
  },

  _validateGuestEmail() {
    this.$inputValidationFailed.toggleClass('hidden', true);

    const guestEmailInput = this.$guestEmailInput.val();
    this.$guestEmailInput.toggleClass('validation-failed', false);

    const validated = this._validateEmail(guestEmailInput);
    if (!validated) {
      this.$guestEmailInput.toggleClass('validation-failed', true);
    }

    this._enableDoneButton(validated);
    return validated;
  },

  render() {
    const androidAttributes =
      window.platform === BitePlatform.KioskAndroid ? 'inputmode="text"' : '';
    this.$el.html(this.template({ androidAttributes: androidAttributes }));

    this.$inputValidationFailed = this.$('.guest-name-input-validation-failed');
    this.$inputValidationFailed.htmlOrText(localizeStr(Strings.ENTER_YOUR_EMAIL_VALIDATION_FAILED));

    this.$skipButton = this.$('.skip-button');
    this.$doneButton = this.$('.done-button');
    this.$guestEmailInput = this.$('.guest-email-input');

    if (this.customerIdentifierIsOptional) {
      this._setSkipButtonText(localizeStr(Strings.SKIP));
      this.$skipButton.onButtonTapOrHold(
        'guestEmailSkipButton',
        this._handleSkipButtonClick.bind(this),
      );
    } else {
      this.$skipButton.hide();
    }

    this._setDoneButtonText(localizeStr(Strings.DONE));
    this.$doneButton.onButtonTapOrHold(
      'guestEmailDoneButton',
      this._handleDoneButtonClick.bind(this),
    );
    this.$guestEmailInput.focus(() => {
      gcn.notifyUserDidFocusOnTextField();
    });

    this.$guestEmailInput.focus();
    this.$guestEmailInput.keyup((e) => {
      if (!this._validateGuestEmail()) {
        return;
      }
      if (e.keyCode === 13) {
        const guestEmailInput = this.$guestEmailInput.val();
        this._handleDoneButtonClick(guestEmailInput);
      }
      return;
    });

    this.$('.header:first').htmlOrText(localizeStr(Strings.ENTER_EMAIL_CUSTOMER_IDENTIFIER));
    if (gcn.screenReaderIsActive) {
      this.$('.header').requestFocusAfterDelay();
    }
    this.$('.footnote').htmlOrText(localizeStr(Strings.FULFILLMENT_INSTRUCTION_GUEST_EMAIL));

    this._renderBackButton();

    const storedGuestEmail = LocalStorage.getItem('guestEmail');
    if (storedGuestEmail) {
      this.$guestEmailInput.val(storedGuestEmail);
      this._enableDoneButton(!!storedGuestEmail);
    } else {
      this._enableDoneButton(false);
    }

    if (gcn.screenReaderIsActive) {
      setTimeout(() => {
        this._submitGuestEmail('screen-reader-user@getbite.com');
      }, 100);
    }

    return this;
  },
});
