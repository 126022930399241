import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

// UI representing a single reward.
export const GCNRewardView = GCNView.extend({
  className: 'reward-view font-body border-color-spot-1',
  _template: _.template(
    // prettier-ignore
    '<div class="details font-body">' +
      '<div class="name font-body"></div>' +
      '<div class="description font-body"></div>' +
    '</div>' +
    '<span role="button" class="reward-claim-button"></span>',
  ),

  setClaimed(claimed) {
    this._claimed = claimed;
  },

  // Prevent the reward from being clicked.
  setDisabled() {
    this.$el.toggleClass('disabled', true);
  },

  render() {
    const self = this;

    this.$el.html(this._template());
    this.$('.name').html(this.model.displayName());
    this.$('.description').html(this.model.get('description'));

    const notCurrentlyRedeemable = this.model.get('notCurrentlyRedeemable');

    // TODO: Localize strings.
    const $applyButton = this.$('.reward-claim-button');
    if (notCurrentlyRedeemable) {
      $applyButton.addClass('bg-color-spot-1 claimed');
      $applyButton.htmlOrText(`${localizeStr(Strings.LOYALTY_REWARD_INVALID)}`);
      this.$el.addClass('disabled');
    } else if (this._claimed) {
      $applyButton.addClass('bg-color-spot-1 claimed');
      $applyButton.htmlOrText(`✓ ${localizeStr(Strings.LOYALTY_REWARD_APPLIED)}`);
    } else {
      $applyButton.addClass('color-spot-1 border-color-spot-1');
      $applyButton.text(`${localizeStr(Strings.LOYALTY_ACCOUNT_APPLY)}`);
    }

    $applyButton.onButtonTapOrHold('rewardApply', () => {
      if (self.$el.hasClass('disabled')) {
        return;
      }
      if (self._claimed) {
        Analytics.track(Analytics.EventName.RewardRemove);
        gcn.loyaltyManager.removeReward(self.model);
      } else {
        Analytics.track(Analytics.EventName.RewardApply);
        gcn.loyaltyManager.applyReward(self.model);
      }
    });

    return this;
  },
});
