import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { str } from '~/app/js/localization/localization';

import { GCNGroupRecipientGuestNameModalView } from './gcn_group_recipient_guest_name_modal_view';
import { GCNView } from './gcn_view';

type Options = {
  currentGuestName: string;
};

export const GCNGroupRecipientNameView = GCNView.extend({
  className: 'group-recipient-view',
  attributes: {
    id: 'group-recipient-view',
  },
  template: _.template('<div class="title"></div><div  class="group-guests-container"></div>'),

  initialize(options: Options) {
    this.value = options.currentGuestName ?? '';
  },

  getValue() {
    return this.value;
  },

  clearInvalidState() {
    this.$el.removeClass('invalid');
  },

  validate() {
    if (!this.value) {
      this.$el.addClass('invalid');
      return false;
    }

    this.$el.removeClass('invalid');
    return true;
  },

  makeGuestButton(guest: string, defaultToSelected: boolean) {
    const $button = $(
      `<button class="button group-guest ${guest === this.value || defaultToSelected ? 'selected' : ''}">${guest}</button>`,
    );
    $button.onButtonTapOrHold('grnv', () => {
      this.value = guest;
      this.trigger('change', this.value);

      this.clearInvalidState();

      // set the selected class and remove it from the other buttons
      this.$groupGuestNumbersContainer.find('.group-guest').removeClass('selected');
      $button.addClass('selected');
    });

    return $button;
  },

  render() {
    this.$el.html(this.template({}));
    this.$groupGuestNumbersContainer = this.$('.group-guests-container');
    this.$title = this.$('.title');

    this.$title.htmlOrText(str(Strings.GROUP_ORDERING_LABEL));

    const guestNames = gcn.orderManager.getDistinctGroupOrderGuestLabels();

    guestNames.forEach((guest) => {
      const $button = this.makeGuestButton(guest, false);
      this.$groupGuestNumbersContainer.append($button);
    });

    if (
      gcn.orderManager.getOrderedItems().length >= 1 &&
      gcn.orderManager.getOrderedItems().length >= guestNames.length
    ) {
      this.$groupGuestNumbersContainer.append(
        `<button class="button new-group-guest">${str(Strings.ADD_NEW)}</button>`,
      );

      const $addNewButton = this.$groupGuestNumbersContainer.find('.new-group-guest');
      const self = this;
      $addNewButton.onButtonTapOrHold('grnvAddNew', () => {
        const guestNameEntryModal = new GCNGroupRecipientGuestNameModalView({
          onDone: (guestName: string) => {
            // remove the add new guest button
            self.$groupGuestNumbersContainer.find('.new-group-guest').remove();

            // default to this new guest number
            self.$groupGuestNumbersContainer.find('.group-guest').removeClass('selected');

            self.value = guestName;
            const $button = self.makeGuestButton(guestName, true);
            self.$groupGuestNumbersContainer.append($button);

            gcn.menuView.dismissPopup();
          },
        });
        gcn.menuView.showPopup(guestNameEntryModal, undefined, 'centered');
      });
    }
    return this;
  },
});
