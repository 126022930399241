import _ from 'underscore';

import { Log } from '@biteinc/common';
import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNView } from './gcn_view';

export const GCNScannerToastView = GCNView.extend({
  className: 'scanner-toast-view',
  template: _.template(
    // prettier-ignore
    '<div class="image"></div>' +
    '<div class="text">' +
      '<div class="name"><%= itemName %></div>' +
      '<div class="label "><%= label %></div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, options ? [options, ...args] : args);

    this.header = options.header;
    this.body = options.body;
    this.model = options.model;
  },

  render() {
    Log.debug({
      msg: 'GCNScannerToastView render',
      model: this.model,
      header: this.header,
      body: this.body,
    });
    const html = this.model
      ? this.template({
          itemName: this.model.displayName(),
          label: localizeStr(Strings.ADDED_TO_ORDER),
        })
      : this.template({
          itemName: this.header,
          label: this.body,
        });
    this.$el.html(html);

    const $image = this.$('.image');
    const images = this.model?.get('images');
    if (_.size(images)) {
      const image = images[images.length - 1];
      gcn.requestImageByUrlAndWidth(image.url, image.width, (err, imgPath) => {
        $image.css('background-image', `url(${imgPath})`);
      });
    } else {
      $image.hide();
    }

    return this;
  },
});
