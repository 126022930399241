import _ from 'underscore';

import { GroupOrderingNameType } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNView } from './gcn_view';

const GroupOrderingByNumberImageUrl = '';
const GroupOrderingByNameImageUrl = '';

type Options = {
  onDone: () => void;
};

export const GCNGroupOrderPromptView = GCNView.extend({
  className: 'group-order-prompt-view',

  template: _.template(`
    <div class="body">
      <div class="title"></div>
      <div class="subtitle"></div>
      <div class="image"></div>
      <div class="button-container">
        <button class="button button-primary button-large" data-action="group-ordering" data-value="no">
        </button>
        <button class="button button-primary button-large" data-action="group-ordering" data-value="yes">
        </button>
      </div>
    </div>
  `),

  initialize(options: Options, ...args: any) {
    GCNView.prototype.initialize.apply(this, options ? [options, ...args] : args);

    if (options?.onDone) {
      this.onDone = options.onDone;
    }
  },

  render() {
    this.$el.html(this.template());

    this.$title = this.$('.title');
    this.$subtitle = this.$('.subtitle');
    this.$yesButton = this.$('.button-container button[data-value="yes"]');
    this.$noButton = this.$('.button-container button[data-value="no"]');

    this.$title.htmlOrText(localizeStr(Strings.GROUP_ORDERING_TITLE));
    this.$subtitle.htmlOrText(localizeStr(Strings.GROUP_ORDERING_SUBTITLE));
    this.$yesButton.htmlOrText(localizeStr(Strings.YES));
    this.$noButton.htmlOrText(localizeStr(Strings.NO));

    const imageUrl =
      gcn.menu.settings.get('groupOrderingNameType') === GroupOrderingNameType.Name
        ? GroupOrderingByNameImageUrl
        : GroupOrderingByNumberImageUrl;

    gcn.requestImageByUrl(imageUrl, (err, imgPath) => {
      this.$el.css('background-image', `url(${imgPath})`);
    });

    this.$yesButton.onButtonTapOrHold('gopYes', () => {
      gcn.orderManager.setGroupOrderingModeActive(true);

      this.onDone();
    });

    this.$noButton.onButtonTapOrHold('gopNo', () => {
      gcn.orderManager.setGroupOrderingModeActive(false);

      this.onDone();
    });

    return this;
  },
});
