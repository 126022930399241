import $ from 'jquery';
import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

export const GCNGuestSurveyView = GCNView.extend({
  className: 'guest-survey-view',
  _template: _.template(
    // prettier-ignore
    '<div class="header font-body"><%= headerText %></div>' +
    '<div class="buttons"></div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._options = options || {};
    this._selectedRating = false;
  },

  _createSubmitRatingFunction(surveyValue, $button) {
    if (this._selectedRating) {
      return;
    }

    this._selectedRating = true;
    this.$el.addClass('completed');
    $button.addClass('selected');
    switch (surveyValue) {
      case 0:
        this.$('.buttons .confirmation').htmlOrText(localizeStr(Strings.GUEST_RATING_0));
        break;
      case 1:
        this.$('.buttons .confirmation').htmlOrText(localizeStr(Strings.GUEST_RATING_1));
        break;
      case 2:
        this.$('.buttons .confirmation').htmlOrText(localizeStr(Strings.GUEST_RATING_2));
        break;
      case 3:
        this.$('.buttons .confirmation').htmlOrText(localizeStr(Strings.GUEST_RATING_3));
        break;
    }

    if (this._options.callback) {
      this._options.callback(surveyValue);
    }
  },

  animateIn() {
    const buttons = this.$buttons.find('.button');
    buttons.css('transform', 'scale(0)');
    setTimeout(() => {
      buttons.each((i, buttonEl) => {
        $(buttonEl).css('transition', `transform 0.4s ease-in-out ${0.3 + i * 0.075}s`);
        $(buttonEl).css('transform', 'scale(1)');
      });
    }, 1);
  },

  render() {
    this.$el.html(
      this._template({
        headerText: localizeStr(Strings.HOW_DID_WE_DO),
      }),
    );

    this.$buttons = this.$('.buttons');

    // Lowest to highest rating.
    const RATINGSIZE = 4;
    Array.from(Array(RATINGSIZE).keys()).forEach((i) => {
      const ratingLevel = i + 1;
      const $button = $(`<div class="button rating-${ratingLevel}"></div>`);
      $button.onButtonTapOrHold(`gsv${ratingLevel}`, () => {
        Analytics.trackEvent({
          eventName: Analytics.EventName.GuestSurveyValueSubmitted,
          eventData: {
            value: ratingLevel,
          },
        });
        this._createSubmitRatingFunction(i, $button);
      });
      this.$buttons.append($button);
    });
    this.$buttons.append('<div class="confirmation"></div>');

    return this;
  },
});
