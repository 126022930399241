import * as Sentry from '@sentry/browser';

import { ErrorCode, Log, PaymentCode } from '@biteinc/common';
import type { Order, Transaction } from '@biteinc/core-react';
import { StringHelper, TimeHelper } from '@biteinc/core-react';
import {
  FlashBridgeMessage,
  GarconBridgeMessage,
  OrderClientEventName,
  OrderSkipReason,
  PaymentsApiVersion,
  PrinterDestination,
  TransactionResult,
  TransactionUserAction,
} from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import type { GcnTransaction } from '~/types';
import type { GcnOrderedItem } from '~/types/gcn_ordered_item';

import { GcnPaymentHelper } from '../../helpers';
import type { GcnOrder } from '../../types/gcn_order';
import MobileAppMessageType from '../../types/mobile_app_message_type';
import type { GcnError } from './gcn_bridge_interface';
import GcnHardwareManager from './gcn_hardware_manager';
import type { CloseOrderResponse, GetOrderResponse, KdsJob } from './gcn_maitred_client';
import OrderHelper from './gcn_order_helper';
import { GCNRouterHelper } from './gcn_router_helper';
import { I9nManager } from './i9ns/i9n_manager';
import { localizeStr } from './localization/localization';
import { GCNTransaction } from './models/gcn_transaction';
import Analytics from './utils/analytics';
import { sleep } from './utils/promises';
import { GCNAlertView } from './views/gcn_alert_view';

module OrderSender {
  enum OrderFlowState {
    FailedToGetOrder = 'failed_to_get_order',
    FlashOrderFailed = 'flash_order_failed',
    LocalPrinting = 'local_printing',
    LocalPrintingDone = 'local_printing_done',
    LocalPrintingFailed = 'local_printing_failed',
    OrderAbandoned = 'order_abandoned',
    OrderComplete = 'order_complete',
    ProcessingPaymentFailed = 'processing_payment_failed',
    SendingOrder = 'sending_order',
    SendingOrderFailed = 'sending_order_failed',
    SendingOrderFailedOutOfSync = 'sending_order_failed_out_of_sync',
    SendingOrderSuccess = 'sending_order_success',
    TransactionCancelled = 'transaction_cancelled',
    WaitingForCard = 'waiting_for_card',
  }

  async function performDemoPayment(
    currentOrder: GcnOrder,
    chargeTotal: number,
  ): Promise<Transaction> {
    const transaction = GCNTransaction.demoPayTransactionWithAmount(
      chargeTotal,
      currentOrder.get('clientId'),
      PaymentsApiVersion.V2,
      gcn.location.get('orgId'),
      gcn.location.id,
      gcn.kiosk?.id, // Will be undefined for kiosk preview
    );

    await captureTransactionOnMaitred(currentOrder, transaction);

    return transaction;
  }

  async function performPaymentFromKiosk(
    currentOrder: GcnOrder,
    chargeTotal: number,
  ): Promise<Transaction> {
    const transaction = await gcn.bridge.sendAsync<any>({
      event: FlashBridgeMessage.MAKE_PAYMENT,
      amountInCents: chargeTotal,
      order: currentOrder.attributes,
    });

    await captureTransactionOnMaitred(currentOrder, transaction);

    return transaction;
  }

  async function performPaymentFromMaitred(): Promise<Transaction> {
    const result = await gcn.maitred.createKioskApiPaymentRequest({ ebtMode: null });
    return result.transaction;
  }

  export async function cancelPaymentFromMaitred(): Promise<boolean> {
    const result = await gcn.maitred.cancelKioskApiPaymentRequest();
    return result.success;
  }

  async function captureTransactionOnMaitred(
    currentOrder: GcnOrder,
    transaction: any,
  ): Promise<void> {
    await gcn.maitred.createKioskPaymentRequest({
      orderId: currentOrder.id,
      transaction,
    });
    // @todo - remove this check once all api driven payment terminal integrations are stable
    // if (!window.isGarcon) {
    gcn.bridge.send({
      event: FlashBridgeMessage.SENT_PAYMENT_TO_MAITRED,
      clientId: transaction.clientId,
    });
    // }
  }

  function setOrderFlowState(state: OrderFlowState, order?: any, error?: any): void {
    gcn.handleBridgeMessage({
      updatePaymentFlowState: state,
      ...(order && { order }),
      ...(error && { error }),
    });

    if (state === OrderFlowState.OrderComplete) {
      gcn.mobileAppBridge?.send(MobileAppMessageType.OrderClosed, {
        orderId: order?._id,
      });
    }
  }

  function shouldAbortSending(errorCode: number): boolean {
    return [
      ErrorCode.RequestKeyDataMissing,
      ErrorCode.PaymentEcommError,
      PaymentCode.PaymentCardCallForAuthorization,
      PaymentCode.PaymentCardCvvInvalid,
      PaymentCode.PaymentCardExpired,
      PaymentCode.PaymentCardNumberInvalid,
      PaymentCode.PaymentCardZipInvalid,
      PaymentCode.PaymentDeclined,
      PaymentCode.PaymentDeclinedGeneric,
      PaymentCode.PaymentDoNotHonor,
      PaymentCode.PaymentInsufficientFunds,
      PaymentCode.PaymentIntegrationInvalid,
      PaymentCode.PaymentIntegrationInvalidCredentials,
      PaymentCode.PaymentLiveCardUsedInTest,
      PaymentCode.PaymentProcessingError,
      PaymentCode.PaymentRestrictedCard,
      PaymentCode.PaymentTokenError,
      PaymentCode.PaymentTransactionNotPermitted,
      PaymentCode.PaymentUnhandledError,
      ErrorCode.OrderValidationWhenClosed,
      ErrorCode.FutureOrderValidationWhenNotEnabled,
      ErrorCode.FutureOrderValidationWhenSlotExpired,
      ErrorCode.FutureOrderValidationWhenSlotFarOut,
      ErrorCode.FutureOrderValidationWhenSlotInvalid,
      ErrorCode.FutureOrderValidationWhenLeadTimeExceedsSlot,
      ErrorCode.POSValidationItem86d,
      ErrorCode.POSValidationMod86d,
      ErrorCode.POSValidationPriceOption86d,
      ErrorCode.POSValidationRuleRequirementUnmet,
      ErrorCode.OrderValidationOrderedItemsUnavailableInMenu,
      ErrorCode.StoredValueCardDeclined,
    ].includes(errorCode);
  }

  async function closeFlashOrderWithRetries(
    orderId: string,
    orderPayload: Record<string, any>,
    timeout: number,
    startedAt: number,
  ): Promise<any> {
    try {
      if (!gcn.location.useOrdersApiV2()) {
        const sendOrderResponse = await gcn.maitred.sendOrder(orderPayload, timeout);
        return sendOrderResponse;
      }

      const closeOrderResponse = await gcn.maitred.closeOrderRequest({
        timeout,
        startedAt,
        code: ErrorCode.SendOrderUITimeoutWithPayment,
        message: 'Send order UI time out',
      });
      return closeOrderResponse;
    } catch (err) {
      // order already closed if our close request timed out
      const orderAlreadyClosed =
        gcn.maitred.isMaitredApiError(err) && err.code === ErrorCode.OrderClosed;
      if (orderAlreadyClosed) {
        try {
          const getOrderResponse = await gcn.maitred.getOrderRequest(orderId);
          return getOrderResponse;
        } catch {
          // best effort
        }
        // fall through and rely on idempotency
      }

      if (shouldAbortSending(err.code)) {
        throw err;
      }

      if (Date.now() - startedAt >= timeout) {
        throw {
          code: ErrorCode.SendOrderUITimeoutWithPayment,
          message: 'Send order UI time out',
        };
      }
    }

    await sleep(1000);
    return closeFlashOrderWithRetries(orderId, orderPayload, timeout, startedAt);
  }

  export async function sendFlashOrder(
    currentOrder: GcnOrder,
    orderPayload: Record<string, any>,
    orderUpdatePayload: Record<string, any>,
    hasEcommPayment: boolean,
    skipSettingOrderFlowStateSending?: true,
  ): Promise<void> {
    const orderId = currentOrder.id;
    if (!orderPayload.order.clientId) {
      orderPayload.order.clientId = StringHelper.newMongoId();
    }

    // Skip this so we don't hide the spinner
    if (!skipSettingOrderFlowStateSending) {
      setOrderFlowState(OrderFlowState.SendingOrder);
    }

    // Always surface the errors from these calls to the user
    if (gcn.location.useOrdersApiV2()) {
      try {
        await gcn.maitred.updateOrderRequest(orderUpdatePayload);

        // Create flash payment
        if (hasEcommPayment) {
          if (skipSettingOrderFlowStateSending) {
            gcn.orderManager.eventRepo.track(OrderClientEventName.PaymentCreditCardStart);
            Analytics.track(Analytics.EventName.CreditCardPaymentStart);
          }

          await gcn.maitred.createEcommPaymentRequest();

          if (skipSettingOrderFlowStateSending) {
            gcn.orderManager.eventRepo.track(OrderClientEventName.PaymentCreditCardEnd);
            Analytics.track(Analytics.EventName.CreditCardPaymentComplete);
          }
        }
      } catch (err) {
        setOrderFlowState(OrderFlowState.FlashOrderFailed, undefined, err);
        Sentry.captureMessage(err.message, {
          extra: {
            order: orderPayload.order,
            error: err,
          },
        });
        return;
      }
    }

    let response: { order: Order; successfulTransactions: Transaction[] };
    try {
      if (skipSettingOrderFlowStateSending) {
        // Since we aren't setting the order flow state to sending-order, we have to track the event
        gcn.orderManager.eventRepo.track(OrderClientEventName.OrderSendStart);
        Analytics.track(Analytics.EventName.OrderSendStart);
      }

      const orderSubmitTimeout = gcn.location.get('orderSubmitTimeout');

      response = await closeFlashOrderWithRetries(
        orderId,
        orderPayload,
        gcn.orderManager.getOrder()!.isSentImmediately()
          ? orderSubmitTimeout
          : TimeHelper.MINUTE * 2,
        Date.now(),
      );

      const orderJson = {
        ...response.order,
        transactions: response.successfulTransactions,
      };
      setOrderFlowState(OrderFlowState.SendingOrderSuccess, orderJson);
      setOrderFlowState(OrderFlowState.OrderComplete, orderJson);
      // Update hash for GA tracking
      GCNRouterHelper.navToOrderConfirmation();
    } catch (err) {
      Sentry.captureMessage(err.message, {
        extra: {
          order: orderPayload.order,
          error: err,
        },
      });
      setOrderFlowState(OrderFlowState.SendingOrderFailed, undefined, err);
    }
  }

  async function takeKioskPayment(
    currentOrder: GcnOrder,
    chargeTotal: number,
  ): Promise<{ accepted: boolean; transaction?: GcnTransaction }> {
    const skippingPayment =
      !gcn.location.takesPayment() || gcn.orderManager.payingAtCashier() || chargeTotal === 0;

    if (skippingPayment) {
      return { accepted: true, transaction: undefined };
    }

    if (!window.isGarcon) {
      setOrderFlowState(OrderFlowState.WaitingForCard);
    }

    // Run transaction
    /**
     * @todo - move to bridge
     */
    let transaction: any;
    try {
      if (
        (window.isGarcon || gcn.location.usesApiForTerminalPayments()) &&
        !window.hasPaymentTerminal
      ) {
        transaction = await performDemoPayment(currentOrder, chargeTotal);
        /**
         * @todo
         * Revert to checking schema once all api driven payment terminal integrations are stable
         */
      } else if (gcn.location.usesApiForTerminalPayments()) {
        transaction = await performPaymentFromMaitred();
      } else {
        transaction = await performPaymentFromKiosk(currentOrder, chargeTotal);
      }
    } catch (err) {
      Sentry.captureMessage(err.message, {
        extra: {
          order: currentOrder,
          transaction,
          error: err,
        },
      });
      setOrderFlowState(OrderFlowState.ProcessingPaymentFailed, undefined, err);
      return { accepted: false, transaction: undefined };
    }

    const cancelled = transaction.userAction === TransactionUserAction.Cancelled;
    const abandoned = transaction.userAction === TransactionUserAction.Abandoned;
    const approved = transaction.result === TransactionResult.Approved;

    if (cancelled) {
      setOrderFlowState(OrderFlowState.TransactionCancelled);
      return { accepted: false, transaction };
    }

    if (abandoned) {
      setOrderFlowState(OrderFlowState.OrderAbandoned);
      return { accepted: false, transaction };
    }

    if (!approved) {
      const shouldRetry = await new Promise((resolve) => {
        const canWeRetry = GcnPaymentHelper.canWeRetryKioskPayment(transaction.errorCode);

        const message = GcnPaymentHelper.getKioskPaymentErrorMessage(
          transaction.identifiedErrorCode,
          transaction.entryMode,
          transaction.cardSchemeId,
        )
          .split('\n')
          .join('<br />');

        let timeout: NodeJS.Timeout;

        const confirmView = new GCNAlertView({
          text: message,
          okText: localizeStr(Strings.OK),
          okCallback: () => {
            gcn.menuView.dismissModalPopup();
            clearTimeout(timeout);
            if (canWeRetry) {
              resolve(true);
            } else {
              setOrderFlowState(OrderFlowState.TransactionCancelled);
              resolve(false);
            }
          },
        });
        gcn.menuView.showModalPopup(confirmView);

        // We want a timeout without a cancel button, so we'll set this outside of the confirm view
        timeout = setTimeout(() => {
          gcn.menuView.dismissModalPopup();
          confirmView.destroy();
          resolve(false);
        }, 15000);
      });

      if (shouldRetry) {
        return takeKioskPayment(currentOrder, chargeTotal);
      }

      setOrderFlowState(OrderFlowState.TransactionCancelled);
      return { accepted: false, transaction };
    }

    return { accepted: true, transaction };
  }

  async function sendKdsJobSocketRequest(
    job: KdsJob,
    orderId: string,
    startedAt: number,
  ): Promise<void> {
    try {
      const address = `${job.kds.ipAddress}:${job.kds.port}`;
      const kdsResult = await gcn.bridge.sendAsync<{ success: boolean; response: string }>({
        event: FlashBridgeMessage.SOCKET_REQUEST,
        address,
        payload: job.payload,
      });
      Log.debug('KDS result', kdsResult);
      /**
       * We are only going to get an error here if we fail to connect to the KDS. The backend is
       * responsible for handling any errors that come from the KDS.
       * @todo - handle errors from the KDS in Tournant for full on-prem mode
       */
      if (!kdsResult.success) {
        throw Error('Failed to connect to KDS');
      }
      const sendAttemptResult = await gcn.maitred.postFulfillmentSendAttempt(orderId, {
        startedAt,
        response: {
          data: kdsResult.response,
          address,
        },
      });
      Log.debug('KDS send attempt result', sendAttemptResult);
    } catch (err) {
      Log.debug('KDS error', err);
    }
  }

  async function sendKdsJob(orderId: string): Promise<void> {
    if (!gcn.location.useOnPremKds()) {
      return;
    }
    const startedAt = Date.now();
    const result = await gcn.maitred.getKdsOrderPayload(orderId);
    Log.debug('KDS payload', result);
    /**
     * FRESH-KDS
     * - Items can be routed to specific devices based on stations
     * - the backend will generate all the necessary jobs for the devices
     *
     * LOGIC-CONTROLS
     * - Items are sent to a single device which acts as the primary router and controls
     * all routing logic
     */

    // Promise.all waits for all the kdsJobs to finish before continuing
    // fixes the problem of orders not closing
    await Promise.all(
      result.kdsJobs.map((job) => {
        return sendKdsJobSocketRequest(job, orderId, startedAt);
      }),
    );
  }

  async function sendKioskOrder(
    currentOrder: GcnOrder,
    orderUpdatePayload: Record<string, any>,
  ): Promise<{ order: GcnOrder }> {
    setOrderFlowState(OrderFlowState.SendingOrder);

    try {
      await gcn.maitred.updateOrderRequest(orderUpdatePayload);

      // Fulfillment methods
      await sendKdsJob(currentOrder.id);

      let closeOrderResponse: CloseOrderResponse;
      if (currentOrder.isSentImmediately()) {
        closeOrderResponse = await gcn.maitred.closeAsapKioskOrder();

        gcn.orderManager.setOrderFromJSON({
          ...closeOrderResponse.order,
          transactions: closeOrderResponse.successfulTransactions,
        });
      } else {
        closeOrderResponse = await gcn.maitred.closeOrderRequest({
          timeout: TimeHelper.SECOND * 45,
          startedAt: Date.now(),
          code: ErrorCode.NetworkRequestTimedOut,
          message: 'Order close request timed out',
        });
      }

      const order = {
        ...closeOrderResponse.order,
        transactions: closeOrderResponse.successfulTransactions,
      };

      setOrderFlowState(OrderFlowState.SendingOrderSuccess, order);

      const orderedItems: GcnOrderedItem[] = [];
      return OrderHelper.orderFromJson(order, orderedItems);
    } catch (err) {
      setOrderFlowState(OrderFlowState.SendingOrderFailed, undefined, err);
      throw err;
    }
  }

  /**
   * As of right now this function pretty much replicates the logic in sendKioskOrder. When we
   * add in all the order validation logic etc into gcn then this method will likely start to
   * send more bridge messages to the kiosk to handle the order flow.
   */
  async function sendOnPremKioskOrder(
    currentOrder: GcnOrder,
    orderUpdatePayload: Record<string, any>,
  ): Promise<{ order: GcnOrder }> {
    setOrderFlowState(OrderFlowState.SendingOrder);
    Log.debug('Sending on-prem order');
    try {
      await gcn.bridge.sendAsync<any>({
        event: GarconBridgeMessage.ON_PREM_REQUEST,
        onPremRequest: {
          action: 'updateOrder',
          body: {
            orderId: currentOrder.id,
            payload: {
              isOnPrem: true,
              ...orderUpdatePayload,
            },
          },
        },
      });

      // Fulfillment methods
      await sendKdsJob(currentOrder.id);

      let closeOrderResponse: CloseOrderResponse;
      if (currentOrder.isSentImmediately()) {
        closeOrderResponse = await gcn.maitred.closeAsapKioskOrder();

        gcn.orderManager.setOrderFromJSON({
          ...closeOrderResponse.order,
          transactions: closeOrderResponse.successfulTransactions,
        });
      } else {
        closeOrderResponse = await gcn.maitred.closeOrderRequest({
          timeout: TimeHelper.SECOND * 45,
          startedAt: Date.now(),
          code: ErrorCode.NetworkRequestTimedOut,
          message: 'Order close request timed out',
        });
      }

      const order = {
        ...closeOrderResponse.order,
        transactions: closeOrderResponse.successfulTransactions,
      };

      setOrderFlowState(OrderFlowState.SendingOrderSuccess, order);

      const orderedItems: GcnOrderedItem[] = [];
      return OrderHelper.orderFromJson(order, orderedItems);
    } catch (err) {
      setOrderFlowState(OrderFlowState.SendingOrderFailed, undefined, err);
      throw err;
    }
  }

  async function printGuestReceipt(
    updatedOrder: GcnOrder,
    sendKioskOrderErr?: GcnError,
  ): Promise<void> {
    setOrderFlowState(OrderFlowState.LocalPrinting);
    try {
      const printPayload = OrderHelper.getPrintPayload(updatedOrder, sendKioskOrderErr);
      printPayload.printJobs = printPayload.printJobs.filter((printJob) => {
        return [PrinterDestination.Receipt, PrinterDestination.Test].includes(printJob.destination);
      });

      const printResult = await gcn.bridge.sendAsync<{ error?: string }>({
        event: FlashBridgeMessage.PRINT_RECEIPT,
        printPayload,
      });

      if (printResult.error) {
        setOrderFlowState(OrderFlowState.LocalPrintingFailed);
        return;
      }

      setOrderFlowState(OrderFlowState.LocalPrintingDone);
    } catch {
      setOrderFlowState(OrderFlowState.LocalPrintingFailed);
    }
  }

  function handleOrderOutOfSync(clientOrder: GcnOrder, message: string): void {
    Sentry.captureMessage(message, {
      extra: {
        order: clientOrder,
      },
    });
    setOrderFlowState(OrderFlowState.SendingOrderFailedOutOfSync);
  }

  function handleFailedToGetOrder(clientOrder: GcnOrder): void {
    Sentry.captureMessage('Failed to get order from server for ensureOrderIsInSyncWithServer', {
      extra: {
        order: clientOrder,
      },
    });
    setOrderFlowState(OrderFlowState.FailedToGetOrder);
  }

  async function ensureOrderIsInSyncWithServer(
    clientOrder: GcnOrder,
    serverOrder: GetOrderResponse,
  ): Promise<boolean> {
    if (serverOrder.order.lockExpiresAt) {
      // If the order is locked on the server, then there is some kind of operation taking place
      handleOrderOutOfSync(clientOrder, 'Order is locked.');
      return false;
    }

    if (
      JSON.stringify(serverOrder.order.appliedRewards) !==
      JSON.stringify(clientOrder.attributes.appliedRewards)
    ) {
      handleOrderOutOfSync(clientOrder, 'Order has unexpected applied rewards.');
      return false;
    }

    if (
      JSON.stringify(serverOrder.order.appliedCompCards) !==
      JSON.stringify(clientOrder.attributes.appliedCompCards)
    ) {
      handleOrderOutOfSync(clientOrder, 'Order has unexpected applied comp cards.');
      return false;
    }

    if (
      JSON.stringify(serverOrder.order.appliedLoyaltyCoupons) !==
      JSON.stringify(clientOrder.attributes.appliedLoyaltyCoupons)
    ) {
      handleOrderOutOfSync(clientOrder, 'Order has unexpected loyalty coupons.');
      return false;
    }

    if (
      JSON.stringify(serverOrder.order.appliedPosCoupons) !==
      JSON.stringify(clientOrder.attributes.appliedPosCoupons)
    ) {
      handleOrderOutOfSync(clientOrder, 'Order has unexpected POS coupons.');
      return false;
    }

    if (
      JSON.stringify(serverOrder.order.appliedBiteCoupons) !==
      JSON.stringify(clientOrder.attributes.appliedBiteCoupons)
    ) {
      handleOrderOutOfSync(clientOrder, 'Order has unexpected Bite coupons.');
      return false;
    }

    if (serverOrder.order.tipTotal !== clientOrder.attributes.tipTotal) {
      handleOrderOutOfSync(clientOrder, 'Order has unexpected tip total.');
      return false;
    }

    if (serverOrder.order.discountTotal !== clientOrder.attributes.discountTotal) {
      handleOrderOutOfSync(clientOrder, 'Order has unexpected discount total.');
      return false;
    }

    if (serverOrder.order.total !== clientOrder.attributes.total) {
      // If the totals don't match, then something has gone terribly wrong
      handleOrderOutOfSync(clientOrder, 'Order has unexpected totals.');
      return false;
    }

    return true;
  }

  export async function startOrderFlow(
    orderPayload: Record<string, any>,
    orderUpdatePayload: Record<string, any>,
    currentOrder: GcnOrder,
    hasEcommPayment: boolean,
    checkoutSession: any,
    chargeTotal: number,
  ): Promise<void> {
    // Only check if there is is an order on the server to be in sync with
    if (currentOrder.isOrdersApiV2OrV3()) {
      let serverOrder: GetOrderResponse;
      try {
        serverOrder = await gcn.maitred.getOrderRequest(currentOrder.id);
      } catch {
        handleFailedToGetOrder(currentOrder);
        return;
      }

      if (!(await ensureOrderIsInSyncWithServer(currentOrder, serverOrder))) {
        return;
      }
    }

    if (window.offlineDemoMode) {
      checkoutSession.orderPayload.wasSentToPos = Date.now();
      checkoutSession.orderPayload.wasSentForFulfillment = true;
      checkoutSession.orderPayload.sendingToPosWasSkipped = OrderSkipReason.OfflineDemoMode;
    }

    if (window.isFlash) {
      await sendFlashOrder(currentOrder, orderPayload, orderUpdatePayload, hasEcommPayment);
    } else if (!gcn.location.useOrdersApiV2()) {
      // Only use the bridge for sending orders for Kiosks on V1
      // We'll leave any sort of retry logic up to the Kiosk, so we'll only call this once,
      // unlike the v1 method for Flash
      gcn.bridge.send({
        event: FlashBridgeMessage.SEND_ORDER,
        order: checkoutSession,
        checkoutSession,
      });
    } else {
      const { accepted: successfulPayment } = await takeKioskPayment(currentOrder, chargeTotal);
      if (!successfulPayment) {
        return;
      }

      let sendKioskOrderErr: GcnError | undefined;
      let updatedOrder: GcnOrder | undefined;
      try {
        if (gcn.kiosk?.get('useOnPremOrdering')) {
          Log.debug('Sending on-prem order', orderUpdatePayload);
          updatedOrder = (await sendOnPremKioskOrder(currentOrder, orderUpdatePayload)).order;
        } else {
          updatedOrder = (await sendKioskOrder(currentOrder, orderUpdatePayload)).order;
        }

        setOrderFlowState(OrderFlowState.OrderComplete);
        gcn.bridge.send({
          event: FlashBridgeMessage.ORDER_COMPLETED,
          guestId: checkoutSession.orderPayload2.order.guestId,
        });

        // Let the I9nManager handle errors itself
        I9nManager.sendOrder(updatedOrder).catch(() => {});
      } catch (err) {
        // No need to setOrderFlowState set to error state in sendKioskOrder
        Sentry.captureMessage(err.message, {
          extra: {
            order: checkoutSession.orderPayload,
            error: err,
          },
        });
        sendKioskOrderErr = err;
      }
      if (GcnHardwareManager.hasReceiptPrinter()) {
        await printGuestReceipt(updatedOrder || currentOrder, sendKioskOrderErr);
      }
    }
  }
}

export default OrderSender;
