/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonButton, IonCol, IonRow } from '@ionic/react';
import React from 'react';

import type { FormProps, FormState, Schema } from '@biteinc/core-react';
import { BaseFormComponent } from '@biteinc/core-react';
import { Strings } from '@biteinc/localization';

import { str } from '~/app/js/localization/localization';

interface GenericFormProps<Data> extends FormProps<Data> {
  submit: (data: any) => void;
  schema: Schema;
  data?: Data;
  submitButtonText?: string;
}

export default class GenericForm<Data> extends BaseFormComponent<
  Data,
  GenericFormProps<Data>,
  FormState<Data>
> {
  constructor(props: GenericFormProps<Data>) {
    super(props);
    this.schema = props.schema;
    this.state = {
      data: props.data || ({} as Data),
      formValid: false,
    };
  }

  submit(ev: any): void {
    ev?.preventDefault();
    const { isValid, message } = this.isFormValid();
    if (isValid) {
      this.props.submit(this.state.data);
    }
    this.setState({
      errorMessage: message,
    });
  }

  componentDidMount(): void {
    this.setFormValidity();
  }

  render(): React.ReactNode {
    return (
      <form
        className="ion-padding generic-form"
        onSubmit={this.submit.bind(this)}
      >
        <IonRow>
          <IonCol>{this.generateForm()}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            size="6"
            offset="3"
          >
            <IonButton
              expand="block"
              disabled={!this.state.formValid}
              onClick={this.submit.bind(this)}
            >
              {this.props.submitButtonText || str(Strings.SUBMIT)}
            </IonButton>
            {
              // Ionic React Webcomponents hack
              <input
                type="submit"
                className="hidden-input"
              />
            }
          </IonCol>
        </IonRow>
      </form>
    );
  }
}
