import _ from 'underscore';

import { TimeHelper } from '@biteinc/core-react';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import GcnHardwareManager from '../gcn_hardware_manager';
import GcnHelper from '../gcn_helper';
import Analytics from '../utils/analytics';
import { GCNTransactionCellView } from './gcn_transaction_cell_view';
import { GCNView } from './gcn_view';

export const GCNOrderCellView = GCNView.extend({
  className: 'order-cell-view shadow-z-1',
  template: _.template(
    // prettier-ignore
    '<div class="btn receipt-button"></div>' +
    '<div class="created-at">Created On: <%= timestamp %></div>' +
    '<% if (cancelledAtTimestamp) { %>' +
      '<div class="cancelled-at">Cancelled On: <%= cancelledAtTimestamp %></div>' +
    '<% } %>' +
    '<div class="order-number">Order Number: <%= orderNumber %></div>' +
    '<% if (guestName) { %>' +
      '<div class="guest-name">Guest Name: <%= guestName %></div>' +
    '<% } %>' +
    '<% if (tableNumber) { %>' +
      '<div class="table-number">Table Number: <%= tableNumber %></div>' +
    '<% } %>' +
    '<div class="order-client-id">Order Reference: <%= clientOrderId %></div>' +
    '<div class="items">Items: <%= orderedItems %></div>' +
    '<div class="totals">' +
      '<span class="sub-total">Sub Total: <%= subTotal %></span>' +
      '<span class="tax-total">Tax Total: <%= taxTotal %></span>' +
      '<% if (discountTotal) { %>' +
        '<span class="discount-total">Discounts: <%= discountTotal %></span>' +
      '<% } %>' +
      '<% if (tipTotal) { %>' +
        '<span class="tip-total">Tip: <%= tipTotal %></span>' +
      '<% } %>' +
      '<% if (serviceChargeTotal) { %>' +
        '<span class="tip-total">Service Charge: <%= serviceChargeTotal %></span>' +
      '<% } %>' +
      '<span class="total">Grand Total: <%= total %></span>' +
    '</div>' +
    '<% if (isCancellable) { %>' +
      '<div class="cancel-order-controls">' +
      '<div class="cancel-order-button btn">CANCEL ORDER</div>' +
    '</div>' +
    '<% } %>' +
    '<div class="transactions"></div>' +
    '<div class="error-label"><%= error %></div>',
  ),
  _transactionCellViews: null,

  initialize(...args) {
    GCNView.prototype.initialize.apply(this, args);

    this._error = null;

    this.listenTo(this.model, 'change', this.render);
  },

  blur() {
    _.each(this._transactionCellViews, (transactionCellView) => {
      transactionCellView.blur();
    });
  },

  setError(error) {
    this._error = error;
    this.render();
  },

  _refundButtonWasTapped(transactionCellView, refundAmount) {
    this.setError(null);
    const transaction = transactionCellView.model;
    this.trigger(
      BackboneEvents.GCNOrderCellView.RefundButtonWasTapped,
      this,
      transaction,
      refundAmount,
    );
  },

  _cancelOrderButtonWasTapped() {
    this.setError(null);
    this.trigger(BackboneEvents.GCNOrderCellView.CancelOrderButtonWasTapped, this);
  },

  render() {
    const order = this.model;

    const orderedItemNames = _.map(order.orderedItems, (orderedItem) => {
      return `${orderedItem.get('priceOption').quantity}× ${orderedItem.get('name')}`;
    });
    const orderedItemsText = orderedItemNames.join(', ');

    let cancelledAtTimestamp;
    if (order.isCancelled()) {
      cancelledAtTimestamp = TimeHelper.format(order.get('cancelledAt'), 'MM/DD/YY h:mma');
    }

    this.$el.html(
      this.template({
        orderNumber: order.get('clientNumber'),
        tableNumber: order.get('tableNumber'),
        isCancellable: order.isCancellable(),
        guestName: order.get('guestName'),
        clientOrderId: order.get('clientId'),
        orderedItems: orderedItemsText,
        subTotal: `$${GcnHelper.stringFromTotal(order.get('subTotal'))}`,
        taxTotal: `$${GcnHelper.stringFromTotal(order.get('taxTotal'))}`,
        discountTotal:
          order.get('discountTotal') >= 0 && order.getDiscountNames().length
            ? `$${GcnHelper.stringFromTotal(order.get('discountTotal'))}`
            : null,
        tipTotal:
          order.get('tipTotal') > 0 ? `$${GcnHelper.stringFromTotal(order.get('tipTotal'))}` : null,
        serviceChargeTotal:
          order.get('serviceChargeTotal') > 0
            ? `$${GcnHelper.stringFromTotal(order.get('serviceChargeTotal'))}`
            : null,
        total: `$${GcnHelper.stringFromTotal(order.get('total'))}`,
        timestamp: TimeHelper.format(order.get('createdAt'), 'MM/DD/YY h:mma'),
        cancelledAtTimestamp,
        error: this._error || '',
      }),
    );

    const $transactionsContainer = this.$('.transactions');

    this.$('.cancel-order-button').click(this._cancelOrderButtonWasTapped.bind(this));

    const self = this;
    this._transactionCellViews = _.map(order.transactions, (transaction) => {
      const cellView = new GCNTransactionCellView({ model: transaction, order });
      self.listenTo(
        cellView,
        BackboneEvents.GCNTransactionCellView.RefundButtonWasTapped,
        self._refundButtonWasTapped,
      );
      $transactionsContainer.append(cellView.render().$el);
      return cellView;
    });

    const $printReceiptButton = this.$('.receipt-button');
    $printReceiptButton.htmlOrText(localizeStr(Strings.PRINT_RECEIPT));
    if (!GcnHardwareManager.hasReceiptPrinter()) {
      $printReceiptButton.remove();
    } else {
      $printReceiptButton.onButtonTapOrHold('ocvPrint', () => {
        Analytics.track(Analytics.EventName.AdminViewPrintReceiptTapped);
        self.trigger(BackboneEvents.GCNOrderCellView.ReceiptButtonWasTapped, self);
        return false;
      });
    }

    return this;
  },
});
