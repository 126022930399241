import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import ScreenReaderHelper from '../screen_reader_helper';
import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

export const GCNQuantitySelectionView = GCNView.extend({
  className: 'quantity-selection-view',
  template: _.template(
    // prettier-ignore
    '<div class="quantity-selector-label"></div>' +
    '<div class="adjust-button minus" role="text" aria-label="subtract">–</div>' +
      '<% if (hasInputQuantity) { %>' +
        '<input class="quantity" type="number"/>' +
      '<% } else { %>' +
        '<div class="quantity"></div>' +
      '<% } %>' +
    '<div class="adjust-button plus" role="text" aria-label="add">+</div>',
  ),

  // This class operates in one of two modes. Either it manages it's value itself, or an external
  // controller managers it's value.
  // Options:
  // - hasExternalController: Set to true if there is an external controller that will listen for
  // DecrementPressed/IncrementPressed events and set the value itself with setValue().
  // - upperLimit: Set to a value if you want to set a max number this can be freely incremented
  // to. Defaults to 99. Note that it may be desirable to set hasExternalController and manage the
  // upper limit externally if there is more sophisticated logic involved.
  // - displayMultiplier: A number to multiply this by, which can be a fraction (ex: 0.25) or a
  // positive while number (ex: 5). Note that this only changes the displayed value, and the
  // getter and setter methods on this class still handles whole number counts (i.e. the caller
  // needs to multiply the getter result by the displayMultiplier again if that is the true final
  // value they need).
  // - displaySuffix: Text to append after the displayed number.
  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._lowerLimit =
      options && Object.prototype.hasOwnProperty.call(options, 'lowerLimit')
        ? options.lowerLimit
        : 1;
    this._internalValue = this._lowerLimit;
    this._hasExternalController = (options || {}).hasExternalController;
    this._showLabel = !!options?.showLabel;
    this._upperLimit = (options || {}).upperLimit || 99;
    this._displayMultiplier = (options || {}).displayMultiplier || 1;
    this._displaySuffix = (options || {}).displaySuffix || '';
    this._hasInputQuantity =
      ((options || {}).hasInputQuantity && !this._hasExternalController) || false;
    this._currentQuantity = (options || {}).currentQuantity || this._internalValue;
    this._menuItemName = options?.menuItemName || '';
  },

  setAriaLabel($element, text, isDisabled) {
    $element.attr(
      'aria-label',
      ScreenReaderHelper.prepareAriaLabel(`${text}${isDisabled ? ', disabled' : ''}`),
    );
  },

  // This method both validates and updates the value as well as the UI.
  setValue(val) {
    this._internalValue = val;
    if (this._hasInputQuantity) {
      this.$numberEl.val(val === 0 ? '' : val * this._displayMultiplier);
    } else {
      this.$numberEl.text((val * this._displayMultiplier).toString() + this._displaySuffix);
    }

    // Update UI - disabled and enabled buttons.
    const isMinusButtonDisabled = val <= this._lowerLimit || !val;
    const isPlusButtonDisabled = val >= this._upperLimit;
    this.$minusButton.toggleClass('disabled', isMinusButtonDisabled);
    this.$plusButton.toggleClass('disabled', isPlusButtonDisabled);
    this.setAriaLabel(
      this.$minusButton,
      `decrease quantity, current quantity is ${this.$('.quantity').text()}`,
      isMinusButtonDisabled,
    );
    this.setAriaLabel(
      this.$plusButton,
      `increase quantity, current quantity is ${this.$('.quantity').text()}`,
      isPlusButtonDisabled,
    );

    this.trigger(BackboneEvents.GCNQuantitySelectionView.QuantityChanged, this);
  },

  // Returns the currently set value as a number.
  getValue() {
    return this._internalValue;
  },

  render() {
    this.$el.html(this.template({ hasInputQuantity: this._hasInputQuantity }));
    this.$numberEl = this.$('.quantity');
    this.$minusButton = this.$('.adjust-button.minus');
    this.$plusButton = this.$('.adjust-button.plus');

    if (this._showLabel) {
      this.$('.quantity-selector-label').text(localizeStr(Strings.QUANTITY_SELECT_LABEL));
    }

    this.setValue(this._currentQuantity);

    this.$minusButton.onTapInScrollableAreaWithCalibration('qsv_minus', () => {
      if (this.$minusButton.hasClass('disabled')) {
        gcn.showNativeToast(`Unable to decrease quantity. Current quantity is ${this.getValue()}`);
        return false;
      }
      if (this._hasExternalController) {
        this.trigger(BackboneEvents.GCNQuantitySelectionView.DecrementPressed, this);
        return false;
      }
      Analytics.trackEvent({
        eventName: Analytics.EventName.MenuItemQuantityUpdated,
        eventData: {
          itemName: this._menuItemName,
          quantity: this.getValue() - 1,
        },
      });
      this.setValue(this.getValue() - 1);
      return false;
    });
    this.$plusButton.onTapInScrollableAreaWithCalibration('qsv_plus', () => {
      if (this.$plusButton.hasClass('disabled')) {
        gcn.showNativeToast(`Unable to increase quantity. Current quantity is ${this.getValue()}`);
        return false;
      }
      if (this._hasExternalController) {
        this.trigger(BackboneEvents.GCNQuantitySelectionView.IncrementPressed, this);
        return false;
      }

      Analytics.trackEvent({
        eventName: Analytics.EventName.MenuItemQuantityUpdated,
        eventData: {
          itemName: this._menuItemName,
          quantity: this.getValue() ? this.getValue() + 1 : 1,
        },
      });
      this.setValue(this.getValue() ? this.getValue() + 1 : 1);
      return false;
    });

    this.$el.onTapInScrollableAreaWithCalibration('quantity_control', () => {
      // this swallows all the events - used when the $minusButton or $plusButton
      // are disabled, and we don't want the event to bubble to the parent components
    });

    if (this._hasInputQuantity) {
      this.$numberEl.attr({
        max: this._upperLimit,
        min: 1,
      });

      this.$numberEl.on('input', (e) => {
        // Restrict input to numbers
        let validValue = e.target.value.replace(/[^0-9]/g, '');

        // Max quantity input to the number of characters of the upper limit
        if (validValue.length > this._upperLimit.toString().length) {
          validValue = validValue.slice(0, Number(this._upperLimit.toString().length));
        }
        this.setValue(Number(validValue));
      });

      this.$numberEl.on('focusout', () => {
        // If we focus out of a blank quantity input, reset to the default quantity
        if (!this.getValue()) {
          this.setValue(1);
        }
      });
    }

    return this;
  },
});
