import $ from 'jquery';
import _ from 'underscore';

import { Log } from '@biteinc/common';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import GcnHelper from '../gcn_helper';
import GcnHtml from '../gcn_html';
import { GCNOrderedItem } from '../models/gcn_ordered_item';
import Analytics from '../utils/analytics';
import { GCNDictionaryCardView } from './gcn_dictionary_card_view';
import { GCNFullScreenFlowView } from './gcn_full_screen_flow_view';
import { GCNOrderedItemView } from './gcn_ordered_item_view';
import { GCNQuantitySelectionView } from './gcn_quantity_selection_view';
import { GCNAddonSetStepView } from './step_views/gcn_addon_set_step_view';
import { GCNConfirmationStepView } from './step_views/gcn_confirmation_step_view';
import { GCNFullScreenFlowStepView } from './step_views/gcn_full_screen_flow_step_view';
import { GCNPriceOptionStepView } from './step_views/gcn_price_option_step_view';

export const GCNCustomizeFlowView = GCNFullScreenFlowView.extend({
  className: 'customize-flow-view',
  template: _.template(
    // prettier-ignore
    '<div class="details-card" aria-hidden="true">' +
      '<div class="font-body item-description"><%= itemDescGlossary %></div>' +
      '<% if(itemStoryGlossary) { %>' +
        '<div class="font-body item-story"><%= itemStoryGlossary  %></div>' +
      '<% } %>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNFullScreenFlowView.prototype.initialize.apply(this, [options, ...args]);

    const historyItem = gcn.guestManager.getOrderedItemWithId(options.item.id);
    if (historyItem) {
      this.orderedItem = historyItem.createCopy();
      this.isEditingPrevious = true;
    } else {
      this.isEditingPrevious = false;
    }
    if (!this.orderedItem) {
      this.orderedItem = new GCNOrderedItem(
        {},
        {
          item: options.item,
          section: options.section,
          upsellScreen: options.upsellScreen,
        },
      );
    }

    if (options.extras.weight) {
      // we set the weight and quantity of `1` if this menu item order view has weight
      this.model.setWeightAndQuantityOnSelectedPriceOption(options.extras.weight, 1);
    }

    if (options.extras.barcode) {
      // we set the barcode if barcode is received via `extras`
      this.model.setBarcode(options.extras.barcode);
    }

    this.fullScreenSeparateFlowView = gcn.menu.settings.get(
      'useSeparateScreenForEachModGroupInFullScreenFlow',
    );
    // linear stack for Depth First (Search) traversal of addons
    this.dfsStack = [];
    this.nestedShownCount = 0;

    this.steps.push(this._generateFirstStep());
  },

  // Clear DFS stack for (nested) children of a specific addon in an addon set.
  // The DFS stack is a set of mod groups which are created on the selection of a certain
  // (parent) addon in a certain (parent) addon set. Therefore we use both to identify the steps
  // that need to be removed from the stack once a parent node has been removed
  // and only removes items in a sequential series to avoid over deleting
  _clearStackWithParentId(removedParentAddonSetId, removedParentAddonId) {
    // Removes the step: if the parent addon set and addon (since these are nested mods, which
    // are created on the click of a certain mod in a set)
    let foundOne = false;
    let foundPrev = false;
    for (let i = this.dfsStack.length - 1; i >= 0 && (!foundOne || (foundOne && foundPrev)); i--) {
      const dfsStep = this.dfsStack[i];
      if (
        dfsStep.parentAddonSetId === removedParentAddonSetId &&
        (!removedParentAddonId || dfsStep.addonSetStep.parentAddonId === removedParentAddonId)
      ) {
        foundPrev = true;
        foundOne = true;
        this.dfsStack.splice(i, 1);
      } else {
        foundPrev = false;
      }
    }
  },

  /**
   * Returns an addon set step view to be added to DFS or directly to the steps
   * @param {*} addonSet the addon set to be created into a step
   * @param {*} parentId the ID of the parent addon set under which addonSet is nested
   * @param {*} genealogyArray a history of nested [modGroupId, modId, nestedModGrpId, nestedModId, ...]
   *            where the modId is the ID of the selected mod in the parent group.
   * @returns addon set step view
   */
  _createAddonSetStep(addonSet, parentId, genealogyArray) {
    const parentAddonId = _.last(genealogyArray);
    const addonSetStep = new GCNAddonSetStepView({
      parent: this,
      addonSet,
      orderedItem: this.orderedItem,
      ...(parentId && { parentId }),
      ...(parentAddonId && { parentAddonId }),
      ...(genealogyArray && { genealogyArray }),
      fullScreenSeparateFlowView: this.fullScreenSeparateFlowView,
      isEditingPrevious: this.isEditingPrevious,
    });
    this.listenTo(
      addonSetStep,
      BackboneEvents.GCNAddonSetPickerView.AddonErrorMessageShown,
      (errorMessage) => {
        if (!errorMessage?.length) {
          return;
        }
        GcnHelper.showWarningToastIfNotAnimated(
          addonSetStep.$warningToast,
          errorMessage,
          { bottom: '20%', opacity: 0.9 },
          { bottom: '0', opacity: 0 },
        );
      },
    );
    if (this.fullScreenSeparateFlowView) {
      this.listenTo(
        addonSetStep,
        BackboneEvents.GCNAddonSetPickerView.NestedPickerNewPageAdded,
        ({ nestedAddonSets, parentAddonSetId, addon, addonSetStepsGenealogy }) => {
          _.each(nestedAddonSets, (nestedAddonSet) => {
            const addonSetTempStep = this._createAddonSetStep(nestedAddonSet, parentAddonSetId, [
              ...addonSetStepsGenealogy,
              parentAddonSetId,
              addon.id,
            ]);
            this.dfsStack.push({
              parentAddonSetId,
              addonSetStep: addonSetTempStep,
              parentAddonId: addon.id,
            });
          });
        },
      );
      this.listenTo(
        addonSetStep,
        BackboneEvents.GCNAddonSetPickerView.NestedPickerNewPageRemoved,
        ({ removedParentAddonSetId, removedParentAddonId }) => {
          this._clearStackWithParentId(removedParentAddonSetId, removedParentAddonId);
        },
      );
    }
    return addonSetStep;
  },

  _createConfirmationStep() {
    this.$confirmationStepView = new GCNConfirmationStepView({ parent: this });
    this.listenTo(
      this.$confirmationStepView,
      BackboneEvents.GCNConfirmationStepView.QuickCheckoutButtonWasTapped,
      this._onQuickCheckoutButtonTapped,
    );
    this.listenTo(
      this._quantitySelectorView,
      BackboneEvents.GCNQuantitySelectionView.QuantityChanged,
      this._onQuantityChangedForConfirmationStep,
    );

    return this.$confirmationStepView;
  },

  _onQuantityChangedForConfirmationStep() {
    this.$confirmationStepView.setNextButtonTextAriaWithPrice(
      this._quantitySelectorView.getValue(),
    );
  },

  _onQuickCheckoutButtonTapped() {
    Analytics.trackEvent({
      eventName: Analytics.EventName.QuickCheckout,
      eventData: {
        source: 'customize_flow',
      },
    });
    this.quickCheckoutButtonTapped = true;
    this.nextStep(this.$confirmationStepView);
  },

  _generateFirstStep() {
    const pos = this.orderedItem.item.priceOptions;
    let firstStep;
    if (pos.length > 1) {
      firstStep = new GCNPriceOptionStepView({
        parent: this,
      });
    } else if (pos[0].addonSets.length) {
      firstStep = this._createAddonSetStep(pos[0].addonSets[0]);
    } else {
      firstStep = this._createConfirmationStep();
    }
    if (firstStep) {
      firstStep.prevButtonText = localizeStr(Strings.EXIT);
    }
    return firstStep;
  },

  _countTotalAddonSteps() {
    let count = 0;
    _.each(this.steps, (step) => {
      if (GCNFullScreenFlowStepView.StepType.AddonSet === step.stepType) {
        count++;
      }
    });
    return count - this.nestedShownCount;
  },

  // Based on the current state of things, return the next step to advance to,
  // or return undefined if there are no more steps.
  _generateNextStep() {
    const currentStep = _.last(this.steps);
    const addonSets = this.orderedItem.orderedPO.po.addonSets || [];
    switch (currentStep.stepType) {
      case GCNFullScreenFlowStepView.StepType.PriceOption:
        if (addonSets.length) {
          return this._createAddonSetStep(addonSets[0]);
        }
        return this._createConfirmationStep();
      case GCNFullScreenFlowStepView.StepType.AddonSet: {
        const currentAddonStepIndex = this._countTotalAddonSteps();
        if (currentAddonStepIndex < addonSets.length) {
          return this._createAddonSetStep(addonSets[currentAddonStepIndex]);
        }
        return this._createConfirmationStep();
      }
      default:
        return undefined;
    }
  },

  _finalizeOrder(orderedRecommendations, recommendationDisplayLocationDescription) {
    // this is the item that we need to track was added to the cart /* reco display location */
    gcn.orderManager.addToOrder(this.orderedItem, this._quantitySelectorView.getValue(), {
      // track recommendation from this view
      recommendationDisplayLocationDescription:
        this.options.extras.recommendationDisplayLocationDescription,
      // don't show toast if quick checkout button was tapped
      skipShowingToast: this.quickCheckoutButtonTapped,
    });

    _.each(orderedRecommendations, (orderedItem) => {
      // track recommendations from child views
      gcn.orderManager.addToOrder(orderedItem, orderedItem.orderedPO.get('quantity'), {
        recommendationDisplayLocationDescription,
      });
    });
  },

  nextStep(fromStep, ...args) {
    const currentStep = _.last(this.steps);
    if (fromStep !== currentStep) {
      Log.warn(
        'CustomizeFlow not going to advance from some random step',
        currentStep.stepType,
        fromStep.stepType,
      );
      return;
    }

    if (this.dfsStack.length) {
      const dfsStep = this.dfsStack.pop();
      const currentDfsStep = dfsStep.addonSetStep;
      this.nestedShownCount++;
      this.steps.push(currentDfsStep);
      this.$contents.append(currentDfsStep.render().$el);
      GCNFullScreenFlowView.prototype.nextStep.apply(this, [fromStep, ...args]);
      return;
    }

    const nextStep = this._generateNextStep();
    if (nextStep === undefined) {
      if (!this.exiting) {
        this.exiting = true;
        const lastStep = _.last(this.steps);
        const { orderedItems: orderedRecommendations, recommendationDisplayLocationDescription } =
          lastStep.getOrderedRecommendations();
        lastStep.animateOutOrderedItem(() => {
          this._finalizeOrder(orderedRecommendations, recommendationDisplayLocationDescription);
          if (this.quickCheckoutButtonTapped) {
            gcn.startCheckoutSequence();
          }
        });
        gcn.menuView.hideFullScreen();
        return;
      }
    } else if (nextStep.stepType === GCNFullScreenFlowStepView.StepType.Confirmation) {
      this.itemPreview.$el.fadeOut();
    }
    this.steps.push(nextStep);
    this.$contents.append(nextStep.render().$el);
    // If step is confirmation, we append the quantity selector.
    if (nextStep.stepType === GCNFullScreenFlowStepView.StepType.Confirmation) {
      this.$('.confirmation')
        .find('.buttons-right')
        .before(this._quantitySelectorView.render().$el);
    }

    GCNFullScreenFlowView.prototype.nextStep.apply(this, [fromStep, ...args]);
  },

  prevStep(...args) {
    if (this.steps.length === 1) {
      gcn.menuView.hideFullScreen();
      return;
    }

    if (_.last(this.steps).stepType === GCNFullScreenFlowStepView.StepType.Confirmation) {
      this.itemPreview.$el.delay(600).fadeIn(600);
    }

    // keep the last step to maintain DFS stack
    const lastStep = _.last(this.steps);
    // lastStep.addonSet can be undefined on the last step
    const removedStepAddonSetId = lastStep.addonSet?.id;
    const removedStepParentAddonSetId = lastStep.parentId;
    const removedStepAddonId = lastStep.parentAddonId;

    GCNFullScreenFlowView.prototype.prevStep.apply(this, args);

    if (removedStepAddonSetId) {
      // remove all dfs nodes that are rooted in last step that was removed
      this._clearStackWithParentId(removedStepAddonSetId, null);
    }

    /**
     * Sometimes we remove a step halfway through a nested flow. Take for instance the following
     * nested addon structure
     * . Small
     * |-> some addon set with addon options
     * . Large
     * |-> customize dressing
     * |-> customize salsa
     * |-> customize toppings
     *    |-> Add nuts
     *    |-> Add berries
     *
     * Assuming we are at the customize toppings step. If user selects previous, lastStep is
     * the customize toppings. This is removed from steps, and now the last index of step
     * is at the customize salsa step. In order for "next" to guide us to the right step, we must
     * push customize toppings to the DFS stack.
     * NB: Should put back in the DFS only if sibling or parent exists
     */
    let parentInStack = false;
    _.each(this.dfsStack, (dfsStep) => {
      parentInStack =
        parentInStack ||
        dfsStep.parentAddonSetId === removedStepParentAddonSetId ||
        dfsStep.addonSetStep.addonSet.id === removedStepParentAddonSetId;
    });
    _.each(this.steps, (step) => {
      parentInStack = parentInStack || step.addonSet?.id === removedStepParentAddonSetId;
    });
    if (removedStepParentAddonSetId && parentInStack) {
      this.nestedShownCount--;
      this.dfsStack.push({
        parentAddonSetId: removedStepParentAddonSetId,
        parentAddonId: removedStepAddonId,
        addonSetStep: lastStep,
      });
    }

    // Have to rerender for item description to show up on first step
    if (this.steps.length === 1) {
      this._renderItemDescription();
    }
  },

  _showGlossaryCard(glossaryWord, left, top, width, height) {
    if (!this._glossaryView) {
      this._glossaryView = new GCNDictionaryCardView({
        model: glossaryWord,
      });
    } else {
      this._glossaryView.updateModel(glossaryWord);
    }

    const css = {
      left,
      top: top + height,
    };
    gcn.menuView.showPopup(this._glossaryView, css);
  },

  _renderItemDescription() {
    const itemDescription = this.orderedItem.item.displayDescriptionHtml();
    const itemStory = this.orderedItem.item.displayStoryHtml();

    // Show item description/story on the first page only
    if (this.steps?.length === 1 && itemDescription) {
      // Support glossary cards for item descriptions
      const descGlossaryHtml = GcnHtml.glossaryTermsFromString(
        itemDescription,
        this._processedGlossaryDescriptionIdSet,
      );
      const storyGlossaryHtml = this.orderedItem.item.get('story')
        ? GcnHtml.glossaryTermsFromString(itemStory, this._processedGlossaryStoryIdSet)
        : undefined;
      const $description = this.template({
        itemDescGlossary: descGlossaryHtml,
        itemStoryGlossary: storyGlossaryHtml,
      });
      // Append description for showing on first step
      this.itemPreview.$el.append($description);

      // Add glossary card clickers for any and all words
      const $glossaryItems = this.itemPreview.$el.find('.dict-word');
      GcnHelper.addGlossaryTermClickers(
        $glossaryItems,
        (glossaryWord, left, top, width, height) => {
          this._showGlossaryCard(glossaryWord, left, top, width, height);
        },
      );
    }
  },

  render(...args) {
    this._$priceLabel = this.$el.find('.bottom-nav .price-label');
    this._processedGlossaryDescriptionIdSet = {};
    this._processedGlossaryStoryIdSet = {};
    GCNFullScreenFlowView.prototype.render.apply(this, args);

    const $container = $('<div class="preview-container"></div>');
    this.$el.append($container);
    this.itemPreview = new GCNOrderedItemView({
      model: this.orderedItem,
      showCalories: true,
      closeCallback: () => {
        gcn.menuView.hideFullScreen();
      },
    });
    this.itemPreview.listenTo(this.orderedItem, 'change', () => {
      this.itemPreview.render();
    });

    $container.append(this.itemPreview.render().$el);
    this._renderItemDescription();

    this._quantitySelectorView = new GCNQuantitySelectionView({
      showLabel: true,
      menuItemName: this.orderedItem.item.displayName(),
    });

    this.itemPreview.$el.attr('aria-hidden', 'true');

    return this;
  },
});
