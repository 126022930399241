import _ from 'underscore';

import { Strings } from '@biteinc/localization';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNView } from './gcn_view';

// Fancy header for loyalty program popup UIs.
export const GCNLoyaltyProgramHeaderView = GCNView.extend({
  className: 'loyalty-program-header-view',

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.isLoyaltySignUp = !!options?.isLoyaltySignUp;
  },

  template: _.template(
    // prettier-ignore
    '<div class="header bg-color-spot-1">' +
      '<div class="rewards-logo"></div>' +
      '<div class="rewards-title font-title"></div>' +
    '</div>',
  ),

  render() {
    const self = this;

    this.$el.html(this.template());

    this._$rewardsLogo = this.$('.rewards-logo');
    this.$('.rewards-title').htmlOrText(
      localizeStr(
        this.isLoyaltySignUp ? Strings.LOYALTY_SIGNUP_TITLE : Strings.LOYALTY_WELCOME_TITLE,
      ),
    );

    // Load program logo.
    const image = gcn.menu.getRewardsProgramLogoImage();
    if (image) {
      gcn.requestImageByUrl(image.url, (err, imgPath) => {
        if (!err) {
          self._$rewardsLogo.css('background-image', `url(${imgPath})`);
        }
      });
      this.$('.header').toggleClass('has-logo', true);
    } else {
      this._$rewardsLogo.remove();
    }

    return this;
  },
});
