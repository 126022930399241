import * as Sentry from '@sentry/browser';
import { createTransport } from '@sentry/core';
import type {
  BaseTransportOptions,
  StackFrame,
  Transport,
  TransportMakeRequestResponse,
  TransportRequest,
} from '@sentry/types';
import _ from 'underscore';

import { Log } from '@biteinc/common';
import { BiteLogDeviceEvent, BitePlatform, Environment, FlashBridgeMessage } from '@biteinc/enums';

import pkg from '../../../package.json';
import type {
  BridgeNetworkRequestErrorResponse,
  BridgeNetworkRequestSuccessResponse,
} from './gcn_bridge_interface';
import { ApiService } from './gcn_maitred_request_manager';

type BiteSentryTunnelResponse = Readonly<{
  statusCode: number;
  headers: Record<string, string | null> & {
    'x-sentry-rate-limits': string | null;
    'retry-after': string | null;
  };
  body: string;
}>;

function getSentryPlatform(): BitePlatform {
  return window.platform;
}

export default function initSentry(bridge: Bridge): void {
  const isKiosk = !window.isFlash && !window.isGarcon && !window.isKioskPreview;

  const sentrySerializedExtrasDepth = 10;
  Sentry.init({
    dsn: 'https://acc98051abea45efae678c9455ed4f89@sentry.io/132234',
    environment: window.env,
    debug: true,
    ignoreErrors: [
      // this error is benign according to the spec author:
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/58701523#comment86691361_49384120
      'ResizeObserver loop limit exceeded',
      // Caused by Google Analytics script, so out of out control.
      // It doesn't seem to affect the app in any way beyond analytics, though.
      "Cannot read properties of null (reading 'scrollLeft')",
      // Thrown in GcnOrderManager, but safe to ignore.
      /Ordered item not found with uid: \w+/,
    ],
    integrations: [
      Sentry.rewriteFramesIntegration({
        /**
         * @description Garcon (iOS) rewrites the filenames by:
         * 1. taking the "absoluteString" of the url (basically removes double slashes)
         * 2. hashing the string
         * 3. replacing the filename with the hash
         * We have to follow the same principle in order to tell sentry how to map the files
         * referenced in the crash to the ones in the sourcemap.
         */
        ...(window.platform === BitePlatform.KioskIos && {
          iteratee: (frame: StackFrame): StackFrame => {
            if (!frame.filename) {
              return frame;
            }
            try {
              const { jsFiles, jsVersion } = gcn.menu.get('presentation');
              const currentFilename = frame.filename.substring(frame.filename.lastIndexOf('/') + 1);
              const jsFile = jsFiles.find(({ url }) => {
                const garconUrl = url.replace('://', ':/').replace('.js', `.${jsVersion}.js`);
                const garconFilename = `${md5(garconUrl)}.js`;
                return currentFilename === garconFilename;
              });
              if (!jsFile) {
                return frame;
              }
              if (jsFile) {
                const jsFilename = jsFile.url.substring(jsFile.url.lastIndexOf('/') + 1);
                return {
                  ...frame,
                  filename: `file:///com.bite.kiosk-ios/gcn/${jsFilename}`,
                };
              }
            } catch (e) {
              Log.error('Error rewriting sentry frame', e);
              return frame;
            }
            return frame;
          },
        }),
      }),
      // When changing this value, make sure to update `normalizeDepth` of the whole SDK
      // to `depth + 1` in order to get it serialized properly - https://docs.sentry.io/platforms/javascript/configuration/options/#normalize-depth
      Sentry.extraErrorDataIntegration({ depth: sentrySerializedExtrasDepth }),
    ],
    normalizeDepth: sentrySerializedExtrasDepth + 1,
    release: `biteinc-gcn.${pkg.version}`,
    beforeSend: (event: Sentry.ErrorEvent, hint: Sentry.EventHint): Sentry.ErrorEvent => {
      const error = hint.originalException;
      const isError =
        _.isObject(error) &&
        error.code &&
        error.message &&
        (event.exception?.values || [])[0]?.type === 'Error';
      if (window.env === Environment.DEV) {
        Log.error(`JS ${isError ? 'ERROR' : 'CRASH'}`, event);
        Log.error('JS HINT', hint);
      }
      bridge.send({
        event: FlashBridgeMessage.DEVICE_GAZEBO_LOG,
        message: _.isString(error) ? error : (error as { message: string })?.message,
        sentryEventId: event.event_id,
        status: isError ? BiteLogDeviceEvent.GcnJsError : BiteLogDeviceEvent.GcnJsCrash,
        takeScreenshot: true,
      });

      return event;
    },
    // Ion element classes don't really tell us what was clicked, this function will give us
    // more info about the element that was clicked.
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const { target } = hint?.event || {};
        let message = '';
        if (target?.class) {
          message = target.class;
        }
        if (target?.ariaLabel) {
          message += `; ariaLabel: ${target.ariaLabel}`;
        }
        if (target?.id) {
          message += `; id: ${target.id}`;
        }
        if (target?.textContent) {
          message += `; text: ${target.textContent}`;
        }

        if (message) {
          breadcrumb.message = message;
        }
      }
      return breadcrumb;
    },
    // We have to tunnel sentry requests for kiosks since sentry blocks requests made from
    // Origin file://
    ...(isKiosk && {
      transport: function kioskTransport(options: BaseTransportOptions): Transport {
        return createTransport(
          options,
          (request: TransportRequest): PromiseLike<TransportMakeRequestResponse> => {
            return new Promise<TransportMakeRequestResponse>((resolve, reject) => {
              bridge.send<
                | BridgeNetworkRequestErrorResponse
                | BridgeNetworkRequestSuccessResponse<BiteSentryTunnelResponse>
              >(
                {
                  event: FlashBridgeMessage.MAITRED_REQUEST,
                  request: {
                    apiService: ApiService.Maitred,
                    method: 'POST',
                    path: '/sentry-tunnel',
                    createdAt: Date.now(),
                    requestId: '1',
                    body: {
                      url: options.url,
                      body: request.body,
                    },
                  },
                },
                (response) => {
                  if (response.success) {
                    resolve(response.data);
                  } else {
                    Log.error(
                      `Error while transporting Sentry event: ${JSON.stringify(response.error)}`,
                    );
                    reject(response.error);
                  }
                },
              );
            });
          },
        );
      },
    }),
    // In dev, use a fake transport so we don't actually send crashes to sentry but do
    // everything else like sending the log.
    // Setting Sentry's `enabled` flag to `false` would stop all processing and `beforeSend`
    // will never be executed, so we won't be able to test our own js-crash logs.
    ...(window.env === Environment.DEV && {
      transport: function devNullTransport(options: BaseTransportOptions): Transport {
        return createTransport(options, (/* request */) => {
          return new Promise((resolve) => {
            resolve({
              headers: {
                'x-sentry-rate-limits': null,
                'retry-after': null,
              },
            });
          });
        });
      },
    }),
  });
  Sentry.setTag('bite-platform', getSentryPlatform());
  if (gcn.location) {
    Sentry.setTag('locationId', gcn.location.id);
  }
}

/* eslint-disable no-bitwise */
function md5(inputString: string): string {
  const hc = '0123456789abcdef';
  function rh(n: number): string {
    let j;
    let s = '';
    for (j = 0; j <= 3; j++) {
      s += hc.charAt((n >> (j * 8 + 4)) & 0x0f) + hc.charAt((n >> (j * 8)) & 0x0f);
    }
    return s;
  }
  function ad(x: number, y: number): number {
    const l = (x & 0xffff) + (y & 0xffff);
    const m = (x >> 16) + (y >> 16) + (l >> 16);
    return (m << 16) | (l & 0xffff);
  }
  function rl(n: number, c: number): number {
    return (n << c) | (n >>> (32 - c));
  }
  function cm(q: number, a: number, b: number, x: number, s: number, t: number): number {
    return ad(rl(ad(ad(a, q), ad(x, t)), s), b);
  }
  function ff(a: number, b: number, c: number, d: number, x: number, s: number, t: number): number {
    return cm((b & c) | (~b & d), a, b, x, s, t);
  }
  function gg(a: number, b: number, c: number, d: number, x: number, s: number, t: number): number {
    return cm((b & d) | (c & ~d), a, b, x, s, t);
  }
  function hh(a: number, b: number, c: number, d: number, x: number, s: number, t: number): number {
    return cm(b ^ c ^ d, a, b, x, s, t);
  }
  function ii(a: number, b: number, c: number, d: number, x: number, s: number, t: number): number {
    return cm(c ^ (b | ~d), a, b, x, s, t);
  }
  function sb(x: string): number[] {
    let i;
    const nblk = ((x.length + 8) >> 6) + 1;
    const blks = new Array(nblk * 16);
    for (i = 0; i < nblk * 16; i++) {
      blks[i] = 0;
    }
    for (i = 0; i < x.length; i++) {
      blks[i >> 2] |= x.charCodeAt(i) << ((i % 4) * 8);
    }
    blks[i >> 2] |= 0x80 << ((i % 4) * 8);
    blks[nblk * 16 - 2] = x.length * 8;
    return blks;
  }
  let i;
  const x = sb(inputString);
  let a = 1732584193;
  let b = -271733879;
  let c = -1732584194;
  let d = 271733878;
  let olda;
  let oldb;
  let oldc;
  let oldd;
  for (i = 0; i < x.length; i += 16) {
    olda = a;
    oldb = b;
    oldc = c;
    oldd = d;
    a = ff(a, b, c, d, x[i + 0], 7, -680876936);
    d = ff(d, a, b, c, x[i + 1], 12, -389564586);
    c = ff(c, d, a, b, x[i + 2], 17, 606105819);
    b = ff(b, c, d, a, x[i + 3], 22, -1044525330);
    a = ff(a, b, c, d, x[i + 4], 7, -176418897);
    d = ff(d, a, b, c, x[i + 5], 12, 1200080426);
    c = ff(c, d, a, b, x[i + 6], 17, -1473231341);
    b = ff(b, c, d, a, x[i + 7], 22, -45705983);
    a = ff(a, b, c, d, x[i + 8], 7, 1770035416);
    d = ff(d, a, b, c, x[i + 9], 12, -1958414417);
    c = ff(c, d, a, b, x[i + 10], 17, -42063);
    b = ff(b, c, d, a, x[i + 11], 22, -1990404162);
    a = ff(a, b, c, d, x[i + 12], 7, 1804603682);
    d = ff(d, a, b, c, x[i + 13], 12, -40341101);
    c = ff(c, d, a, b, x[i + 14], 17, -1502002290);
    b = ff(b, c, d, a, x[i + 15], 22, 1236535329);
    a = gg(a, b, c, d, x[i + 1], 5, -165796510);
    d = gg(d, a, b, c, x[i + 6], 9, -1069501632);
    c = gg(c, d, a, b, x[i + 11], 14, 643717713);
    b = gg(b, c, d, a, x[i + 0], 20, -373897302);
    a = gg(a, b, c, d, x[i + 5], 5, -701558691);
    d = gg(d, a, b, c, x[i + 10], 9, 38016083);
    c = gg(c, d, a, b, x[i + 15], 14, -660478335);
    b = gg(b, c, d, a, x[i + 4], 20, -405537848);
    a = gg(a, b, c, d, x[i + 9], 5, 568446438);
    d = gg(d, a, b, c, x[i + 14], 9, -1019803690);
    c = gg(c, d, a, b, x[i + 3], 14, -187363961);
    b = gg(b, c, d, a, x[i + 8], 20, 1163531501);
    a = gg(a, b, c, d, x[i + 13], 5, -1444681467);
    d = gg(d, a, b, c, x[i + 2], 9, -51403784);
    c = gg(c, d, a, b, x[i + 7], 14, 1735328473);
    b = gg(b, c, d, a, x[i + 12], 20, -1926607734);
    a = hh(a, b, c, d, x[i + 5], 4, -378558);
    d = hh(d, a, b, c, x[i + 8], 11, -2022574463);
    c = hh(c, d, a, b, x[i + 11], 16, 1839030562);
    b = hh(b, c, d, a, x[i + 14], 23, -35309556);
    a = hh(a, b, c, d, x[i + 1], 4, -1530992060);
    d = hh(d, a, b, c, x[i + 4], 11, 1272893353);
    c = hh(c, d, a, b, x[i + 7], 16, -155497632);
    b = hh(b, c, d, a, x[i + 10], 23, -1094730640);
    a = hh(a, b, c, d, x[i + 13], 4, 681279174);
    d = hh(d, a, b, c, x[i + 0], 11, -358537222);
    c = hh(c, d, a, b, x[i + 3], 16, -722521979);
    b = hh(b, c, d, a, x[i + 6], 23, 76029189);
    a = hh(a, b, c, d, x[i + 9], 4, -640364487);
    d = hh(d, a, b, c, x[i + 12], 11, -421815835);
    c = hh(c, d, a, b, x[i + 15], 16, 530742520);
    b = hh(b, c, d, a, x[i + 2], 23, -995338651);
    a = ii(a, b, c, d, x[i + 0], 6, -198630844);
    d = ii(d, a, b, c, x[i + 7], 10, 1126891415);
    c = ii(c, d, a, b, x[i + 14], 15, -1416354905);
    b = ii(b, c, d, a, x[i + 5], 21, -57434055);
    a = ii(a, b, c, d, x[i + 12], 6, 1700485571);
    d = ii(d, a, b, c, x[i + 3], 10, -1894986606);
    c = ii(c, d, a, b, x[i + 10], 15, -1051523);
    b = ii(b, c, d, a, x[i + 1], 21, -2054922799);
    a = ii(a, b, c, d, x[i + 8], 6, 1873313359);
    d = ii(d, a, b, c, x[i + 15], 10, -30611744);
    c = ii(c, d, a, b, x[i + 6], 15, -1560198380);
    b = ii(b, c, d, a, x[i + 13], 21, 1309151649);
    a = ii(a, b, c, d, x[i + 4], 6, -145523070);
    d = ii(d, a, b, c, x[i + 11], 10, -1120210379);
    c = ii(c, d, a, b, x[i + 2], 15, 718787259);
    b = ii(b, c, d, a, x[i + 9], 21, -343485551);
    a = ad(a, olda);
    b = ad(b, oldb);
    c = ad(c, oldc);
    d = ad(d, oldd);
  }
  return rh(a) + rh(b) + rh(c) + rh(d);
}
/* eslint-enable no-bitwise */
