import { Log } from '@biteinc/common';
import { TimeHelper } from '@biteinc/core-react';
import { CardEntryMethod } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { localizeStr } from '../localization/localization';
import { GCNCardWithPinView } from './gcn_card_with_pin_view';
import GcnView from './gcn_view_ts';

export default class GcnGiftCardBarcodeView extends GcnView {
  className = 'gift-card-barcode-view';

  private callback: any;

  $icon: any;

  $formGroup: JQuery<HTMLElement>;

  $giftCardNumberLabel: JQuery<HTMLElement>;

  $giftCardPopupTitleWrapper: JQuery<HTMLElement>;

  $manualEntryButton: any;

  constructor(options: any) {
    super();
    this.callback = options.callback;
  }

  handleScannerData(scannerData: any): void {
    Log.info('GCNGiftCardBarcodeView.handleScannerData', scannerData);

    // If not validated, or either scan source is already processed, ignore scans.
    if (!gcn.orderManager.orderHasBeenValidated()) {
      return;
    }
    this.showGiftCardWithPinPopup(scannerData, CardEntryMethod.Scan);
  }

  private showGiftCardWithPinPopup(cardNumber?: string, cardEntryMethod?: CardEntryMethod): void {
    // TODO: Clean up self-dismissal with a controller.
    gcn.menuView.dismissStablePopup();
    // Remove barcode listener
    gcn.removeScannerHandler(this);
    const giftCardView = new GCNCardWithPinView('stored-value', {
      callback: this.callback,
      cardNumber,
      cardEntryMethod,
    });
    gcn.menuView.showStablePopup(giftCardView, 'gift-card-view');
  }

  render(): this {
    const self = this;
    // prettier-ignore
    const html =
      '<div class="gift-card-view-top">' +
        '<div class="icon"></div>' +
        '<div id="gift-card-popup-title-wrapper"></div>' +
      '</div>' +
      '<div class="gift-card-view-bottom">' +
        '<div class="form-group"></div>' +
        '<div class="fallback-button card-number"></div>' +
      '</div>';
    this.$el.html(html);
    this.$icon = this.$('.icon');
    this.$formGroup = this.$('.form-group');
    this.$giftCardPopupTitleWrapper = this.$('#gift-card-popup-title-wrapper');
    this.$giftCardPopupTitleWrapper.append(
      `<div class="gift-card-popup-title">${localizeStr(Strings.SCAN_YOUR_GIFT_CARD)}</div>`,
    );

    this.$manualEntryButton = this.$('.card-number');
    this.$manualEntryButton.htmlOrText(localizeStr(Strings.ENTER_GIFT_CARD_BARCODE_FALLBACK));
    this.$manualEntryButton.css('opacity', '0');
    setTimeout(() => {
      self.$manualEntryButton.css('opacity', '1');
    }, TimeHelper.SECOND);

    this.$manualEntryButton.onButtonTapOrHold('gcbvManual', () => {
      this.showGiftCardWithPinPopup();
    });

    gcn.pushScannerHandler(self);

    this.$icon.delayedScaleIn();

    if (window.isKioskPreview) {
      setTimeout(() => {
        const scannerData = prompt('Please enter a barcode:', '');
        if (scannerData) {
          this.handleScannerData(scannerData);
        }
      }, 1000);
    }

    return this;
  }
}
