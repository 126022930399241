import _ from 'underscore';

import { ModGroupWalletType } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { localizeStr, str } from '~/app/js/localization/localization';

import GcnHtml from '../gcn_html';
import { GCNModel } from './gcn_model';

/**
 * @description Returns a short descriptive string for the range of min to max, where one might not
 * be defined.
 * @param {number} min
 * @param {number} max
 * @returns {string}
 */
function getMinMaxDescription(min, max) {
  if (min && max) {
    if (min === max) {
      return min.toString();
    }
    return `${min}-${max}`;
  }
  if (min) {
    return localizeStr(Strings.AT_LEAST_X, [`${min}`]);
  }
  if (max) {
    return localizeStr(Strings.UP_TO_X, [`${max}`]);
  }
  return '';
}

export const GCNAddonSet = GCNModel.extend({
  initialize(...args) {
    GCNModel.prototype.initialize.apply(this, args);

    this.items = [];
  },

  getNon86dAddons() {
    return this.items.filter((addon) => {
      return !addon.is86d();
    });
  },

  /**
   * Checks the list of selected addons against any restrictions included in this set.
   *
   * @param {Object} selectionStruct - The selection structure to validate.
   * @param {boolean} shallowValidation - If true, performs a shallow validation.
   * @param {boolean} ignoreMinSelectable - If true, ignores the minimum selectable restriction.
   * @param {boolean} shouldValidateQuantityInRange - If true, validates that the quantity is in range.
   * @returns {boolean} - Returns true iff the selection is valid
   */
  isSelectionStructValid(
    selectionStruct,
    shallowValidation,
    ignoreMinSelectable,
    shouldValidateQuantityInRange,
  ) {
    return !this.validateSelectionStruct(
      selectionStruct,
      shallowValidation,
      ignoreMinSelectable,
      shouldValidateQuantityInRange,
    );
  },

  /**
   * Checks the list of selected addons against any restrictions included in this set.
   *
   * @param {Object} selectionStruct - The selection structure to validate.
   * @param {boolean} shallowValidation - If true, performs a shallow validation.
   * @param {boolean} ignoreMinSelectable - If true, ignores the minimum selectable restriction.
   * @param {boolean} shouldValidateQuantityInRange - If true, validates that the quantity is in range.
   * @returns {null|string} - Returns null if the selection is valid, otherwise returns the id of the invalid mod.
   */
  validateSelectionStruct(
    selectedModGroup,
    shallowValidation,
    ignoreMinSelectable,
    shouldValidateQuantityInRange,
  ) {
    const minSelectable = ignoreMinSelectable ? 0 : this.get('minSelectable');
    const maxSelectable = this.get('maxSelectable');
    let isValid = false;

    // Number of unique addons that have a price option selected.
    let addonCount = 0;
    Object.values(selectedModGroup.selections).forEach((selectedMod) => {
      if (selectedMod.model.priceOptions.length === 1 || selectedMod.selectedPriceOption) {
        addonCount += 1;
      }
    });

    if (minSelectable && maxSelectable) {
      // the validation is called twice; when `shouldValidateQuantityInRange` is not set then addonCount
      // represents the currently selected addons hence the need for `<=`
      if (shouldValidateQuantityInRange) {
        if (addonCount < maxSelectable || maxSelectable === 1) {
          isValid = true;
        }
      } else if (minSelectable <= addonCount && addonCount <= maxSelectable) {
        isValid = true;
      }
    } else if (minSelectable) {
      // we ignore minSelectable when `shouldValidateQuantityInRange` is set
      if (shouldValidateQuantityInRange || minSelectable <= addonCount) {
        isValid = true;
      }
    } else if (maxSelectable) {
      // the validation is called twice; when `shouldValidateQuantityInRange` is not set then addonCount
      // represents the currently selected addons hence the need for `<=`
      if (shouldValidateQuantityInRange) {
        if (addonCount < maxSelectable || maxSelectable === 1) {
          isValid = true;
        }
      } else if (addonCount <= maxSelectable) {
        isValid = true;
      }
    } else if (!minSelectable && !maxSelectable) {
      isValid = true;
    }

    if (!isValid) {
      return selectedModGroup.model.id;
    }

    const hasInvalidPriceOption = Object.values(selectedModGroup.selections).some((selectedMod) => {
      return selectedMod.model.priceOptions.length !== 1 && !selectedMod.selectedPriceOption;
    });
    if (hasInvalidPriceOption) {
      return selectedModGroup.model.id;
    }

    // If we're still valid, check multiple-quantity rules.
    if (
      !this._validateSelectionStructForMultipleQuantities(
        selectedModGroup,
        shouldValidateQuantityInRange,
      )
    ) {
      return selectedModGroup.model.id;
    }

    if (shallowValidation) {
      // must be valid since we would've returned id by now
      return null;
    }

    let invalidNestedModGroupId = undefined;
    // Use `some` to short-return if we find an invalid mod group.
    Object.values(selectedModGroup.selections)
      .sort((selectedModA, selectedModB) => {
        const indexA = selectedModGroup.model.items.findIndex((mod) => {
          return mod.id === selectedModA.model.id;
        });
        const indexB = selectedModGroup.model.items.findIndex((mod) => {
          return mod.id === selectedModB.model.id;
        });

        return indexA - indexB;
      })
      .some((selectedMod) => {
        return Object.values(selectedMod.selections)
          .sort((nestedSelectedModGroupA, nestedSelectedModGroupB) => {
            const priceOption =
              selectedMod.selectedPriceOption ?? selectedMod.model.priceOptions[0];

            const indexA = priceOption.get('addonSetIds').findIndex((nestedModGroupId) => {
              return nestedModGroupId === nestedSelectedModGroupA.model.id;
            });
            const indexB = priceOption.get('addonSetIds').findIndex((nestedModGroupId) => {
              return nestedModGroupId === nestedSelectedModGroupB.model.id;
            });

            return indexA - indexB;
          })
          .some((selectedNestedModGroup) => {
            if (!selectedNestedModGroup.model.isSelectionStructValid(selectedNestedModGroup)) {
              invalidNestedModGroupId = selectedNestedModGroup.model.id;
              return true;
            }
            return false;
          });
      });
    if (invalidNestedModGroupId) {
      return invalidNestedModGroupId;
    }

    return null;
  },

  _validateSelectionStructForMultipleQuantities(selectionStruct, shouldValidateQuantityInRange) {
    // `shouldValidateQuantityInRange` is set if we want to verify if the selection's quantity or
    // aggregateQuantity are within the min/max aggregateQuantity
    if (!this.allowMultipleOfSameAddon()) {
      return true;
    }

    const maxSelectable = this.get('maxSelectable');
    const minChoiceQuantity = this.get('minChoiceQuantity');
    const maxChoiceQuantity = this.get('maxChoiceQuantity');
    const minAggregateQuantity = this.get('minAggregateQuantity');
    const maxAggregateQuantity = this.get('maxAggregateQuantity');
    const choiceQuantityIncrement = this.get('choiceQuantityIncrement');
    let aggregateQuantity = 0;
    const selectionsInRange = _.all(selectionStruct.selections, (selection) => {
      const quantity = selection.quantity;
      aggregateQuantity += quantity;

      // If min/max choice quantity is specified, check that all quantities are in range.
      if (minChoiceQuantity && quantity < minChoiceQuantity) {
        return false;
      }
      if (maxChoiceQuantity && quantity > maxChoiceQuantity) {
        return false;
      }
      // If choice quantity increment is specified, check to make sure all quantities are a
      // multiple of the increment.
      if (choiceQuantityIncrement && quantity % choiceQuantityIncrement !== 0) {
        return false;
      }
      return true;
    });

    if (!selectionsInRange) {
      return false;
    }

    if (shouldValidateQuantityInRange) {
      // this is before the quantity of selection or addon is set
      let isValid = true;

      // If max aggregate quantity is specified, add up all the quantities and check in range.
      if (maxAggregateQuantity && aggregateQuantity >= maxAggregateQuantity) {
        isValid = false;
      }
      if (maxSelectable && aggregateQuantity >= maxSelectable) {
        // Implicitly true restriction. Only check if maxSelectable is set otherwise there is no
        // implicit max to care about.
        isValid = false;
      }
      return isValid;
    }

    // If min/max aggregate quantity is specified, add up all the quantities and check in range.
    if (minAggregateQuantity && aggregateQuantity < minAggregateQuantity) {
      return false;
    }
    if (maxAggregateQuantity && aggregateQuantity > maxAggregateQuantity) {
      return false;
    }
    if (maxSelectable && aggregateQuantity > maxSelectable) {
      // Implicitly true restriction. Only check if maxSelectable is set otherwise there is no
      // implicit max to care about.
      return false;
    }

    return true;
  },

  // Returns true if this addon for the menu item can be `assorted`
  isAssorted() {
    if (!this.items.length) {
      return false;
    }

    // this makes sure that we're selecting an addon/mod
    // that can be incremented, which is what custom assorted is for
    if (this.get('choiceQuantityIncrement') === 0) {
      return false;
    }

    // no nested mods for the items of this addon
    const containsNestedMods = this.getNon86dAddons().some((addon) =>
      addon.priceOptions.some((itemPO) => itemPO.addonSets.length !== 0),
    );

    // addonSet is no longer an assorted menu item if any of the addonSets has
    // 1. nested mods this is no longer
    // 2. minAggregateQuantity &&  maxAggregateQuantity should be equal and be one of 3, 4, 6, 8, 12
    const possibleAggregateQuantityList = [3, 4, 6, 8, 12];
    const matchesAggregateQuantities =
      this.get('minAggregateQuantity') === this.get('maxAggregateQuantity') &&
      possibleAggregateQuantityList.indexOf(this.get('maxAggregateQuantity')) !== -1;

    const isItemAssorted = matchesAggregateQuantities && !containsNestedMods;
    return isItemAssorted;
  },

  hasSelectableRules() {
    return (
      this.get('maxSelectable') || this.get('minSelectable') || this.allowMultipleOfSameAddon()
    );
  },

  _getMultipleQuantitySelectableText() {
    if (!this.allowMultipleOfSameAddon()) {
      return null;
    }

    const selectableTextParts = [];

    const maxSelectable = this.get('maxSelectable') === 1066 ? 0 : this.get('maxSelectable');
    const minSelectable = this.get('minSelectable');
    const minChoiceQuantity = this.get('minChoiceQuantity');
    const maxChoiceQuantity = this.get('maxChoiceQuantity');
    const minAggregateQuantity = this.get('minAggregateQuantity');
    const maxAggregateQuantity =
      this.get('maxAggregateQuantity') === 1066 ? 0 : this.get('maxAggregateQuantity');
    const choiceQuantityIncrement = this.get('choiceQuantityIncrement');
    if ((minSelectable || maxSelectable) && !this.get('hideSelectionCriteria')) {
      if (maxSelectable === 0) {
        // We want to let the guest know that they need to make at least `minSelectable`
        // choices since `maxSelectable` is 0;
        selectableTextParts.push(localizeStr(Strings.MOD_GROUP_CHOOSE_AT_LEAST_X, [minSelectable]));
      } else {
        // We limit max to double the length of items, as some places have
        // arbitrarily high maxSelectable values (1066, 100+) and some places
        // have maxSelectable values that are slightly above item length
        // (Select 12 from 10, allow selecting the same item multiple times)
        const max = Math.min(maxSelectable || this.getNon86dAddons().length * 2);
        if (minSelectable === max && minSelectable === 1) {
          selectableTextParts.push(localizeStr(Strings.MOD_GROUP_CHOICES_MAKE_ONE));
        } else {
          const minMaxDescription = getMinMaxDescription(minSelectable, max);
          selectableTextParts.push(
            localizeStr(Strings.MOD_GROUP_CHOICES_MAKE_X, [minMaxDescription]),
          );
        }
      }
    }

    if ((minChoiceQuantity || maxChoiceQuantity) && !this.get('hideChoiceSelectionCriteria')) {
      const minMaxDescription = getMinMaxDescription(minChoiceQuantity, maxChoiceQuantity);
      selectableTextParts.push(
        localizeStr(Strings.MOD_GROUP_CHOICES_QUANTITY_PER, [minMaxDescription]),
      );
    }

    if (!this.get('hideAggregateSelectionCriteria')) {
      if (minAggregateQuantity || maxAggregateQuantity) {
        const minMaxDescription = getMinMaxDescription(minAggregateQuantity, maxAggregateQuantity);
        selectableTextParts.push(
          localizeStr(Strings.MOD_GROUP_AGGREGATE_QUANTITY, [minMaxDescription]),
        );
      }
    }

    if (choiceQuantityIncrement > 1 && !this.get('hideChoiceSelectionCriteria')) {
      selectableTextParts.push(
        localizeStr(Strings.MOD_GROUP_CHOICES_INCREMENT, [choiceQuantityIncrement]),
      );
    }

    if (selectableTextParts.length) {
      // Always formal.
      selectableTextParts[0] =
        selectableTextParts[0].charAt(0).toUpperCase() + selectableTextParts[0].slice(1);
    }

    return selectableTextParts.join(', ');
  },

  hasWalletText() {
    return !!this.get('posWalletSettings') && !this.get('hideWalletSelectionCriteria');
  },

  getWalletText() {
    const { type, amount, values } = this.get('posWalletSettings') || {};

    if (!type) {
      return '';
    }

    if (type === ModGroupWalletType.Dollar) {
      return localizeStr(Strings.MOD_GROUP_WALLET_$_TO_SPEND, [
        `$${GcnHtml.stringFromPrice(amount)}`,
      ]);
    }

    if (type === ModGroupWalletType.Quantity) {
      return values
        .map(({ inclusiveQuantityCap, price }, idx) => {
          const priceStr = `$${GcnHtml.stringFromPrice(price)}`;
          if (idx === values.length - 1 && values.length - 1 > 0) {
            return localizeStr(Strings.MOD_GROUP_WALLET_ADDITIONAL_CHOICES_AT_$_PER, [priceStr]);
          }
          if (price === 0) {
            return localizeStr(Strings.MOD_GROUP_WALLET_FIRST_X_FREE, [inclusiveQuantityCap]);
          }
          return localizeStr(Strings.MOD_GROUP_WALLET_UP_TO_X, [inclusiveQuantityCap, priceStr]);
        })
        .join(' ');
    }

    return '';
  },

  selectableText() {
    let maxSelectable = this.get('maxSelectable');
    const minSelectable = this.get('minSelectable');

    const multipleQuantitySelectableText = this._getMultipleQuantitySelectableText();
    if (multipleQuantitySelectableText) {
      return multipleQuantitySelectableText;
    }

    // Remove maxSelectable if it's either the max item count or 1066; otherwise we get
    // "choose up to 15"
    if (
      Math.min(maxSelectable, this.getNon86dAddons().length) === this.getNon86dAddons().length ||
      maxSelectable === 1066
    ) {
      maxSelectable = 0;
    }
    maxSelectable = Math.min(maxSelectable, this.getNon86dAddons().length);
    if (maxSelectable || minSelectable) {
      if (minSelectable && minSelectable === maxSelectable) {
        if (minSelectable === 1) {
          return str(Strings.MOD_GROUP_CHOOSE_ONE);
        }
        return str(Strings.MOD_GROUP_CHOOSE_X, [minSelectable]);
      }

      if ((minSelectable || 0) > 0 && (maxSelectable || 0) > 0) {
        return str(Strings.MOD_GROUP_CHOOSE_BETWEEN_X_AND_Y, [minSelectable, maxSelectable]);
      }
      if ((minSelectable || 0) > 0) {
        return str(Strings.MOD_GROUP_CHOOSE_AT_LEAST_X, [minSelectable]);
      }
      if (maxSelectable === 1) {
        return str(Strings.MOD_GROUP_CHOOSE_UP_TO_ONE);
      }
      if (maxSelectable !== 0) {
        return str(Strings.MOD_GROUP_CHOOSE_UP_TO_X, [maxSelectable]);
      }
    }
    return '';
  },

  canAutoScroll(selectedAddonById) {
    const maxSelectable = this.get('maxSelectable');
    const minSelectable = this.get('minSelectable');
    const allowMultipleOfSameAddon =
      !!this.get('allowMultipleOfSameAddon') && !this.get('disableAllowMultipleOfSameAddon');
    const numberSelected = allowMultipleOfSameAddon
      ? _.reduce(selectedAddonById, (sum, val) => sum + val.quantity, 0)
      : _.size(selectedAddonById);
    return (
      (allowMultipleOfSameAddon && numberSelected === maxSelectable) ||
      (maxSelectable === numberSelected &&
        maxSelectable > 0 &&
        (maxSelectable === minSelectable || (minSelectable || 0) === 0))
    );
  },

  addonsHaveTheSameNonZeroPrice() {
    const uniqPrices = _.unique(
      this.getNon86dAddons().flatMap((addon) => {
        return addon.priceOptions.map((priceOption) => {
          return this.addonPriceInSet(addon, priceOption);
        });
      }),
    );
    return uniqPrices.length === 1 && uniqPrices[0] > 0 && this.getNon86dAddons().length > 1;
  },

  doWeRecommend() {
    // Do not recommend if there is more than one price option.
    if (
      this.getNon86dAddons().some((addon) => {
        return addon.priceOptions.length !== 1;
      })
    ) {
      return false;
    }
    // Don't look for recommendations for a single free mod
    // (most likely it's something like "customize")
    if (
      this.getNon86dAddons().length === 1 &&
      this.addonPriceInSet(this.getNon86dAddons()[0]) === 0
    ) {
      return false;
    }

    return true;
  },

  allowMultipleOfSameAddon() {
    return (
      this.get('allowMultipleOfSameAddon') &&
      !this.get('disableAllowMultipleOfSameAddon') &&
      this.get('maxSelectable') !== 1 &&
      !gcn.screenReaderIsActive
    );
  },

  // Return the maximum possible quantity for the addon choices in this set. Could be undefined or
  // zero if not set.
  getChoiceQuantityMax() {
    return (
      this.get('maxSelectable') || this.get('maxChoiceQuantity') || this.get('maxAggregateQuantity')
    );
  },

  _getItemRefProperty(addonId, propertyName, defaultValue) {
    for (let i = 0; i < this.get('items').length; i++) {
      const itemRef = this.get('items')[i];
      if (itemRef._id === addonId) {
        if (_.has(itemRef, propertyName)) {
          return itemRef[propertyName];
        }
      }
    }
    return defaultValue;
  },

  addonIsSelectedByDefault(addonId) {
    return this.getAddonSelectedByDefaultCount(addonId) > 0;
  },

  getAddonSelectedByDefaultCount(addonId) {
    const defaultQuantity = this._getItemRefProperty(addonId, 'selectedByDefaultQuantity', 0);

    if (this.allowMultipleOfSameAddon()) {
      return defaultQuantity;
    }

    return defaultQuantity > 0 ? 1 : 0;
  },

  addonMustBeAlwaysSent(addonId) {
    return !!this._getItemRefProperty(addonId, 'mustBeAlwaysSent', false);
  },

  addonWontSendIfSelected(addonId) {
    return this._getItemRefProperty(addonId, 'dontSendIfSelected', false);
  },

  getAddonIdsAndPricesSelectedByDefault() {
    return this.getNon86dAddons()
      .filter((addon) => {
        return this.addonIsSelectedByDefault(addon.id);
      })
      .map((addon) => {
        return {
          _id: addon.id,
          price: this.addonPriceInSet(addon),
          quantity: this.getAddonSelectedByDefaultCount(addon.id) ?? 1,
        };
      });
  },

  addonNameInSet(addon, priceOption) {
    let name = this._getItemRefProperty(addon.id, 'name', addon.displayName());

    if (priceOption?.displayName()) {
      name += ` (${priceOption.displayName()})`;
    }

    return name;
  },

  addonImagesInSet(addon) {
    return this._getItemRefProperty(addon.id, 'images', addon.get('images'));
  },

  subModsInSet(addonId) {
    return this._getItemRefProperty(addonId, 'autoSelectedSubMods', []);
  },

  addonPriceInSet(addon, priceOption) {
    return this.addonPriceInSetWithId(
      addon.id,
      (priceOption || addon.priceOptions[0]).get('price'),
    );
  },

  addonPriceInSetWithId(addonId, defaultPrice) {
    return this._getItemRefProperty(addonId, 'price', defaultPrice);
  },

  containsItemWithId(addonId) {
    return !!_.find(this.items, (addon) => {
      return addon.id === addonId;
    });
  },
});
