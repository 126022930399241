import $ from 'jquery';
import _ from 'underscore';

import { Log } from '@biteinc/common';
import { FulfillmentMethod, FulfillmentMethodHelper } from '@biteinc/enums';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import ScreenReaderHelper from '../screen_reader_helper';
import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

function getClassForFulfillmentMethod(fulfillmentMethod) {
  switch (fulfillmentMethod) {
    case FulfillmentMethod.CATERING_CURBSIDE:
    case FulfillmentMethod.FLASH_CURBSIDE:
    case FulfillmentMethod.WEB_CURBSIDE:
      return 'curbside';
    case FulfillmentMethod.CATERING_DELIVERY:
    case FulfillmentMethod.FLASH_DELIVERY:
    case FulfillmentMethod.WEB_DELIVERY:
      return 'delivery';
    case FulfillmentMethod.CATERING_DINE_IN:
    case FulfillmentMethod.FLASH_DINE_IN:
    case FulfillmentMethod.KIOSK_DINE_IN:
    case FulfillmentMethod.WEB_DINE_IN:
      return 'dine-in';
    case FulfillmentMethod.CATERING_OUTPOST:
    case FulfillmentMethod.FLASH_OUTPOST:
    case FulfillmentMethod.KIOSK_OUTPOST:
    case FulfillmentMethod.WEB_OUTPOST:
      return 'outpost';
    case FulfillmentMethod.CATERING_TO_GO:
    case FulfillmentMethod.FLASH_TO_GO:
    case FulfillmentMethod.KIOSK_TO_GO:
    case FulfillmentMethod.WEB_TO_GO:
      return 'to-go';
  }
  return '';
}

// UI to allow the guest to choose when they want their order.
export const GCNFulfillmentMethodPickerView = GCNView.extend({
  className: 'fulfillment-method-picker-view list-picker-view',
  attributes: {
    'aria-model': true,
    role: 'dialog',
  },
  _template: _.template(`
    <div class="title-bar" aria-hidden="true">
      <div class="fulfillment-close-button"></div>
    </div>
    <div class="choose-fulfillment-method">
      <div class="header" role="heading" aria-level="1" tabindex="-1" aria-label="<%= headerAriaLabel %>">
        <span aria-hidden="true"><%= headerText %></span>
      </div>
      <div class="fulfillment-options"></div>
    </div>
  `),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._showCancelButton = options.showCancelButton;

    this.addRefreshOnLanguageChangeSubscription();
  },

  render() {
    Log.info('OSM Render start GCNFulfillmentMethodPickerView', this._id);
    const header = localizeStr(Strings.HOW_WOULD_YOU_LIKE_YOUR_ORDER);
    this.$el.html(
      this._template({
        headerText: header,
        headerAriaLabel: ScreenReaderHelper.prepareAriaLabel('Select Fulfillment Method'),
      }),
    );

    if (this._showCancelButton) {
      this.$('.fulfillment-close-button').onButtonTapOrHold('fmpvClose', () => {
        Log.info('OSM FulfillmentMethodPickerView cancel button tapped', this._id);
        Analytics.track(Analytics.EventName.FulfillmentMethodSelectionBackOut);
        this.trigger(
          BackboneEvents.GCNFulfillmentMethodPickerView.DidCancelPickingFulfillmentMethod,
        );
      });
    } else {
      this.$('.fulfillment-close-button').remove();
    }
    const $fulfillmentMethodList = this.$('.fulfillment-options');

    gcn.location.getActiveDiningOptions().forEach(({ fulfillmentMethod }) => {
      const title = gcn.location.getDiningOptionName(fulfillmentMethod);
      const className = ['list-item-button', getClassForFulfillmentMethod(fulfillmentMethod)].join(
        ' ',
      );
      const $fulfillmentMethodButton = $(
        // prettier:ignore
        `<div class="${className}">
            <span class="title">${title}</span>
          </div>`,
      );
      $fulfillmentMethodList.append($fulfillmentMethodButton);
      $fulfillmentMethodButton.onButtonTapOrHold('fulfillmentPickerButton', () => {
        Log.info('OSM FulfillmentMethodPickerView button tapped', fulfillmentMethod, this.id);
        Analytics.trackEvent({
          eventName: Analytics.EventName.FulfillmentMethodSelected,
          eventData: {
            fulfillmentMethod: FulfillmentMethodHelper.stringFromEnum(fulfillmentMethod),
          },
        });
        this.trigger(
          BackboneEvents.GCNFulfillmentMethodPickerView.DidPickFulfillmentMethod,
          fulfillmentMethod,
        );
      });
    });
    Log.info('OSM Render finish GCNFulfillmentMethodPickerView', this._id);

    return this;
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);
    Log.info('OSM Destroy GCNFulfillmentMethodPickerView', this._id);
  },
});
