import { IonButton, IonCol, IonIcon, IonItem, IonLabel, IonList, IonRow } from '@ionic/react';
import { cartOutline, closeOutline, informationCircleOutline, timeOutline } from 'ionicons/icons';
import React, { Component } from 'react';
import _ from 'underscore';
import { shallow } from 'zustand/shallow';

import { Log } from '@biteinc/common';
import { TimeHelper } from '@biteinc/core-react';
import { Strings } from '@biteinc/localization';

import { localizeStr, str } from '~/app/js/localization/localization';
import { LocationUtils } from '~/helpers';

import GcnHtml from '../app/js/gcn_html';
import GcnRecoTracker from '../app/js/gcn_reco_tracker';
import CartCoordinator from '../app/js/utils/cart_coordinator';
import { asCallback } from '../app/js/utils/promises';
import type { Store } from '../stores';
import { withStore } from '../stores';
import type { GcnMenuItem } from '../types/gcn_menu_item';
import { RecommendationDisplayLocationDescription } from '../types/recommendation';
import { OrderedItemComponent } from './ordered-item';
import RecommendedItemComponent from './recommended-item';

interface CartProps {
  order: Store['cart'];
  location: Store['bridge']['location'];
  settings: Store['bridge']['menu']['settings'];
}

interface CartComponentState {
  showCartInfo: boolean;
  recommendedMenuItems: GcnMenuItem[];
  includeUtensils: boolean;
}

class Cart extends Component<CartProps, CartComponentState> implements CartCoordinator.Delegate {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cartRef: React.RefObject<any>;

  constructor(props: CartProps) {
    super(props);
    this.state = {
      showCartInfo: false,
      recommendedMenuItems: [],
      includeUtensils: false,
    };
    this.cartRef = React.createRef();
    this.handleClickOutsideToClose = this.handleClickOutsideToClose.bind(this);
  }

  private static eventPathHasPopup(event: Event): boolean {
    return event.composedPath().some((eventTarget) => {
      // @ts-ignore this property seems to only exist in Chrome/Safari
      const className = eventTarget.className;
      // className may not be a string if the eventTarget is an svg
      return _.isString(className) ? className.match('touch-blocker-overlay')?.length : false;
    });
  }

  /**
   * Only close when the even happens outside of a popup window.
   */
  private handleClickOutsideToClose(event: Event): void {
    if (
      this.cartRef &&
      !Cart.eventPathHasPopup(event) &&
      !this.cartRef.current?.contains(event.target)
    ) {
      this.setState({
        showCartInfo: false,
      });
    }
  }

  getOrderedItems(): React.ReactNode[] {
    return this.props.order.orderedItems.map((orderedItem, index) => {
      return (
        <OrderedItemComponent
          // eslint-disable-next-line no-underscore-dangle
          key={orderedItem._uid}
          orderedItem={orderedItem}
          index={index}
          showEditDeleteButtons={true}
        />
      );
    });
  }

  private static getRecommendationComponents(
    recommendedMenuItems: GcnMenuItem[],
    displayLocationDescription: RecommendationDisplayLocationDescription,
  ): React.ReactNode | undefined {
    if (recommendedMenuItems.length === 0) {
      return undefined;
    }

    const recoItemIds = recommendedMenuItems.map((reco) => {
      return reco.id;
    });
    GcnRecoTracker.trackDisplayRecommendations(
      gcn.maitred,
      displayLocationDescription,
      recoItemIds,
    );

    const recommendations = recommendedMenuItems.map((recommendedMenuItem, index) => {
      return (
        <RecommendedItemComponent
          key={recommendedMenuItem.id}
          recommendedMenuItem={recommendedMenuItem}
          index={index}
          displayLocationDescription={displayLocationDescription}
        />
      );
    });
    return (
      <IonRow>
        <IonLabel className="recommendation-name">{localizeStr(Strings.ALSO_CONSIDER)}</IonLabel>
        {recommendations}
      </IonRow>
    );
  }

  static showSummaryInfo(): React.ReactNode | undefined {
    const cartDisclaimer = gcn.menu.settings.get('cartDisclaimer');
    const shouldShowOrderLeadTimeWarningInCart =
      gcn.orderManager.shouldShowOrderLeadTimeWarningInCart();

    const footnotes: React.ReactElement[] = [];
    if (shouldShowOrderLeadTimeWarningInCart) {
      const orderLeadTime = gcn.orderManager.getOrderLeadTime()!;
      const readyTime = TimeHelper.millisecondsToFriendlyDescription(orderLeadTime);
      const message = `${str(Strings.LEAD_TIME_CART)} (Ready in ${readyTime})`;
      footnotes.push(
        <IonItem
          lines="none"
          className="summary-footnote lead-time-warning"
          key={`lead-time-warning`}
        >
          <IonIcon
            className="summary-icon"
            icon={timeOutline}
          />
          <IonLabel className="summary-column lead-time-warning-body">
            <p className="summary-description">{message} </p>
          </IonLabel>
        </IonItem>,
      );
    }
    if (cartDisclaimer) {
      footnotes.push(
        <IonItem
          className="summary-footnote"
          key={`summary-step-footnote`}
        >
          <IonIcon
            className="summary-icon"
            icon={informationCircleOutline}
          ></IonIcon>
          <IonLabel className="summary-column">
            <p className="summary-description">{cartDisclaimer}</p>
          </IonLabel>
        </IonItem>,
      );
    }

    if (footnotes.length) {
      return (
        <IonList
          lines="none"
          className="summary-footnotes"
        >
          {footnotes}
        </IonList>
      );
    }
    return undefined;
  }

  getIncludeUtensils(): React.ReactNode | undefined {
    const showIncludeUtensils = this.props.settings.askToIncludeUtensilsOnFlashOrders;
    const shouldSkipIncludeUtensils = LocationUtils.shouldSkipIncludeUtensils(
      this.props.location,
      this.props.order.fulfillmentMethod!,
    );
    if (!showIncludeUtensils || shouldSkipIncludeUtensils) {
      return undefined;
    }

    return (
      <IonList
        lines="full"
        className=""
      >
        <IonItem
          lines="none"
          className="order-totals-item bold"
        >
          <IonLabel>
            <IonRow className="tw-border-t tw-border-t-slate-400 tw-py-3 tw-mt-4">
              <IonCol className="ios tw-flex tw-flex-row tw-justify-end">
                <label
                  className="tw-text-lg tw-mr-4 tw-ml-2 tw-text-gray-900"
                  htmlFor="utensils-opt-in"
                >
                  {localizeStr(Strings.INCLUDE_UTENSILS)}
                </label>
                <input
                  id="utensils-opt-in"
                  type="checkbox"
                  checked={this.state.includeUtensils}
                  name="utensils-opt-in"
                  className="tw-w-6 tw-h-6 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded tw-focus:ring-blue-500 tw-focus:ring-2"
                  onChange={() => {
                    gcn.orderManager.setIncludeUtensils(!this.state.includeUtensils);
                    this.setState({
                      includeUtensils: !this.state.includeUtensils,
                    });
                  }}
                />
              </IonCol>
            </IonRow>
          </IonLabel>
        </IonItem>
      </IonList>
    );
  }

  getCartInfo(): React.ReactNode | undefined {
    if (!this.state.showCartInfo || !this.getCartSize()) {
      return undefined;
    }

    return (
      <div
        className="web-cart"
        ref={this.cartRef}
      >
        <IonList lines="full">
          <IonItem
            lines="none"
            className="title-item"
          >
            <IonLabel className="cart-title">Your Order</IonLabel>
            <IonButton
              className="hide-cart-button simple-icon-button"
              slot="end"
              color="primary"
              onClick={() => {
                this.setState({
                  showCartInfo: false,
                });
              }}
            >
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonItem>
          {this.getOrderedItems()}
        </IonList>
        <IonList
          lines="none"
          className="cart-recommendations"
        >
          {Cart.getRecommendationComponents(
            this.state.recommendedMenuItems,
            RecommendationDisplayLocationDescription.CART_VIEW,
          )}
        </IonList>
        {this.getIncludeUtensils()}
        {Cart.showSummaryInfo()}
        <IonList
          lines="none"
          className="totals-list"
        >
          <IonItem
            lines="none"
            className="order-totals-item bold"
          >
            <IonLabel>
              <IonRow>
                <IonCol>
                  <p>Subtotal</p>
                </IonCol>
                <IonCol className="text-right">
                  <p>{`$${GcnHtml.stringFromPrice(this.props.order.subTotal)}`}</p>
                </IonCol>
              </IonRow>
            </IonLabel>
          </IonItem>
          <IonItem className="checkout-item">
            <IonButton
              className="cta-button checkout-button"
              slot="end"
              color="primary"
              expand="block"
              onClick={() => {
                this.setState({
                  showCartInfo: false,
                });
                gcn.startCheckoutSequence();
              }}
            >
              Checkout
            </IonButton>
          </IonItem>
        </IonList>
      </div>
    );
  }

  componentDidUpdate(prevProps: CartProps, prevState: CartComponentState): void {
    const prevCartSize: number = prevProps.order.orderedItems.reduce((count, prevOrderedItem) => {
      return count + prevOrderedItem.priceOption.quantity;
    }, 0);
    const currCartSize: number = prevProps.order.orderedItems.reduce((count, orderedItem) => {
      return count + orderedItem.priceOption.quantity;
    }, 0);
    if (prevCartSize !== currCartSize) {
      CartCoordinator.cartUpdated(currCartSize);

      this.fetchRecommendations();
    }

    if (prevState.showCartInfo !== this.state.showCartInfo) {
      if (this.state.showCartInfo) {
        CartCoordinator.cartOpened();
      } else {
        CartCoordinator.cartClosed();
      }
    }
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutsideToClose);
    CartCoordinator.setCartDelegate(this);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutsideToClose);
    CartCoordinator.setCartDelegate(undefined);
  }

  private fetchRecommendations(): void {
    if (!this.props.settings.showUpsellInCart) {
      return;
    }

    const displayRecosLimit = 2;

    asCallback(GcnRecoTracker.getSideCartRecommendations(gcn.maitred), (err, recommendations) => {
      if (err) {
        Log.error(err);
        return;
      }

      const recommendedMenuItems = gcn.menu.getMenuItemsFromRecommendations(recommendations!, {
        displayRecosLimit,
      });

      this.setState({ recommendedMenuItems });
    });
  }

  openCart(): void {
    this.setState({
      showCartInfo: true,
    });
  }

  closeCart(): void {
    this.setState({
      showCartInfo: false,
    });
  }

  isCartOpen(): boolean {
    return this.state.showCartInfo;
  }

  getCartSize(): number {
    return this.props.order.orderedItems.reduce((count, orderedItem) => {
      return count + orderedItem.priceOption.quantity;
    }, 0);
  }

  render(): React.ReactNode {
    return (
      <div className="cart-button-container">
        <IonButton
          className="cart-button"
          color="primary"
          fill="clear"
          onClick={() => {
            if (this.getCartSize()) {
              this.setState({
                showCartInfo: true,
              });

              this.fetchRecommendations();
            }
          }}
        >
          <IonIcon icon={cartOutline} />
          <div className="cart-item-count">{this.getCartSize()}</div>
        </IonButton>
        {this.getCartInfo()}
      </div>
    );
  }
}

const mapStateToProps = (state: Store): CartProps => {
  return {
    order: state.cart,
    location: state.bridge.location,
    settings: state.bridge.menu.settings,
  };
};

// this is an optimization to prevent unnecessary re-renders
// https://github.com/pmndrs/zustand#selecting-multiple-state-slices
export default withStore(mapStateToProps, shallow)(Cart);
