import _ from 'underscore';

import { Log } from '@biteinc/common';
import { Strings } from '@biteinc/localization';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import { GCNNumberPadView } from './gcn_number_pad_view';
import { GCNView } from './gcn_view';

export const GCNPasscodeView = GCNView.extend({
  className: 'passcode-view',

  template: _.template(
    // prettier-ignore
    '<div class="header">' +
      '<div class="custom-message"></div>' +
      '<div class="label"></div>' +
      '<div class="input-container">' +
        '<div class="user-input"></div>' +
        '<div class="underscores">_ _ _ _</div>' +
      '</div>' +
    '</div>',
  ),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.numberPadView = new GCNNumberPadView();
    this.listenTo(
      this.numberPadView,
      BackboneEvents.GCNNumberPadView.DigitWasPressed,
      this._inputNumber,
    );
    this.listenTo(
      this.numberPadView,
      BackboneEvents.GCNNumberPadView.DeleteWasPressed,
      this._deleteChar,
    );
    this._userCode = '';
    this._checkCallback = options.checkCallback;
    this._successCallback = options.successCallback;
    this._failureMessage = options.failureMessage;
    this._customMessage = options.customMessage;
  },

  _updateDisplayValue() {
    let displayValue = '';
    for (let i = 0; i < this._userCode.length; i++) {
      displayValue += '. ';
    }
    this.$('.user-input').text(displayValue.trim());
  },

  _inputNumber(number) {
    if (this._userCode.length < 4) {
      this.$label.htmlOrText(localizeStr(Strings.ENTER_ACCESS_CODE));
      this.$label.toggleClass('error', false);
      this._userCode += number;
      if (this._userCode.length === 4) {
        this._checkAccessCode();
      }
      this._updateDisplayValue();
    }
  },

  _deleteChar() {
    if (this._userCode.length > 0) {
      this._userCode = this._userCode.substring(0, this._userCode.length - 1);
      this._updateDisplayValue();
    }
  },

  _checkAccessCode() {
    if (!this._checkCallback || !this._successCallback) {
      Log.error(
        'Callback not provided. Check Callback:',
        this._checkCallback,
        ' Success Callback:',
        this._successCallback,
      );
      return;
    }
    if (this._checkCallback(this._userCode)) {
      this._successCallback(this._userCode);
    } else {
      this._userCode = '';
      this._updateDisplayValue();
      this.$label.htmlOrText(
        this._failureMessage ? this._failureMessage : localizeStr(Strings.BAD_ACCESS_CODE),
      );
      this.$label.toggleClass('error', true);
    }
  },

  render() {
    this.$el.html(this.template());
    this.$el.append(this.numberPadView.render().$el);

    this.$label = this.$('.label');
    this.$label.htmlOrText(localizeStr(Strings.ENTER_ACCESS_CODE));
    const $customMessage = this.$('.custom-message');
    if (this._customMessage) {
      $customMessage.htmlOrText(this._customMessage);
    } else {
      $customMessage.hide();
    }

    return this;
  },
});
